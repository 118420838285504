<template>
  <div id="incident" class="incident-form">
    <div class="page-container">
      <div class="page">
        <div class="form-container">
          <template v-if="checkRiddor">
            <check-riddor v-on:riddor-not-needed="startIncident" />
          </template>
          <template v-else>
            <steps :items="screens" :active-item="activeScreen" v-if="screens.length > 1"></steps>
            <div class="inner-container">
              <h2>{{ activeScreen.title }}</h2>
              <vue-form-generator
                :schema="formSchema"
                :model="formModel"
                :options="formOptions"
                @validated="onValidated"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import formBuilder from '@/mixins/form-builder';
import Steps from '@/components/Steps';
import CheckRiddor from './CheckRiddor';

export default {
  name: 'new-incident',

  props: ['incidentId', 'screenId'],

  components: { CheckRiddor, Steps },

  mixins: [formBuilder],

  data() {
    return {
      checkRiddor: false,
    };
  },

  computed: {
    ...mapState({
      screens: (state) => state.incident.screens,
    }),

    ...mapGetters(['activeFields', 'activeWorkflow', 'activeScreen']),
  },

  watch: {
    activeWorkflow: {
      handler() {
        if (this.activeWorkflow && this.activeWorkflow.id) {
          if (this.incidentId) {
            this.renderScreen({ workflow: this.activeWorkflow.id, screen: parseInt(this.screenId) });
          } else if (this.activeWorkflow.workflow_type === 'incident') {
            this.checkRiddor = true;
          } else {
            this.startIncident();
          }
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions(['createIncident', 'renderScreen', 'fetchScreens']),

    startIncident() {
      this.checkRiddor = false;
      this.createIncident(this.activeWorkflow.id).then((incident) => {
        this.$router.push({
          name: 'resume-incident',
          params: { incidentId: incident.id, screenId: this.activeScreenId },
        });
      });
    },

    onValidated(isValid) {
      if (!isValid) {
        setTimeout(() => {
          window.scrollTo(0, document.querySelector('.form-group.error').offsetTop);
        }, 0);
      }
    },
  },
};
</script>

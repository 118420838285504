<template>
  <div class="box">
    <div class="box__inner">
      <p class="box__message">{{ parent }} doesn't have any {{ pluralizedName }} yet</p>
      <i :class="`fa-solid fa-${icon}`" />
      <themed-button v-if="themed" :to="{ name: newLink }" :disabled="disabled">Add {{ name }}</themed-button>
      <router-link v-else :to="{ name: newLink }" class="button" :disabled="disabled" :event="disabled ? '' : 'click'">
        Add {{ name }}
      </router-link>
    </div>
  </div>
</template>

<script>
import pluralize from 'pluralize';

export default {
  name: 'admin-no-items',
  props: ['parent', 'name', 'icon', 'newLink', 'disabled'],

  computed: {
    pluralizedName() {
      if (!this.name) return this.name;
      return pluralize(this.name).toLowerCase();
    },

    themed() {
      return !this.newLink.startsWith('admin');
    },
  },
};
</script>

<template>
  <div>
    <h1>Edit {{ activeCompany.name }}</h1>

    <vue-form-generator
      :schema="buildSchema('company', 'companySchema', 'basicInfo', 'edit')"
      :model="formModel"
      :options="formOptions" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import editForm from '@/mixins/edit-form';

export default {
  name: 'admin-edit-company',
  mixins: [editForm],

  data: () => {
    return {
      formName: 'Company',
      formIndex: 'admin-dashboard',
    };
  },

  computed: {
    ...mapGetters('admin', [
      'activeCompany',
    ]),
  },

  watch: {
    activeCompany: {
      handler(value) {
        if (value.accounts_id) {
          this.populatePrimaryUsers({ company: value.accounts_id });
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions('admin', [
      'populatePrimaryUsers',
    ]),
  },
};
</script>

import pluralize from 'pluralize';

const formField = (form, error, index = 0) => {
  if (form.groups.length > 0) {
    return form.groups[index + 1].fields.find((f) => {
      return f.model.replace(/['"]+/g, '').replace(/_id$/, '').replace(/^\d-/, '') === error.replace(/_id$/, '');
    });
  }
  return form.fields.find((f) => f.model.replace(/['"]+/g, '').replace(/_id$/, '') === error.replace(/_id$/, ''));
};

const handleFormErrors = (form, errors, errorKeys, response) => {
  if (errorKeys.length > 0 && errors && errors.constructor !== Array) {
    errorKeys.forEach((error) => {
      form.errors.push({ error: errors[error][0], field: formField(form, error) });
    });
  } else if (errors && errors.constructor === Array && errors.length > 0) {
    errors.forEach((errorGroup, index) => {
      Object.keys(errorGroup).forEach((error) => {
        form.errors.push({ error: errors[index][error][0], field: formField(form, error, index) });
      });
    });
  } else if (response.status >= 400 && response.status < 500) {
    form.fields
      .filter((field) => !field.type.toLowerCase().includes('submit'))
      .forEach((field) => form.errors.push({ error: '', field }));
  }
};

export default {
  handleError({ commit, dispatch }, { response, form, fieldKey, showToastMessage }) {
    if (!response) {
      commit('setApiConnectionError', true);
    }
    const showToast = showToastMessage === undefined ? true : showToastMessage;
    commit('setSubmittingForm', false);
    const errorResponse = (response.data ? response.data : response) || 'Cannot connect to API';

    const errors =
      errorResponse.errors || (errorResponse.data && errorResponse.data.errors ? errorResponse.data.errors : []);
    const errorKeys = Object.keys(errors);

    if (form) {
      const formErrors = fieldKey ? errors[fieldKey] : errors;
      handleFormErrors(form, formErrors, errorKeys, errorResponse);
    }

    /* eslint-disable no-console */
    if (errorKeys.length > 0) {
      console.error(errors);
    } else {
      console.error(errorResponse);
    }
    /* eslint-enable no-console */

    let messageText = errorResponse;
    if (errorResponse.message || errorResponse.errors) {
      messageText = form || !response.errors ? errorResponse.message : errorResponse.errors[0];
    }

    if (showToast) {
      dispatch('setMessage', {
        text: messageText || 'An unknown error occurred',
        messageType: 'error',
      });
    }
  },

  setMessage({ commit }, message) {
    commit('setMessage', message);
    window.setTimeout(() => commit('setMessage', { message: '', messageType: message.messageType }), 5000);
  },

  loadResources({ state, commit, dispatch }, { to, resource, resources, areaIn }) {
    const prefix = areaIn === 'standard' ? '' : `${areaIn}/`;
    const key = parseInt(to.params[resource.key]);

    let rootState = prefix ? state[prefix.replace('/', '')] : state;
    rootState = rootState[resource.name.toLowerCase()];

    if (resource.single && key) {
      dispatch(`${prefix}fetch${resource.name}`, key);
    }

    if (key && ((rootState && rootState[`active${resource.name}`] !== key) || resource.keepActive)) {
      dispatch('fetchResources', { resources, resource, key, prefix });
    }

    if (!resource.noActive && (key || !resource.keepActive)) {
      commit(`${prefix}setActive${resource.name}`, key || 0);
    }

    return true;
  },

  fetchResources({ dispatch }, { resources, resource, key, prefix }) {
    if (resource.fetch !== false && !resource.dependsOn && !resource.single) {
      dispatch(`${prefix}fetch${pluralize(resource.name)}`);
    }

    if (key) {
      const dependencies = resources.filter((r) => {
        return r.dependsOn === resource.key && r.fetch !== false;
      });

      dependencies.forEach((dependency) => dispatch(`${prefix}fetch${pluralize(dependency.name)}`, { dependsOn: key }));
    }
  },
};

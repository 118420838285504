<template>
  <div class="page-container">
    <ul class="tabs">
      <li>
        <router-link :to="{ name: 'incident-reports' }" aria-selected="true">Reported Incidents</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'incident-reports' }">Incidents Summary</router-link>
      </li>
    </ul>

    <div class="page white">
      <div class="container">
        <div class="page__header">
          <div class="actions" v-if="incidents && incidents.length > 0">
            <label class="actions">
              Show Closed Incidents?
              <div class="input input--checkbox input--spacing">
                <input
                  type="checkbox"
                  class="input__default"
                  :checked="showClosedIncidents"
                  @change="setShowClosedIncidents($event.target.checked)"
                />
                <div class="input__styled input__styled--checkbox"></div>
              </div>
            </label>
            <themed-button :to="{ name: 'new-incident' }" class="page__action" :disabled="activeWorkflow.read_only">
              New {{ workflowType() }}
            </themed-button>
          </div>
        </div>
        <div v-if="incidents && incidents.length > 0">
          <div class="list-view">
            <div class="list-view__header">
              <div>Date/Time</div>
              <div class="list-view__small-column">Reference</div>
              <div class="list-view__small-column">Submitted By</div>
              <div class="list-view__small-column">Status</div>
              <div class="list-view__small-column">Actions</div>
            </div>
            <div class="list-view__row" v-for="incident in sortedIncidents" :key="incident.id">
              <div>
                <router-link :to="incidentLink(incident)">
                  {{ dateTime(incident.completed_at || incident.updated_at) }}
                </router-link>
              </div>
              <div class="list-view__small-column">
                <span class="show-for-small">Reference: &nbsp;</span>{{ incident.reference || '-' }}
              </div>
              <div class="list-view__small-column">{{ userFullName(incident.company_user_id) }}</div>
              <div class="list-view__small-column">
                {{ incident.complete ? 'Complete' : 'In Progress' }}
                <span class="small" v-if="incident.closed">&nbsp; (closed)</span>
              </div>
              <div class="list-view__small-column">
                <router-link :to="incidentLink(incident)" v-if="incident.complete">
                  <button class="icon-button">
                    <i class="fa-solid fa-eye" />
                  </button>
                </router-link>
                <router-link :to="incidentLink(incident)" v-else-if="canResumeReport(incident)">
                  <button class="icon-button">
                    <i class="fa-solid fa-play" />
                  </button>
                </router-link>
                <button class="icon-button" @click="closeIncident(incident)" :disabled="!canCloseReport(incident)">
                  <i class="fa-solid fa-box-archive" />
                </button>
                <button class="icon-button" @click="deleteIncident(incident)" :disabled="!canDeleteReport(incident)">
                  <i class="fa-solid fa-trash" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <no-items
          v-else
          :parent="parentName"
          :name="workflowType()"
          icon="assignment"
          new-link="new-incident"
          :disabled="activeWorkflow.read_only"
        />
            </div>
      </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import Common from '@/common';
import fetchResources from '@/mixins/fetch-resources';
import NoItems from '@/components/NoItems';

export default {
  name: 'incidents-index',

  mixins: [fetchResources],

  components: { NoItems },

  data() {
    return {
      resources: [{ name: 'Users', dependant: 'Company' }],
    };
  },

  computed: {
    ...mapState({
      incidents: (state) => state.incident.incidents,
      currentUser: (state) => state.user.currentUser,
      showClosedIncidents: (state) => state.incident.showClosedIncidents,
    }),

    ...mapGetters([
      'activeCompany',
      'activeWorkflow',
      'sortedIncidents',
      'workflowType',
      'userById',
      'userFullName',
      'parsedDateTime',
      'dateTime',
      'hasPermissionForResource',
    ]),

    parentName() {
      return `${this.activeWorkflow.title} workflow`;
    },
  },

  methods: {
    ...mapMutations(['setShowClosedIncidents']),

    incidentLink(incident) {
      if (incident.complete) {
        return { name: 'incident', params: { incidentId: incident.id } };
      }
      return { name: 'resume-incident', params: { incidentId: incident.id, screenId: incident.next_screen } };
    },

    canResumeReport(incident) {
      const incidentUser = this.userById(incident.company_user_id).accounts_id;
      return !incident.complete && this.hasPermissionForResource(incidentUser);
    },

    canCloseReport(incident) {
      return incident.complete && !incident.closed && this.hasPermissionForResource();
    },

    canDeleteReport(incident) {
      const incidentUser = this.userById(incident.company_user_id).accounts_id;
      return incident.deletable && this.hasPermissionForResource(incidentUser);
    },

    closeIncident(incident) {
      Common.confirmDialog({
        modal: this.$modal,
        // eslint-disable-next-line max-len
        title: `Are you sure you want to close the incident dated ${this.dateTime(incident.completed_at)}?`,
        okHandler: () => {
          this.$store.dispatch('closeIncident', incident.id);
        },
      });
    },

    deleteIncident(incident) {
      Common.confirmDialog({
        modal: this.$modal,
        // eslint-disable-next-line max-len
        title: `Are you sure you want to delete the incident dated ${this.dateTime(incident.updated_at)}?`,
        okHandler: () => {
          this.$store.dispatch('deleteIncident', incident.id);
        },
      });
    },
  },
};
</script>

<template>
  <div>
    <h1>Latest Reportable Incidents</h1>
    <div class="list-view" v-if="incidents.length > 0">
      <div class="list-view__header">
        <div>Date/Time</div>
        <div class="list-view__small-column">Reference</div>
        <div>Company</div>
        <div>Workflow</div>
        <div class="list-view__small-column">Actions</div>
      </div>

      <div class="list-view__row" v-for="incident in incidents" :key="incident.id">
        <div>
          <router-link :to="{
            name: 'lawyer-incident',
            params: { workflowId: incident.workflow_id, incidentId: incident.id }
          }">
            {{ dateTime(incident.completed_at) }}
          </router-link>
        </div>
        <div class="list-view__small-column">
          <span class="show-for-small">Reference: &nbsp;</span>{{ incident.reference || '-' }}
        </div>
        <div>
          <router-link :to="{ name: 'lawyer-company', params: { companyId: incident.company_id } }">
           {{ incident.company_name }}
          </router-link>
        </div>
        <div>{{ incident.workflow_title }}</div>
        <div class="list-view__small-column">
          <button class="icon-button" @click="closeIncident(incident)" :disabled="incident.closed">
            <i class="fa-solid fa-box-archive" />
          </button>
        </div>
      </div>
    </div>

    <div class="panel" v-else>
      There are currently no open reportable incident reports
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Common from '@/common';

export default {
  name: 'lawyer-incidents',

  computed: {
    ...mapState('lawyer', {
      incidents: state => state.incident.recentIncidents.filter(incident => !incident.closed),
    }),

    ...mapGetters([
      'dateTime',
    ]),
  },

  methods: {
    ...mapActions('lawyer', [
      'fetchRecentIncidents',
    ]),

    closeIncident(incident) {
      Common.confirmDialog({
        modal: this.$modal,
        // eslint-disable-next-line max-len
        title: `Are you sure you want to close the incident dated ${this.dateTime(incident.completed_at)}?`,
        okHandler: () => {
          this.$store.dispatch('lawyer/closeIncident', incident.id);
        },
      });
    },
  },

  created() {
    this.fetchRecentIncidents();
  },
};
</script>

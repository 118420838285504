<template>
  <button class="primary-button" @click="schema.buttonAction(formOptions.context, schema)" :disabled="disabled">
    {{ schema.buttonText }}
  </button>
</template>

<script>
import { abstractField } from 'vue-form-generator';

export default {
  mixins: [abstractField],
};
</script>

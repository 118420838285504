<template>
  <div class="warning panel container container__spacing" v-show="checkShowWarningPanel">
    <div class="panel-content">
      <button class="icon-button" @click="setShowWarningPanel(false)">
        <i class="fa-solid fa-xmark" />
      </button>
      <div class="icon-text-container">
        <i class="fa-solid fa-triangle-exclamation" />
        <p>
          <strong> rradarreport </strong> is an official incident reporting mechanism. Details entered here are
          permanent so please take care with your responses.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'warning-panel',
  props: {
    checkShowWarningPanel: {
      type: Boolean,
      required: true,
    },
    setShowWarningPanel: {
      type: Function,
      required: true,
    },
  },
};
</script>

/* eslint-disable no-shadow */

const state = {
  operators: [
    { id: 'eq', name: 'Equals' },
    { id: 'con', name: 'Contains' },
    { id: 'sw', name: 'Starts With' },
    { id: 'ew', name: 'Ends With' },
    { id: 'gt', name: 'Greater Than' },
    { id: 'gte', name: 'Greater Than or Equal' },
    { id: 'lt', name: 'Less Than' },
    { id: 'lte', name: 'Less Than or Equal' },
  ],
};

const getters = {
  friendlyOperatorName: (state) => (operatorId) => {
    const operator = state.operators.find((operator) => operator.id === operatorId);
    return operator ? operator.name : operatorId;
  },
};

const mutations = {};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div>
    <div class="items" v-if="activeCompany.id">
      <div class="item panel">
        <h2 class="item__name">Workflows</h2>
        <router-link :to="{ name: 'admin-workflows', params: { companyId: activeCompany.id } }" class="button">
          Manage Workflows
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'admin-show-company',

  computed: {
    ...mapGetters('admin', [
      'activeCompany',
    ]),
  },
};
</script>

<template>
  <div class="info panel container container__spacing" v-show="checkShowInfoPanel">
    <div class="panel-content">
      <button class="icon-button" @click="setShowInfoPanel(false)">
        <i class="fa-solid fa-xmark" />
      </button>
      <div class="icon-text-container">
        <i class="fa-solid fa-circle-info" />
        <div class="text-container">
          <h3>What is rradarreport?</h3>
          <p>
            Following a workplace incident, it can be easy to forget to record vital information that will be needed
            later. This information could prove crucial if any claims are made or an outside investigation takes place.
            <br /><br />
            That is why we have developed rradarreport, a mobile app and website that can be used to record accidents
            and near misses and which connects you directly to one of our specialist lawyers if appears that a claim or
            outside investigation is likely.
            <br /><br />
            An incident record can be created in two ways, depending on the nature and seriousness of the incident. See
            below for details.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'info-panel',
  props: {
    checkShowInfoPanel: {
      type: Boolean,
      required: true,
    },
    setShowInfoPanel: {
      type: Function,
      required: true,
    },
  },
};
</script>

<template>
  <div>
    <ul :key="note.id" v-for="note in notes">
      <li>
        <div class="note note-incident">
          {{ dateTime(note.created_at) }}
          <template v-if="note.user_full_name"> - {{ note.user_full_name }}</template>
        </div>
        <div class="note note-incident" v-if="note.upload_id">
          Attached media
          <download-link :files="[note.upload_id]" buttonClasses="button small" />
        </div>
        <div>{{ note.note }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DownloadLink from '@/components/DownloadLink';

export default {
  name: 'note-list',

  props: ['notes'],

  components: { DownloadLink },

  computed: {
    ...mapGetters([
      'dateTime',
    ]),
  },
};
</script>

import pluralize from 'pluralize';
import { mapMutations } from 'vuex';
import Common from '@/common';
import clone from 'lodash.clone';

export default {
  data: () => {
    return {
      activeObject: 0,
      initialObjects: [{}],
      objects: [{}],
    };
  },

  computed: {
    parentId() {
      return this[`active${this.formName}`].id;
    },
  },

  methods: {
    ...mapMutations(['setSubmittingForm']),

    saveObject(data, form) {
      this.$store
        .dispatch(data.id ? `admin/edit${this.inlineName}` : `admin/add${this.inlineName}`, {
          data,
          parentId: this.parentId,
          form,
        })
        .then(() => {
          this.setSubmittingForm(false);
          this.clearForm();
          this.setupObjects();
        });
    },

    setupObjects() {
      let objects = [];
      if (this[`active${this.formName}`][this.inlineId]) {
        objects = this[`active${this.formName}`][this.inlineId];
      } else if (this[this.inlineId]) {
        this[this.inlineId].forEach((object) => {
          const newObject = {};
          Object.keys(object).forEach((key) => (newObject[key] = object[key]));
          objects.push(newObject);
        });
      }
      this.initialObjects.splice(1, this.initialObjects.length, ...objects);
      this.objects.splice(1, this.objects.length, ...objects.map((object) => clone(object)));
    },

    editObject(objectId) {
      const editTitle = document.getElementById('edit-title');
      window.scrollTo(0, editTitle.offsetTop);
      this.activeObject = objectId;
    },

    deleteObject(object) {
      Common.confirmDialog({
        modal: this.$modal,
        title: `Are you sure you want to delete this ${pluralize.singular(this.inlineId)}?`,
        okHandler: () => {
          this.$store
            .dispatch(`admin/delete${this.inlineName}`, {
              parentId: this.parentId,
              inlineId: object.id,
            })
            .then(() => {
              this.setupObjects();
            });
        },
      });
    },

    clearForm() {
      this.$set(this.objects, 0, {});
      this.activeObject = 0;
    },

    dropdownFields(fields) {
      return fields.map((field) => {
        return { id: field.id, name: `${field.title} (${field.field_type})`, group: field.screenTitle };
      });
    },
  },

  created() {
    this.setupObjects();
  },
};

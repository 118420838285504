export default {
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateDebounceTime: 800,
  },
  message: {
    text: '',
    messageType: '',
  },
  apiDateFormat: 'YYYY-MM-DD HH:mm:ss',
  apiDateFormatWithTZ: 'YYYY-MM-DD HH:mm:ss ZZ',
  primaryColour: '#B61311',
  alreadyScrolled: false,
  apiConnectionError: false,
  notFoundError: false,
  submittingForm: false,
  showWarningPanel: false,
  showInfoPanel: false,
  reportLoading: [],
  siteAreas: ['admin', 'lawyer'],
};

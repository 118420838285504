<template>
  <div v-if="!needsReporting">
    <h2>Before you continue...</h2>
    <!-- eslint-disable max-len -->
    <p>Some incidents require a report to be completed and submitted to the Health and Safety Executive. To determine whether this incident should be reported, please check whether the incident meets any of these <a href="" @click.prevent="$modal.show('reportable-conditions')">reportable conditions</a> and answer the following questions:</p>
    <!-- eslint-enable max-len -->
    <vue-form-generator
      :schema="checkRiddorSchema"
      :model="checkRiddorModel"
      :options="formOptions" />

      <reportable-conditions />
  </div>
  <div class="panel" v-else>
    <h2>This incident needs reporting to the HSE</h2>

    <!-- eslint-disable max-len -->
    <p>As this looks like a serious incident, it will need to be reported to the Health and Safety Executive and therefore should be filled in using a reportable workflow. This will allow rradar's lawyers to be aware of the incident and to contact you on how to report the incident and what else needs to be done. Don't worry, there'll be no bill for us to support you with this.</p>
    <!-- eslint-disable max-len -->

    <themed-button :to="workflowRoute" class="spacing">
      Continue to reportable workflow
    </themed-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ReportableConditions from '@/components/ReportableConditions';

export default {
  name: 'check-riddor',

  components: { ReportableConditions },

  data() {
    return {
      needsReporting: false,
      checkRiddorModel: {},
    };
  },

  computed: {
    ...mapGetters([
      'buildSchema',
      'workflowsByType',
    ]),

    checkRiddorSchema() {
      return this.buildSchema('incident', 'checkRiddorSchema', 'basicInfo', 'new', false);
    },

    formOptions() {
      return Object.assign({ context: this }, this.$store.state.formOptions);
    },

    workflowRoute() {
      if (this.workflowsByType('privileged').length === 1) {
        return { name: 'new-incident', params: { workflowId: this.workflowsByType('privileged')[0].id } };
      }
      return { name: 'workflows' };
    },
  },

  methods: {
    continueIncident() {
      if (Object.values(this.checkRiddorModel).some(question => question)) {
        this.needsReporting = true;
      } else {
        this.$emit('riddor-not-needed');
      }
    },
  },

};
</script>

<template>
  <modal-wrapper name="trigger-messages">
    <div class="modal__message" v-for="(message, index) in messages" :key="index">
      <h2 v-if="message.title">{{ message.title }}</h2>
      <div class="preserve-whitespace">{{ message.message }}</div>
    </div>
  </modal-wrapper>
</template>

<script>
import { mapState } from 'vuex';
import ModalWrapper from '@/components/ModalWrapper';

export default {
  name: 'trigger-modal',

  components: { ModalWrapper },

  computed: {
    ...mapState({
      messages: state => state.trigger.messages,
      displayModal: state => state.trigger.displayModal,
    }),
  },

  watch: {
    displayModal: {
      handler() {
        if (this.displayModal) {
          setTimeout(() => this.$modal.show('trigger-messages'), 0);
        }
      },
      immediate: true,
    },
  },
};
</script>

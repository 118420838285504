<template>
  <div>
    <div class="admin__header">
      <div class="admin-workflow-pages">
        <steps
          class="steps--clickable"
          :items="screens"
          :active-item="activeScreen"
          :click-event="goToScreen"
          v-if="screens.length > 1" />
        <h2 v-if="screens && screens.length === 1">{{ screens[0].title }}</h2>
      </div>
    </div>

    <screen-preview></screen-preview>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import NoItems from '@/components/NoItems';
import Steps from '@/components/Steps';
import ScreenPreview from '@/components/admin/screens/Preview';

export default {
  name: 'admin-preview-workflow',

  components: { NoItems, ScreenPreview, Steps },

  computed: {
    ...mapState({
      screens: state => state.admin.screen.screens,
    }),

    ...mapGetters('admin', [
      'activeWorkflow',
      'activeScreen',
    ]),

    ...mapGetters('admin', {
      fields: 'activeFields',
    }),
  },

  methods: {
    ...mapMutations('admin', [
      'setActiveScreen',
    ]),

    goToScreen(screen) {
      this.setActiveScreen(screen.id);
    },
  },

  created() {
    this.setActiveScreen(1);
  },
};
</script>

<template>
  <div v-if="workflows.length > 0">
    <div class="admin__header">
      <h1 class="no-margin">Workflows</h1>
      <div class="actions">
        <pulse-loader
          :loading="loading || workflowCloning"
          :color="primaryColour"
          size="10px"
          class="v-spinner--no-spacing actions__action"
          title="Cloning workflow" />
        <router-link :to="{ name: 'admin-clone-company-workflow' }" class="actions__action button">
          Clone Workflow
        </router-link>
        <router-link :to="{ name: 'admin-new-workflow' }" class="actions__action button">Add Workflow</router-link>
      </div>
    </div>

    <admin-workflow-list :workflows="workflows" />
  </div>

  <no-items v-else
    :parent="activeCompany.name"
    name="Workflow"
    icon="briefcase"
    new-link="admin-new-workflow"
  />
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import PulseLoader from '@/components/PulseLoader';
import NoItems from '@/components/NoItems';
import AdminWorkflowList from './WorkflowList';

export default {
  name: 'admin-workflows',

  components: { NoItems, PulseLoader, AdminWorkflowList },

  computed: {
    ...mapState({
      workflows: state => state.admin.workflow.workflows,
      primaryColour: state => state.primaryColour,
      loading: state => state.admin.workflow.loading,
    }),

    ...mapGetters('admin', [
      'activeCompany',
    ]),

    workflowCloning() {
      return this.workflows.some(workflow => workflow.cloning);
    },
  },
};
</script>

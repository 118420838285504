<template>
  <div class="page-container">
    <ul class="tabs">
      <li>
        <router-link :to="{ name: 'incidents' }">Reported Incidents</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'incident-reports' }" aria-selected="true">Incidents Summary</router-link>
      </li>
    </ul>
    <div class="page white">
      <div class="page__header page__header--large">
        <div class="actions" v-if="incidents && incidents.length > 0">
          <themed-button :to="{ name: 'new-incident' }" class="page__action">New {{ workflowType() }}</themed-button>
        </div>
      </div>
      <number-of-incidents-per-month v-if="incidents && incidents.length > 0" :incidents="incidents" />
      <p class="padding-1 text-large" v-if="!incidents && incidents.length <= 0">No incidents have been recorded yet</p>
    </div>

    <multiple-choice-questions v-if="incidents && incidents.length > 0" :incidents="incidents" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import NumberOfIncidentsPerMonth from './report/NumberOfIncidentsPerMonth';
import MultipleChoiceQuestions from './report/MultipleChoiceQuestions';

export default {
  name: 'incident-reports',

  components: { NumberOfIncidentsPerMonth, MultipleChoiceQuestions },

  computed: {
    ...mapGetters(['incidentsFromPastMonths', 'activeWorkflow', 'workflowType']),

    incidents() {
      return this.incidentsFromPastMonths(12);
    },
  },

  watch: {
    activeWorkflow: {
      handler() {
        if (this.activeWorkflow.id) {
          this.fetchIncidents({ dependsOn: this.activeWorkflow.id, withData: true });
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions(['fetchIncidents']),
  },
};
</script>

<template>
  <div class="list-view incident__copy">
    <div class="list-view__header">
      <div>Field</div>
      <div>Value</div>
      <div class="list-view__small-column hide-for-print">Actions</div>
    </div>

    <div
      class="list-view__row"
      :class="{ 'hide-for-print': !field.show_for_print }"
      v-for="field in orderedData(screen)"
      :key="field.id"
    >
      <div>{{ field.title }}</div>

      <div v-if="screen.screen.fields[field.ref] === 'file'">
        <download-link :files="field.data" v-if="screen.screen.fields[field.ref] === 'file'" />
      </div>
      <div v-else>{{ formatValue(field.data, screen.screen.fields[field.ref]) }}</div>

      <div class="list-view__small-column hide-for-print notes-column">
        <button
          class="icon-button"
          @click="manageIncidentNotes('field', { screen, screenIndex, screenCopyIndex, fieldRef: field.ref })"
        >
          <i class="fa-solid fa-note-sticky" />
          <span
            class="absolute"
            v-if="numberOfNotes({ screen, screenIndex, screenCopyIndex, fieldRef: field.ref }) > 0"
          >
            {{ numberOfNotes({ screen, screenIndex, screenCopyIndex, fieldRef: field.ref }) }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import notes from '@/mixins/notes';
import DownloadLink from '@/components/DownloadLink';

export default {
  name: 'incident-screen-fields',

  props: ['screen', 'screenIndex', 'screenCopyIndex'],

  mixins: [notes],

  components: { DownloadLink },

  computed: {
    ...mapState({
      notes: (state) => state.incident.notes,
    }),

    ...mapGetters(['formatValue']),
  },

  methods: {
    orderedData(screen) {
      const data = screen.screen.multiple ? screen.data[this.screenCopyIndex - 1] : screen.data;
      return Object.values(data).sort((a, b) => a.position > b.position);
    },
  },
};
</script>

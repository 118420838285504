<template>
  <div>
    <h2 id="edit-title">Edit {{ activeWorkflow.title }} Custom Fields</h2>
    <tabs :options="{ useUrlFragment: false }">
      <tab name="Basic Info">
        <vue-form-generator
          :schema="buildSchema('workflow', 'workflowSchema', 'basicInfo', 'companyEdit')"
          :model="formModel"
          :options="formOptions" />
      </tab>
    </tabs>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import editForm from '@/mixins/edit-form';

export default {
  name: 'admin-edit-company-workflow',
  mixins: [editForm],

  data: () => {
    return {
      formName: 'CompanyWorkflow',
      formParent: 'Company',
    };
  },

  computed: {
    ...mapState('admin', {
      fields: state => state.field.allFields,
    }),

    ...mapGetters('admin', [
      'activeCompany',
      'activeWorkflow',
      'fieldById',
      'friendlyOperatorName',
    ]),

    formIndex() {
      return 'admin-workflows';
    },
  },

  watch: {
    activeWorkflow: {
      handler() {
        if (this.activeWorkflow.id) {
          this.defineFormObjects();
        }
      },
      immediate: true,
    },
  },
};
</script>

<template>
  <!-- eslint-disable vue/require-v-for-key -->
  <div class="wrapper">
    <div class="listbox form-control inline-fields" v-if="schema.listBox" :disabled="disabled">
      <div class="list-row" v-for="item in items" :class="{'is-checked': isItemChecked(item)}">
        <label class="input input--checkbox">
          <input
            type="checkbox"
            class="input__default input__default--checkbox"
            :checked="isItemChecked(item)"
            :disabled="disabled"
            @change="onChanged($event, item)">
          <div class="input__styled input__styled--checkbox"></div>
            {{ getItemName(item) }}
        </label>
      </div>
    </div>

    <div class="combobox form-control" v-if="!schema.listBox" :disabled="disabled">
      <div class="mainRow" @click="onExpandCombo" :class="{ expanded: comboExpanded }">
        <div class="info">{{ selectedCount }} selected</div>
        <div class="arrow"></div>
      </div>

      <div class="dropList">
        <div class="list-row" v-if="comboExpanded" v-for="item in items" :class="{'is-checked': isItemChecked(item)}">
          <label class="input input--checkbox">
            <input
              type="checkbox"
              class="input__default"
              :checked="isItemChecked(item)"
              :disabled="disabled"
              @change="onChanged($event, item)">
            <div class="input__styled input__styled--checkbox"></div>
            {{ getItemName(item) }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { abstractField } from 'vue-form-generator';
import clone from 'lodash.clone';

export default {
  /* eslint-disable no-throw-literal */
  /* eslint-disable max-len */

  mixins: [abstractField],

  data() {
    return {
      comboExpanded: false,
    };
  },

  computed: {
    items() {
      const values = this.schema.values;
      if (typeof (values) === 'function') {
        return values.apply(this, [this.model, this.schema]);
      } return values;
    },

    selectedCount() {
      if (this.value) { return this.value.length; }

      return 0;
    },
  },

  methods: {
    getItemValue(item) {
      if (item) {
        if (typeof this.schema.checklistOptions !== 'undefined' && typeof this.schema.checklistOptions.value !== 'undefined') {
          return item[this.schema.checklistOptions.value];
        }
        if (typeof item.value !== 'undefined') {
          return item.value;
        }
        throw '`value` is not defined. If you want to use another key name, add a `value` property under `checklistOptions` in the schema. https://icebob.gitbooks.io/vueformgenerator/content/fields/checklist.html#checklist-field-with-object-values';
      } else {
        return item;
      }
    },

    getItemName(item) {
      if (item) {
        if (typeof this.schema.checklistOptions !== 'undefined' && typeof this.schema.checklistOptions.name !== 'undefined') {
          return item[this.schema.checklistOptions.name];
        }
        if (typeof item.name !== 'undefined') {
          return item.name;
        }
        throw '`name` is not defined. If you want to use another key name, add a `name` property under `checklistOptions` in the schema. https://icebob.gitbooks.io/vueformgenerator/content/fields/checklist.html#checklist-field-with-object-values';
      } else {
        return item;
      }
    },

    isItemChecked(item) {
      return (this.value && this.value.indexOf(this.getItemValue(item)) !== -1);
    },

    onChanged(event, item) {
      if (!this.value || !Array.isArray(this.value)) {
        this.value = [];
      }

      if (event.target.checked) {
        // Note: If you modify this.value array, it won't trigger the `set` in computed field
        const arr = clone(this.value);
        arr.push(this.getItemValue(item));
        this.value = arr;
      } else {
        // Note: If you modify this.value array, it won't trigger the `set` in computed field
        const arr = clone(this.value);
        arr.splice(this.value.indexOf(this.getItemValue(item)), 1);
        this.value = arr;
      }
    },

    onExpandCombo() {
      this.comboExpanded = !this.comboExpanded;
    },
  },
};
</script>

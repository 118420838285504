<template>
  <div class="page__header page__actions">
    <h2 class="page__title">
      {{ screen.screen.title }}
      <template v-if="screen.screen.multiple"> - {{ screenCopyIndex }}</template>
    </h2>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import notes from '@/mixins/notes';

export default {
  name: 'incident-screen-header',

  props: ['screen', 'screenIndex', 'screenCopyIndex'],

  mixins: [notes],

  computed: {
    ...mapState({
      notes: (state) => state.incident.notes,
    }),

    ...mapGetters(['formatValue']),
  },
};
</script>

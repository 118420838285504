export default {
  created() {
    const prefix = this.$store.getters.inAdminArea ? 'admin/' : '';

    this.resources.forEach((resource) => {
      const fetchResource = () => {
        const dependantId = this[`active${resource.dependant}`][resource.dependantKey || 'id'];
        if (dependantId) {
          this.$store.dispatch(`${prefix}fetch${resource.name}`, dependantId);
          if (resource.setActive) {
            this.$store.commit(`${prefix}setActive${resource.name}`, dependantId);
          }
        }
      };

      if (this[`active${resource.dependant}`].id) {
        fetchResource();
      }
      this.$watch(`active${resource.dependant}.id`, () => fetchResource());
    });
  },
};

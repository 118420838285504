/* eslint-disable no-shadow */

import Vue from 'vue';
import pluralize from 'pluralize';

import Common from '@/common';
import Workflow from '@/workflow';

const state = {
  activeWorkflow: 0,
  workflows: [],
  workflowTypes: Workflow.workflowTypes,
};

const getters = {
  activeWorkflow: (state) => state.workflows.find((workflow) => workflow.id === state.activeWorkflow) || {},
  workflowsByType: (state) => (wType) => state.workflows.filter((workflow) => workflow.workflow_type === wType),
  workflowType:
    (state, getters) =>
    ({ workflow = getters.activeWorkflow, title = true, fullTitle = false, plural = false } = {}) => {
      if (Object.keys(workflow).length === 0) return false;
      let workflowType = workflow.workflow_type;
      if (title) {
        workflowType = state.workflowTypes.find((wt) => wt.id === workflowType);
        workflowType = fullTitle ? workflowType.name : workflowType.shortName;
      }
      return plural ? pluralize(workflowType) : workflowType;
    },
};

const mutations = {
  setWorkflows(state, workflows) {
    state.workflows = workflows;
  },

  setActiveWorkflow(state, workflow) {
    state.activeWorkflow = workflow;
  },
};

const actions = {
  fetchWorkflows({ commit }) {
    return Vue.axios
      .get('workflows')
      .then((response) => {
        commit('setWorkflows', response.data.workflow);
      })
      .catch((error) => Common.handleError(error));
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

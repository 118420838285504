<template>
  <div class="page home">
    <div class="page__card" v-for="wType in filteredWorkflowTypes" :key="wType.id">
      <div class="page__header">
        <div>
          <h1 class="page__title">
            {{ wType.plural === false ? wType.name : pluralize(wType.name) }}
            <i
              class="fa-solid fa-circle-info fa-xs"
              @click="$modal.show('reportable-conditions')"
              v-if="wType.showInfo"
            />
          </h1>
          <div
            class="page__subtitle"
            v-html="wType.description"
            @click.prevent="wType.showInfo && $modal.show('reportable-conditions')"
          ></div>
        </div>
      </div>
      <div class="items">
        <div class="item panel" v-for="workflow in workflowsByType(wType.id)" :key="workflow.id">
          <h2 class="item__name">
            {{ workflow.title }}
            <span class="note no-spacing" v-if="workflow.read_only">(read only)</span>
          </h2>
          <div class="button-container">
            <themed-button
              :to="{ name: 'new-incident', params: { workflowId: workflow.id } }"
              :disabled="workflow.read_only"
            >
              Start New {{ workflowType({ workflow }) }}
            </themed-button>
            <themed-button :to="{ name: 'incidents', params: { workflowId: workflow.id } }">
              View {{ workflowType({ workflow, plural: true }) }}
            </themed-button>
          </div>
          <div class="item__note" v-html="wType.workflowNote" v-if="wType.workflowNote"></div>
        </div>
      </div>
    </div>

    <reportable-conditions />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ReportableConditions from '@/components/ReportableConditions';

export default {
  name: 'workflows',

  components: { ReportableConditions },

  computed: {
    ...mapState({
      workflows: (state) => state.workflow.workflows,
      workflowTypes: (state) => state.workflow.workflowTypes,
    }),

    filteredWorkflowTypes() {
      return this.workflowTypes.filter((wType) => this.workflowsByType(wType.id).length > 0);
    },

    ...mapGetters(['workflowsByType', 'workflowType']),
  },
};
</script>

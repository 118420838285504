<template>
  <div class="charts">
    <h2>Number of {{ workflowType({ plural: true }) }} Per Month - Past 12 Months</h2>

    <div class="chart-psanel">
      <bar-chart class="charts__chart bar_chart" :chart-data="numberOfIncidentsData" />
    </div>
  </div>
</template>

<script>
import fecha from 'fecha';
import { mapState, mapGetters } from 'vuex';
import BarChart from '../chart/Bar';

export default {
  name: 'number-of-incidents-per-month-report',

  props: ['incidents'],

  components: { BarChart },

  computed: {
    ...mapState({
      apiDateFormat: state => state.apiDateFormat,
    }),

    ...mapGetters([
      'themeColour',
      'workflowType',
    ]),

    monthNames() {
      // eslint-disable-next-line max-len
      let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;
      const currentYear = currentDate.getFullYear().toString().substr(-2);
      if (currentMonth !== 12) {
        months = months
          .slice(-(12 - currentMonth))
          .concat(months.slice(0, currentMonth));
      }
      return months.map((month, index) => `${month} ${index >= (12 - currentMonth) ? currentYear : currentYear - 1}`);
    },

    numberOfIncidentsPerMonth() {
      const data = [];
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;

      for (let i = 1; i <= 12; i += 1) {
        data.push(this.incidents.filter((incident) => {
          const completedAt = fecha.parse(incident.completed_at, this.apiDateFormat);
          const month = parseInt(fecha.format(completedAt, 'M'));

          if (i <= (12 - currentMonth)) {
            return i + currentMonth === month;
          }

          return 12 - (currentMonth - month) === i;
        }).length);
      }
      return data;
    },

    numberOfIncidentsData() {
      return {
        labels: this.monthNames,
        datasets: [
          {
            backgroundColor: this.themeColour,
            data: this.numberOfIncidentsPerMonth,
          },
        ],
      };
    },
  },
};
</script>

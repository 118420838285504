<template>
  <div class="multiple-choice">
    <h2>Multiple Choice Question Results</h2>

    <p v-if="!hasFields">No multiple choice questions have yet been answered</p>
    <tabs
      :options="{ useUrlFragment: false }"
      v-if="Object.keys(aggregateIncidents).length > 0"
      v-on:changed="handleTabChange($event)"
    >
      <template v-for="screen in filteredAggregateIncidents">
        <tab :name="screen.title" :key="screen.title">
          <div class="panel charts charts--tabs" v-for="field in screen.fields" :key="field.title">
            <h3 class="charts__label">{{ field.title }}</h3>

            <horizontal-bar-chart
              class="charts__chart charts__chart--spacing"
              :ref="chartId(screen.title)"
              :chart-data="multipleChoiceData(field)"
              :height="chartHeight(field)"
              :style="chartHeightStyle(field)"
            />

            <div class="list-view list-view--compact list-view--panel">
              <div class="list-view__header">
                <div>Answer Choices</div>
                <div>Responses ({{ fieldValues(field).length }})</div>
              </div>

              <div class="list-view__row" v-for="option in fieldLabels(field)" :key="option.id">
                <div class="text-center">{{ option }}</div>
                <div>{{ optionPercentage(field, option).replace('.00', '') }}% ({{ optionNumber(field, option) }})</div>
              </div>
            </div>
          </div>
        </tab>
      </template>
    </tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HorizontalBarChart from '../chart/HorizontalBar';

export default {
  name: 'multiple-choice-questions-report',

  props: ['incidents'],

  components: { HorizontalBarChart },

  computed: {
    ...mapGetters(['activeWorkflow', 'fieldHasOptions', 'themeColour']),

    hasFields() {
      return this.filteredAggregateIncidents.some((screen) => Object.keys(screen.fields).length > 0);
    },

    filteredAggregateIncidents() {
      const aggregateArray = Object.values(this.aggregateIncidents);
      const filtered = aggregateArray.filter((screen) => Object.keys(screen.fields).length > 0);
      return filtered;
    },

    aggregateIncidents() {
      const aggregate = {};
      if (this.activeWorkflow.id) {
        this.incidents.forEach((incident) => {
          if (incident.data && incident.data.screens) {
            incident.data.screens.forEach((screen) => {
              if (!aggregate[screen.screen.id]) {
                aggregate[screen.screen.id] = {
                  title: screen.screen.title,
                  fields: {},
                };
              }
              Object.keys(screen.data).forEach((field) => {
                if (!aggregate[screen.screen.id].fields[field] && this.aggregateField(screen.screen.fields[field])) {
                  aggregate[screen.screen.id].fields[field] = {
                    title: screen.data[field].title,
                    fieldType: screen.screen.fields[field],
                    values: [],
                  };
                }
                if (this.aggregateField(screen.screen.fields[field])) {
                  let data = screen.data[field].data;
                  if (Array.isArray(data)) {
                    data = data.map((d) => d.replace(/:.*$/, ''));
                  } else if (typeof data === 'string') {
                    data = data.replace(/:.*$/, '');
                  }
                  aggregate[screen.screen.id].fields[field].values.push(data);
                }
              });
            });
          }
        });
      }
      return aggregate;
    },
  },

  methods: {
    aggregateField(fieldType) {
      return this.fieldHasOptions(fieldType);
    },

    fieldValues(field) {
      return Array.isArray(field.values[0]) ? field.values.reduce((a, b) => a.concat(b)) : field.values;
    },

    fieldLabels(field) {
      return [...new Set(this.fieldValues(field))];
    },

    optionNumber(field, label) {
      return this.fieldValues(field).filter((i) => i === label).length;
    },

    optionPercentage(field, label) {
      return ((this.optionNumber(field, label) / this.fieldValues(field).length) * 100).toFixed(2);
    },

    chartHeight(field) {
      return this.fieldLabels(field).length * 50;
    },

    chartHeightStyle(field) {
      return `max-height: ${this.chartHeight(field)}px;`;
    },

    multipleChoiceData(field) {
      const data = [];
      this.fieldLabels(field).forEach((label) => {
        data.push(this.optionPercentage(field, label));
      });
      return {
        labels: this.fieldLabels(field),
        datasets: [
          {
            backgroundColor: this.themeColour,
            data,
          },
        ],
      };
    },

    chartId(screenTitle) {
      return `screen-${screenTitle.replace(/\s/g, '-').toLowerCase()}`;
    },

    handleTabChange(tab) {
      this.$refs[this.chartId(tab.tab.name)].forEach((chart) => {
        setTimeout(() => chart.render(), 100);
      });
    },
  },
};
</script>

<script>
import router from "@/router";

export default {
  name: "auth",

  props: ["authToken"],

  render() {
    return null;
  },

  created() {
    this.$store.dispatch("authenticate", this.authToken).then(() => {
      if (!this.$store.state.user.userFetchError) {
        router.push({ name: "index" });
      }
    });
  },
};
</script>

/* eslint-disable no-shadow */

const state = {
  displayModal: false,
  messages: [],
};

const getters = {};

const mutations = {
  addMessages(state, messages) {
    state.messages = messages;
  },

  displayModal(state, status) {
    state.displayModal = status;
  },
};

const actions = {
  displayTriggerModal({ commit }, messages) {
    const popupMessages = messages.filter((message) => message.trigger === 'popup').map((message) => message.data);
    commit('addMessages', popupMessages);
    commit('displayModal', true);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div class="incident-outer-container">
    <themed-button :to="{ name: 'incidents' }" class="page__action page__action--full">
      <i class="fa-solid fa-arrow-left" /> Return to {{ workflowType({ plural: true }) }}
    </themed-button>
    <div class="incident page" v-if="incident.id">
      <div class="page__header">
        <div>
          <div class="page__actions">
            <h1 class="page__title">{{ workflow.title }}</h1>
            <a class="page__action button text-small" @click.native="manageIncidentNotes('incident')" v-if="numberOfNotes() > 0">
              View Notes
              <template>({{ numberOfNotes() }})</template>
            </a>
          </div>
          <div class="incident__details">
            <div class="incident__detail"><strong>Reported by:</strong> {{ userName }}</div>
            <div class="incident__detail"><strong>On:</strong> {{ dateTime(incident.completed_at) }}</div>
            <div class="incident__detail"><strong>Reference:</strong> {{ incident.reference }}</div>
          </div>
        </div>
        <slot name="navigation" />
      </div>
      <slot name="message" />
      <template v-if="incident.data">
        <div
          v-for="(screen, screenIndex) in incident.data.screens"
          :key="screen.id"
          :class="{ 'hide-for-print': !screenHasPrintableFields(screen) }"
        >
          <div v-for="screenCopyIndex in numberOfCopies(screen)" :key="screenCopyIndex">
            <screen-header :screen="screen" :screen-index="screenIndex" :screen-copy-index="screenCopyIndex" />
            <screen-fields :screen="screen" :screen-index="screenIndex" :screen-copy-index="screenCopyIndex" />
          </div>
        </div>
      </template>
      <note-modal :notes="notes" :workflow="workflow" :incident="incident" />
      <trigger-modal />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import notes from '@/mixins/notes';
import TriggerModal from '@/components/TriggerModal';
import ScreenHeader from './screen/Header';
import ScreenFields from './screen/Fields';
import NoteModal from './note/Modal';

export default {
  name: 'incidents',

  components: { ScreenHeader, ScreenFields, NoteModal, TriggerModal },

  mixins: [notes],

  props: ['incident', 'workflow', 'notes', 'userName'],

  computed: {
    ...mapGetters(['dateTime', 'workflowType']),
  },

  methods: {
    numberOfCopies(screen) {
      return screen.screen.multiple ? screen.data.length : 1;
    },

    screenHasPrintableFields(screen) {
      const data = screen.screen.multiple ? Object.assign({}, ...screen.data) : screen.data;
      return Object.values(data).find((field) => field.show_for_print);
    },
  },
};
</script>

<template>
  <div class="container">
    <div class="panel text-center">
      <h1>Page Not Found</h1>
      <p>
        This page doesn't seem to exist
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'not-found',
};
</script>

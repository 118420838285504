<template>
  <div class="form__field form__field--full form__field--no-spacing"></div>
</template>

<script>
import { abstractField } from 'vue-form-generator';

export default {
  mixins: [abstractField],
};
</script>

<template>
  <div class="container">
  <router-link>Back </router-link>
    <div class="page__section">
      <h1>Clone Workflow</h1>
      <vue-form-generator
        :schema="buildSchema('workflow', 'workflowSchema', 'clone', 'new')"
        :model="formModel"
        :options="formOptions" />
    </div>
  </div>
</template>

<script>
import newForm from '@/mixins/new-form';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'admin-clone-workflow',

  mixins: [newForm],

  data() {
    return {
      formName: 'WorkflowClone',
      formParent: 'Company',
      formIndex: 'admin-workflows',
      formIndexParamKey: 'companyId',
      altFormIndex: 'admin-dashboard',
    };
  },

  computed: {
    ...mapState('admin', {
      companies: state => state.company.companies,
      activeCompany: state => state.company.activeCompany,
    }),
  },

  methods: {
    ...mapActions('admin', [
      'fetchCompanies',
    ]),

    getFormIndexParamValue(response) {
      return response.data.workflow.company_id;
    },
  },

  created() {
    this.fetchCompanies();

    if (this.activeCompany) {
      this.formModel.company_id = this.activeCompany;
    }
  },
};
</script>

<template>
  <div>
    <h2 id="edit-title">Edit {{ activeWorkflow.title }}</h2>
    <tabs :options="{ useUrlFragment: false }">
      <tab name="Basic Info">
        <vue-form-generator :schema="buildSchema('workflow', 'workflowSchema', 'basicInfo', 'edit')" :model="formModel"
          :options="formOptions" />
      </tab>
      <tab name="Triggers">
        <vue-form-generator :schema="buildSchema('trigger', 'triggerSchema', 'basicInfo', 'edit')"
          :model="objects[activeObject]" :options="formOptions" />

        <div class="list-view list-view--inline" v-if="objects.length > 1">
          <div class="list-view__header">
            <div>Field</div>
            <div>Condition</div>
            <div class="list-view__small-column">Trigger Type</div>
            <div class="list-view__small-column">Always Run</div>
            <div class="list-view__small-column">Actions</div>
          </div>

          <div class="list-view__row" v-for="(trigger, index) in initialObjects" :key="trigger.id" v-if="index > 0">
            <div>{{ fieldById(trigger.field_id).title || '-' }}</div>
            <div>{{ friendlyOperatorName(trigger.operator) }}
              <template v-if="friendlyOperatorName(trigger.operator)">
                ({{ trigger.operator }}): {{ trigger.value }}
              </template>
              <template v-else>
                -
              </template>
            </div>
            <div class="list-view__small-column">{{ trigger.trigger }}</div>
            <div class="list-view__small-column">
              <span class="show-for-small">Always Run: </span>
              <i class="fa-solid fa-check" v-if="trigger.always_run" />
              <i class="fa-solid fa-xmark" v-else />
            </div>
            <div class="list-view__small-column">
              <button class="icon-button" @click="editObject(index)">
                <i class="fa-solid fa-pen-to-square" />
              </button>
              <button class="icon-button" @click="deleteObject(trigger)">
                <i class="fa-solid fa-trash" />
              </button>
            </div>
          </div>
        </div>
      </tab>
    </tabs>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import editForm from '@/mixins/edit-form';
import inlineEditForm from '@/mixins/inline-edit-form';

export default {
  name: 'admin-edit-workflow',
  mixins: [editForm, inlineEditForm],

  data: () => {
    return {
      formName: 'Workflow',
      inlineName: 'Trigger',
      inlineId: 'triggers',
    };
  },

  computed: {
    ...mapState('admin', {
      triggers: state => state.trigger.triggers,
      fields: state => state.field.allFields,
    }),

    ...mapGetters('admin', [
      'activeCompany',
      'activeWorkflow',
      'fieldById',
      'friendlyOperatorName',
    ]),

    formIndex() {
      return this.activeWorkflow.global ? 'admin-dashboard' : 'admin-workflows';
    },
  },

  watch: {
    activeWorkflow: {
      handler() {
        if (this.activeWorkflow.id) {
          this.defineFormObjects();
          this.setupObjects();
        }
      },
      immediate: true,
    },

    fields() {
      this.addFieldsToTriggerDropdown(this.dropdownFields(this.fields));
    },

    triggers() {
      this.setupObjects();
    },
  },

  methods: {
    ...mapMutations('admin', [
      'addFieldsToTriggerDropdown',
    ]),
  },
};
</script>

<template>
  <div>
    <admin-company-index />
    <admin-global-workflows />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import AdminGlobalWorkflows from '@/components/admin/workflows/global/Index';
import AdminCompanyIndex from '@/components/admin/companies/Index';

export default {
  components: { AdminGlobalWorkflows, AdminCompanyIndex },

  methods: {
    ...mapMutations('admin', [
      'setActiveCompany',
    ]),

    ...mapActions('admin', [
      'fetchGlobalWorkflows',
    ]),
  },

  created() {
    this.fetchGlobalWorkflows();
    this.setActiveCompany(0);
  },
};
</script>

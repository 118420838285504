import company from './admin/company';
import workflow from './admin/workflow';
import screen from './admin/screen';
import field from './admin/field';
import trigger from './admin/trigger';
import operator from './admin/operator';

export default {
  namespaced: true,
  modules: {
    company,
    workflow,
    screen,
    field,
    trigger,
    operator,
  },
};

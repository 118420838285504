import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';
import state from './state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import user from './modules/user';
import company from './modules/company';
import workflow from './modules/workflow';
import incident from './modules/incident';
import field from './modules/field';
import trigger from './modules/trigger';
import admin from './modules/admin';
import lawyer from './modules/lawyer';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    user,
    company,
    workflow,
    incident,
    field,
    trigger,
    admin,
    lawyer,
  },
  strict: debug,
});

Vue.use(VueAxios, axios);

Vue.axios.defaults.baseURL = process.env.API_HOST;
Vue.axios.defaults.timeout = 10000;

Vue.axios.interceptors.request.use((request) => {
  const url = request.url.replace(`${request.baseURL}/`, '');

  if (request.method === 'get') {
    store.commit('setReportLoading', { url, status: true });
  }

  if (user.state.sessionId && (url !== 'auth' || (url === 'auth' && request.method === 'delete'))) {
    request.headers = { Authorization: user.state.sessionId };
  }

  return request;
});

Vue.axios.interceptors.response.use(
  (response) => {
    const url = response.config.url.replace(`${response.config.baseURL}/`, '');

    if (response.config.method === 'get') {
      store.commit('setReportLoading', { url, status: false });
    }

    return response;
  },
  (error) => {
    const url = error.config.url.replace(`${error.config.baseURL}/`, '');
    store.commit('setReportLoading', { url, status: false });

    return Promise.reject(error);
  },
);

export default store;

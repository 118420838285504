import Vue from 'vue';
import clone from 'lodash.clonedeep';

export default {
  setMessage(state, message) {
    state.message.text = message.text;
    state.message.messageType = message.messageType || 'success';
  },

  setAlreadyScrolled(state, status) {
    state.alreadyScrolled = status;
  },

  setApiConnectionError(state, status) {
    state.apiConnectionError = status;
  },

  setNotFoundError(state, status) {
    state.notFoundError = status;
  },

  setSubmittingForm(state, status) {
    state.submittingForm = status;
  },

  setReportLoading(state, { url, status }) {
    if (status) {
      state.reportLoading.push(url);
    } else {
      const urlIndex = state.reportLoading.findIndex((loadedUrl) => loadedUrl === url);
      if (urlIndex !== -1) {
        state.reportLoading.splice(urlIndex, 1);
      }
    }
    return state.reportLoading;
  },

  duplicateSchema(state, schema) {
    const group = clone(
      schema.groups.filter((g) => g.groupIndex !== undefined).sort((a, b) => b.groupIndex - a.groupIndex)[0],
    );
    group.groupIndex += 1;
    group.fields.map((field) => {
      field.model = field.model.replace(/\d-/, `${group.groupIndex}-`);
      if (field.groupIndex !== undefined) {
        field.groupIndex += 1;
      }
      if (field.hideForFirstGroup) {
        field.visible = true;
      }
      return field;
    });
    schema.groups.push(group);
  },

  removeDuplicateSchemas(state, schema) {
    schema.groups.splice(2);
  },

  removeRow(state, { schema, groupIndex }) {
    const index = schema.groups.findIndex((group) => group.groupIndex === groupIndex);
    Vue.delete(schema.groups, index);
  },

  setShowWarningPanel(state, status) {
    state.showWarningPanel = status;
    localStorage.setItem('showWarningPanel', status);
  },

  setShowInfoPanel(state, status) {
    state.showInfoPanel = status;
    localStorage.setItem('showInfoPanel', status);
  },
};

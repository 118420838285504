<template>
  <div class="view">
    <header class="header">
      <div class="header__inner site-gutter">
        <div class="flex-row flex--vert-center">
          <router-link :to="{ name: activeCompany.id ? 'workflows' : 'index' }">
            <img src="../assets/images/report-logo.svg" alt="rradarreport" class="header__logo" />
          </router-link>
          <div class="environment-badge" v-if="checkIsDevelopment || checkIsStaging">
            <p v-if="checkIsDevelopment">Development</p>
            <p v-if="checkIsStaging">Staging</p>
          </div>
        </div>


        <pulse-loader
          :loading="showLoadingSpinner"
          color="#333"
          size="10px"
          class="v-spinner--report-loading spacing-left"
          title="Waiting for response from API"
        />

        <div class="header__info" v-if="isAuthenticated">
          <div class="header__actions">
            <themed-button :to="{ name: 'admin-dashboard' }" v-if="isAdminUser" class="header__info-section">
              Admin Area
            </themed-button>
            <a
              href="https://rradar-docs.s3.eu-west-2.amazonaws.com/rradarreport-user-guide.pdf"
              v-if="isStandardUser"
              class="header__info-section button"
              target="_blank"
            >
              User Guide
            </a>
            <themed-button :to="{ name: 'lawyer-dashboard' }" v-if="isLawyerUser" class="header__info-section">
              Lawyer Area
            </themed-button>
          </div>

          <div>
            <div class="header__info-section" v-show="activeCompany.name">
              <strong>Working as</strong>
              <div>
                {{ activeCompany.name }}
                <span class="text-small" v-if="switchCompanyUrl"> (<a :href="switchCompanyUrl">Switch</a>) </span>
              </div>
            </div>
            <div class="header__info-section">
              <strong>Logged in as</strong>
              <div>
                {{ fullName }}
                <span class="text-small"> (<a href="" @click.prevent="logoutUser">Log Out</a>) </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <div class="message" :class="[{ open: message.text }, message.messageType]">
      {{ message.text }}
    </div>

    <div class="site-gutter" v-if="checkShowWarningPanel || checkShowInfoPanel">
      <warning-panel :check-show-warning-panel="checkShowWarningPanel" :set-show-warning-panel="setShowWarningPanel" />
      <info-panel :check-show-info-panel="checkShowInfoPanel" :set-show-info-panel="setShowInfoPanel" />
    </div>

    <api-error v-if="apiConnectionError" />
    <not-found v-else-if="notFoundError" />
    <user-fetch-error v-else-if="userFetchError" :switchCompanyUrl="switchCompanyUrl" @logoutUser="logoutUser" />
    <router-view v-else class="site-gutter" />

    <footer class="footer">
      <div class="site-gutter footer__inner">
        <a href="http://www.rradar.com">
          <img src="../assets/images/rradar-logo.svg" alt="rradar" class="footer__logo" />
        </a>

        <div class="footer__terms">
          &copy; {{ new Date().getFullYear() }} rradar Ltd.
          <span class="footer__link">
            <a href="https://www.rradar.com/terms-and-conditions" target="_blank">Terms & Conditions</a>
          </span>
          <span class="footer__link">
            <a href="https://www.rradar.com/privacy-policy" target="_blank">Privacy Policy</a>
          </span>
        </div>
      </div>
    </footer>

    <v-dialog />
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import router from '@/router/index';
import PulseLoader from './PulseLoader';
import ApiError from './ApiError';
import NotFound from './NotFound';
import WarningPanel from './WarningPanel';
import InfoPanel from './InfoPanel';
import UserFetchError from './UserFetchError';

export default {
  name: 'app',

  components: { ApiError, NotFound, PulseLoader, WarningPanel, InfoPanel, UserFetchError },

  data() {
    return {
      showLoadingSpinner: false,
      switchCompanyUrl: null,
    };
  },

  computed: {
    ...mapState(['message', 'apiConnectionError', 'notFoundError', 'reportLoading']),

    ...mapState({
      companies: (state) => state.company.availableCompanies,
      currentUser: (state) => state.user.currentUser,
      userFetchError: (state) => state.user.userFetchError,
    }),

    ...mapGetters([
      'inAdminArea',
      'isAuthenticated',
      'isStandardUser',
      'isAdminUser',
      'isLawyerUser',
      'fullName',
      'activeCompany',
      'inStandardArea',
      'themeColour',
    ]),

    checkShowInfoPanel() {
      return !this.inAdminArea && this.$store.state.showInfoPanel;
    },

    checkShowWarningPanel() {
      return !this.inAdminArea && this.$store.state.showWarningPanel;
    },

    checkIsDevelopment() {
      return window.location.host.includes('localhost') || window.location.host.includes('2ra');
    },

    checkIsStaging() {
      return window.location.host.includes('rreframe');
    },
  },

  watch: {
    themeColour() {
      if (window.cordova && window.StatusBar && this.inStandardArea) {
        StatusBar.backgroundColorByHexString(this.themeColour);
      }
    },

    isAuthenticated: {
      handler() {
        if (this.isAuthenticated) {
          this.fetchAvailableCompanies().then((companies) => {
            const activeCompany = companies[0].id;
            this.fetchWorkflows(activeCompany);
          });
        }
      },
      immediate: true,
    },

    reportLoading(newValue) {
      if (newValue.length > 0) {
        setTimeout(() => (this.showLoadingSpinner = this.reportLoading.length > 0), 2000);
      } else {
        this.showLoadingSpinner = false;
      }
    },
  },

  methods: {
    ...mapActions(['fetchAvailableCompanies', 'fetchUsers', 'fetchWorkflows', 'logout']),
    ...mapMutations(['setShowWarningPanel', 'setShowInfoPanel']),

    logoutUser() {
      this.logout().then(() => {
        this.axios.get('deauth').then((response) => {
          if (window.cordova) {
            // eslint-disable-next-line max-len
            const browserLogout = window.cordova.InAppBrowser.open(
              response.data,
              '_blank',
              'hidden=yes,clearsessioncache=yes,clearcache=yes',
            );
            router.push({ name: 'login' });
            browserLogout.addEventListener('loadstop', () => browserLogout.close());
          } else {
            window.location = response.data;
          }
        });
      });
    },

    getSwitchCompanyUrl() {
      return this.axios.get('auth/companies/switch');
    },
  },

  created() {
    this.getSwitchCompanyUrl().then((response) => {
      let switchCompanyUrl = response.data.company.switch_company_url;
      if (process.env.REDIRECT_URL_UID) {
        switchCompanyUrl += `&redirect_url_uid=${process.env.REDIRECT_URL_UID}`;
      }
      this.switchCompanyUrl = switchCompanyUrl;
    });

    if (!localStorage.showWarningPanel || localStorage.showWarningPanel === 'true') {
      this.setShowWarningPanel(true);
    }

    if (!localStorage.showInfoPanel || localStorage.showInfoPanel === 'true') {
      this.setShowInfoPanel(true);
    }
  },
};
</script>

<template>
  <div>
    <h1>Add Screen</h1>
    <vue-form-generator
      :schema="buildSchema('screen', 'screenSchema', 'basicInfo', 'new')"
      :model="formModel"
      :options="formOptions" />
  </div>
</template>

<script>
import newForm from '@/mixins/new-form';

export default {
  name: 'admin-new-screen',
  mixins: [newForm],

  data() {
    return {
      formName: 'Screen',
      formParent: 'Workflow',
      formIndex: 'admin-screens',
    };
  },
};
</script>

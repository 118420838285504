const roles = [
  { id: 'admin', name: 'Admin' },
  { id: 'manager', name: 'Manager' },
  { id: 'analyst', name: 'Analyst' },
  { id: 'reporter', name: 'Reporter' },
  { id: 'observer', name: 'Observer' },
];

const fullName = (firstName, lastName) => {
  if (firstName !== lastName) {
    return `${firstName} ${lastName}`;
  }
  return firstName;
};

const users = (items) => {
  return items
    .map((item) => {
      const details = item.details || item;
      item.name = `${details.first_name} ${details.last_name}`;
      return item;
    })
    .sort((a, b) => a.name.localeCompare(b.name));
};

export default {
  roles,
  fullName,
  users,
};

/* eslint-disable no-shadow */

import Vue from 'vue';

import Common from '@/common';

const state = {
  activeCompany: 0,
  availableCompanies: [],
};

const getters = {
  activeCompany: (state) => state.availableCompanies.find((company) => company.id === state.activeCompany) || {},
};

const mutations = {
  setAvailableCompanies(state, companies) {
    state.availableCompanies = companies;
  },

  setActiveCompany(state, company) {
    state.activeCompany = company;
  },

  editPrimaryUser(state, { company, user }) {
    company.primary_user_accounts_id = user.primary_user_accounts_id;
  },
};

const actions = {
  fetchAvailableCompanies({ commit }) {
    return Vue.axios
      .get('auth/companies')
      .then((response) => {
        commit('setAvailableCompanies', response.data.company);
        commit('setActiveCompany', response.data.company[0].id);
        return state.availableCompanies;
      })
      .catch((error) => Common.handleError(error));
  },

  editPrimaryUser({ getters, commit, dispatch }, { data, form }) {
    return Vue.axios
      .put('companies/primary-user', data)
      .then((response) => {
        commit('editPrimaryUser', {
          company: getters.activeCompany,
          user: response.data.company,
        });
        dispatch('setMessage', { text: 'Successfully transferred primary user' }, { root: true });
      })
      .catch((error) => Common.handleError(error, form));
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

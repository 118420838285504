<template>
  <router-view></router-view>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'admin',

  methods: {
    ...mapActions('admin', [
      'fetchAdmins',
      'fetchLawyers',
    ]),
  },

  created() {
    this.fetchAdmins();
    this.fetchLawyers();
  },
};
</script>

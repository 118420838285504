<template>
  <div v-if="fields.length > 0">
    <div class="admin__header">
      <h1 class="no-margin">{{ activeScreen.title }} Fields</h1>
      <router-link :to="{ name: 'admin-new-field' }" class="button">Add Field</router-link>
    </div>
    <div class="list-view">
      <div class="list-view__header">
        <div>Title</div>
        <div>Field Type</div>
        <div class="list-view__small-column">Required</div>
        <div class="list-view__small-column">Actions</div>
      </div>
      <div class="list-view__row" v-for="(field, index) in fields" :key="field.id">
        <div>
          <router-link :to="{ name: 'admin-edit-field', params: { fieldId: field.id } }">
            {{ field.position }}: {{ field.title }}
          </router-link>
        </div>
        <div>{{ fieldTypes[field.field_type].name }}</div>
        <div class="list-view__small-column">
          <span class="show-for-small">Required: </span>
          <i class="fa-solid fa-check" v-if="field.required" />
          <i class="fa-solid fa-xmark" v-else />
        </div>
        <div class="list-view__small-column">
          <button class="icon-button" @click="alterPosition(field.id, field.position - 1)" :disabled="index === 0">
            <i class="fa-solid fa-arrow-up" />
          </button>
          <button class="icon-button" @click="alterPosition(field.id, field.position + 1)"
            :disabled="index === fields.length - 1">
            <i class="fa-solid fa-arrow-down" />
          </button>
          <router-link :to="{ name: 'admin-edit-field', params: { fieldId: field.id } }">
            <i class="fa-solid fa-pen-to-square" />
          </router-link>
          <button class="icon-button" @click="deleteField(field)">
            <i class="fa-solid fa-trash" />
          </button>
        </div>
      </div>
    </div>
  </div>
  <no-items v-else :parent="activeScreen.title" name="Field" icon="inbox-in" new-link="admin-new-field" />
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Common from '@/common';
import NoItems from '@/components/NoItems';

export default {
  name: 'admin-fields',

  components: { NoItems },

  computed: {
    ...mapState({
      fields: state => state.admin.field.fields,
      fieldTypes: state => state.field.inputTypes,
    }),

    ...mapGetters('admin', [
      'activeScreen',
    ]),
  },

  methods: {
    deleteField(field) {
      Common.confirmDialog({
        modal: this.$modal,
        title: `Are you sure you want to delete ${field.title}?`,
        okHandler: () => {
          this.$store.dispatch('admin/deleteField', {
            screenId: this.activeScreen.id,
            fieldId: field.id,
          });
        },
      });
    },

    alterPosition(fieldId, position) {
      this.$store.dispatch('admin/editField', { data: { id: fieldId, position }, noCommit: true })
        .then(() => this.$store.dispatch('admin/fetchFields', { dependsOn: this.activeScreen.id }));
    },
  },
};
</script>

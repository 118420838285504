<template>
  <div>
    <h1>Add Global Workflow</h1>
    <vue-form-generator
      :schema="buildSchema('workflow', 'workflowSchema', 'basicInfo', 'new')"
      :model="workflow"
      :options="formOptions" />
  </div>
</template>

<script>
import newForm from '@/mixins/new-form';

export default {
  name: 'admin-new-global-workflow',
  mixins: [newForm],

  data() {
    return {
      workflow: {
        colour: '#cc0000',
      },
      formName: 'GlobalWorkflow',
      formIndex: 'admin-dashboard',
    };
  },
};
</script>

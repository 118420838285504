/* eslint-disable no-shadow */

import Vue from 'vue';

import Common from '@/common';
import Store from '@/store/index';

const populateCloneableWorkflows = (companyId) => {
  let url;
  if (companyId) {
    url = `admin/companies/${companyId}/workflows`;
  } else {
    url = 'admin/workflows';
  }

  Vue.axios.get(url).then((response) => {
    let workflows = response.data.workflow;
    if (companyId) workflows = workflows.filter((workflow) => !workflow.global);
    const values = workflows.map((workflow) => {
      return { id: workflow.workflow_id || workflow.id, name: workflow.title };
    });
    Store.commit('admin/populateCloneableWorkflows', { values });
  });
};

const state = {
  workflows: [],
  activeWorkflow: 0,
  loading: false,
  workflowSchema: {
    basicInfo: {
      fields: [
        {
          type: 'customInput',
          inputType: 'text',
          styleClasses: 'form__field',
          label: 'Title',
          model: 'title',
          validator: 'string',
          required: true,
          notOn: 'companyEdit',
        },
        {
          type: 'customInput',
          inputType: 'text',
          styleClasses: 'form__field',
          label: 'Title',
          model: 'title',
          validator: 'string',
          onlyOn: 'companyEdit',
        },
        {
          type: 'customSelect',
          styleClasses: 'form__field',
          label: 'Workflow Type',
          model: 'workflow_type',
          values: () => {
            return Store.state.workflow.workflowTypes.map((workflowType) => {
              return { id: workflowType.id, name: workflowType.name };
            });
          },
          validator: 'string',
          required: true,
          notOn: 'companyEdit',
        },
        {
          type: 'customInput',
          inputType: 'color',
          styleClasses: 'form__field',
          label: 'Colour',
          model: 'colour',
          validator: 'string',
          required: true,
          notOn: 'companyEdit',
        },
        {
          type: 'customInput',
          inputType: 'color',
          styleClasses: 'form__field',
          label: 'Colour',
          model: 'colour',
          validator: 'string',
          onlyOn: 'companyEdit',
        },
        {
          type: 'customCheckbox',
          styleClasses: 'form__field',
          label: 'Published',
          model: 'published',
          onlyOn: 'edit',
        },
        {
          type: 'customCheckbox',
          styleClasses: 'form__field form__field--last',
          label: 'Published',
          model: 'published',
          onlyOn: 'companyEdit',
        },
        {
          type: 'customInput',
          inputType: 'text',
          styleClasses: 'form__field',
          label: 'Company Email Template',
          model: 'company_email_template',
          validator: 'string',
          notOn: 'companyEdit',
        },
        {
          type: 'customInput',
          inputType: 'text',
          styleClasses: 'form__field',
          label: 'rradar Email Template',
          model: 'rradar_email_template',
          validator: 'string',
          onlyOn: 'edit',
        },
        {
          type: 'customInput',
          inputType: 'text',
          styleClasses: 'form__field form__field--last',
          label: 'rradar Email Template',
          model: 'rradar_email_template',
          validator: 'string',
          onlyOn: 'new',
        },
        {
          model: '',
          type: 'customSubmit',
          styleClasses: 'form__field',
          buttonText: 'Add Workflow',
          onlyOn: 'new',
          onSubmit: (model, schema, context, form) => {
            context.addForm(model, form);
          },
          validateBeforeSubmit: true,
        },
        {
          model: '',
          type: 'customSubmit',
          styleClasses: 'form__field',
          buttonText: 'Edit Workflow',
          onlyOn: ['edit', 'companyEdit'],
          onSubmit: (model, schema, context, form) => {
            context.editForm(model, form);
          },
          validateBeforeSubmit: true,
        },
      ],
    },
    clone: {
      fields: [
        {
          type: 'customSelect',
          styleClasses: 'form__field',
          label: 'From Company',
          model: 'from_company_id',
          values: () => {
            return Store.state.admin.company.companies
              .map((company) => {
                return { id: company.id, name: company.name };
              })
              .sort((a, b) => a.name.localeCompare(b.name));
          },
          onCreated: (model, companyId) => populateCloneableWorkflows(companyId),
          onChanged: (model, companyId) => populateCloneableWorkflows(companyId),
          validator: 'number',
        },
        {
          type: 'customSelect',
          styleClasses: 'form__field',
          label: 'To Company',
          model: 'company_id',
          values: () => {
            return Store.state.admin.company.companies
              .map((company) => {
                return { id: company.id, name: company.name };
              })
              .sort((a, b) => a.name.localeCompare(b.name));
          },
          validator: 'number',
        },
        {
          type: 'customSelect',
          styleClasses: 'form__field',
          label: 'Workflow',
          model: 'workflow_id',
          values: [],
          validator: 'number',
          required: true,
        },
        {
          type: 'customInput',
          inputType: 'text',
          styleClasses: 'form__field',
          label: 'Title',
          model: 'title',
          validator: 'string',
        },
        {
          model: '',
          type: 'customSubmit',
          styleClasses: 'form__field',
          buttonText: 'Clone Workflow',
          onlyOn: 'new',
          onSubmit: (model, schema, context, form) => {
            context.addForm(model, form);
          },
          validateBeforeSubmit: true,
        },
      ],
    },
  },
};

const getters = {
  activeWorkflow: (state) => state.workflows.find((workflow) => workflow.id === state.activeWorkflow) || {},
  activeCompanyWorkflow: (state, getters) => getters.activeWorkflow,
  activeWorkflowId: (state, getters) => {
    const workflowId = state.workflows.findIndex((workflow) => workflow.id === getters.activeWorkflow.id);
    return workflowId >= 0 ? workflowId : 0;
  },
  workflowById: (state) => (workflowId) => state.workflows.find((workflow) => workflow.id === workflowId),
  workflowIndexById: (state) => (workflowId) => {
    const workflowIndex = state.workflows.findIndex((workflow) => workflow.id === workflowId);
    return workflowIndex >= 0 ? workflowIndex : 0;
  },
};

const mutations = {
  setWorkflows(state, workflows) {
    state.workflows = workflows;
  },

  setActiveWorkflow(state, workflow) {
    state.activeWorkflow = workflow;
  },

  addWorkflow(state, workflow) {
    const existingWorkflow = state.workflows.findIndex((stateWorkflow) => stateWorkflow.id === workflow.id);
    if (existingWorkflow >= 0) {
      Vue.set(state.workflows, existingWorkflow, workflow);
    } else {
      state.workflows.push(workflow);
    }
  },

  editWorkflow(state, { workflowId, editedWorkflow }) {
    Vue.set(state.workflows, workflowId, editedWorkflow);
  },

  removeWorkflow(state, workflow) {
    Vue.delete(state.workflows, workflow);
  },

  setCloningState(state, { status, workflowId }) {
    Vue.set(state.workflows[workflowId], 'cloning', status);
  },

  setLoadingState(state, status) {
    state.loading = status;
  },

  populateCloneableWorkflows(state, { values }) {
    const field = state.workflowSchema.clone.fields.find((field) => field.model === 'workflow_id');
    field.values = values;
  },
};

const actions = {
  fetchWorkflows({ commit }, { dependsOn }) {
    return Vue.axios
      .get(`admin/companies/${dependsOn}/workflows`)
      .then((response) => {
        const workflows = response.data.workflow.map((workflow) => {
          workflow.company_workflow_id = workflow.id;
          workflow.id = workflow.workflow_id;
          return workflow;
        });
        commit('setWorkflows', workflows);
      })
      .catch();
  },

  fetchGlobalWorkflows({ commit }) {
    return Vue.axios
      .get('admin/workflows')
      .then((response) => {
        commit('setWorkflows', response.data.workflow);
      })
      .catch((error) => Common.handleError(error));
  },

  fetchWorkflow({ commit }, workflowId) {
    return Vue.axios
      .get(`admin/workflows/${workflowId}`)
      .then((response) => {
        commit('addWorkflow', response.data.workflow);
      })
      .catch((error) => Common.handleError(error));
  },

  addWorkflow({ commit, dispatch }, { data, parentId, form }) {
    return Vue.axios
      .post(`admin/companies/${parentId}/workflows`, data)
      .then((response) => {
        commit('addWorkflow', response.data.workflow);
        dispatch('setMessage', { text: 'Successfully created workflow' }, { root: true });
      })
      .catch((error) => Common.handleError(error, form));
  },

  addGlobalWorkflow({ commit, dispatch }, { data, form }) {
    return Vue.axios
      .post('admin/workflows', data)
      .then((response) => {
        commit('addWorkflow', response.data.workflow);
        dispatch('setMessage', { text: 'Successfully created workflow' }, { root: true });
      })
      .catch((error) => Common.handleError(error, form));
  },

  addWorkflowClone({ commit, dispatch }, { data, parentId, form }) {
    commit('setLoadingState', true);
    return new Promise((resolve) => {
      return Vue.axios
        .post(`admin/workflows/${data.workflow_id}/clone`, {
          company_id: data.company_id || parentId,
          title: data.title,
        })
        .then((response) => {
          commit('addWorkflow', response.data.workflow);
          commit('setLoadingState', false);
          dispatch('setMessage', { text: 'Successfully cloned workflow' }, { root: true }).then(() =>
            resolve(response),
          );
        })
        .catch((error) => {
          Common.handleError(error, form);
          commit('setLoadingState', false);
        });
    });
  },

  editWorkflow({ getters, commit, dispatch }, { data, form }) {
    return Vue.axios
      .put(`admin/workflows/${data.id}`, data)
      .then((response) => {
        commit('editWorkflow', {
          workflowId: getters.activeWorkflowId,
          editedWorkflow: response.data.workflow,
        });
        dispatch('setMessage', { text: `Successfully edited ${data.title}` }, { root: true });
      })
      .catch((error) => Common.handleError(error, form));
  },

  editCompanyWorkflow({ getters, commit, dispatch }, { data, parentId, form }) {
    return Vue.axios
      .put(`admin/companies/${parentId}/workflows/${data.id}`, data)
      .then((response) => {
        commit('editWorkflow', {
          workflowId: getters.activeWorkflowId,
          editedWorkflow: response.data.workflow,
        });
        dispatch('setMessage', { text: `Successfully edited ${data.title}` }, { root: true });
      })
      .catch((error) => Common.handleError(error, form));
  },

  deleteWorkflow({ getters, commit, dispatch }, workflowId) {
    return Vue.axios
      .delete(`admin/workflows/${workflowId}`)
      .then(() => {
        const workflowIndex = getters.workflowIndexById(workflowId);
        commit('removeWorkflow', workflowIndex);
        dispatch('setMessage', { text: 'Successfully deleted workflow' }, { root: true });
      })
      .catch((error) => Common.handleError(error));
  },

  cloneWorkflow({ getters, commit, dispatch }, { companyId, workflowId, clonedTitle }) {
    commit('setCloningState', {
      status: true,
      workflowId: getters.workflowIndexById(workflowId),
    });
    return Vue.axios
      .post(`admin/workflows/${workflowId}/clone`, {
        company_id: companyId,
        title: clonedTitle,
      })
      .then((response) => {
        if (response.data.workflow.original_id) {
          commit('removeWorkflow', getters.workflowIndexById(response.data.workflow.original_id));
        }
        commit('addWorkflow', response.data.workflow);
        commit('setCloningState', {
          status: false,
          workflowId: getters.workflowIndexById(workflowId),
        });
        dispatch('setMessage', { text: 'Successfully cloned workflow' }, { root: true });
      })
      .catch((error) => Common.handleError(error));
  },

  restoreWorkflow({ getters, commit, dispatch }, { companyId, workflowId }) {
    return Vue.axios
      .post(`admin/workflows/${workflowId}/restore`, { company_id: companyId })
      .then((response) => {
        commit('removeWorkflow', getters.workflowIndexById(workflowId));
        commit('addWorkflow', response.data.workflow);
        dispatch('setMessage', { text: 'Successfully restored workflow' }, { root: true });
      })
      .catch((error) => Common.handleError(error));
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

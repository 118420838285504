<template>
  <!-- eslint-disable vue/require-v-for-key -->
  <div class="radio-list" :disabled="disabled">
    <div class="inline-fields">
      <label v-for="item in items" class="input input--radio" :class="{'is-checked': isItemChecked(item)}">
        <input
          type="radio"
          class="input__default input__default--radio"
          :disabled="disabled"
          :name="id"
          @click="onSelection(item)"
          :value="getItemValue(item)"
          :checked="isItemChecked(item)">
        <div class="input__styled input__styled--radio" :style="radioBackground(item)"></div>
          {{ getItemName(item) }}
      </label>
    </div>
  </div>
</template>

<script>
import { abstractField } from 'vue-form-generator';
import { mapGetters } from 'vuex';

export default {
  /* eslint-disable no-throw-literal */
  /* eslint-disable max-len */
  mixins: [abstractField],

  computed: {
    items() {
      const values = this.schema.values;
      if (typeof (values) === 'function') {
        return values.apply(this, [this.model, this.schema]);
      }
      return values;
    },
    id() {
      return this.schema.model;
    },
    ...mapGetters([
      'activeCompany',
      'themeColour',
    ]),
  },

  methods: {
    getItemValue(item) {
      if (item) {
        if (
          typeof this.schema.radiosOptions !== 'undefined'
            && typeof this.schema.radiosOptions.value !== 'undefined'
        ) {
          return item[this.schema.radiosOptions.value];
        }
        if (typeof item.value !== 'undefined') {
          return item.value;
        }
        throw '`value` is not defined. If you want to use another key name, add a `value` property under `radiosOptions` in the schema. https://icebob.gitbooks.io/vueformgenerator/content/fields/radios.html#radios-field-with-object-values';
      } else {
        return item;
      }
    },

    getItemName(item) {
      if (item) {
        if (typeof this.schema.radiosOptions !== 'undefined' && typeof this.schema.radiosOptions.name !== 'undefined') {
          return item[this.schema.radiosOptions.name];
        }
        if (typeof item.name !== 'undefined') {
          return item.name;
        }
        throw '`name` is not defined. If you want to use another key name, add a `name` property under `radiosOptions` in the schema. https://icebob.gitbooks.io/vueformgenerator/content/fields/radios.html#radios-field-with-object-values';
      } else {
        return item;
      }
    },

    onSelection(item) {
      this.value = this.getItemValue(item);
    },

    isItemChecked(item) {
      const currentValue = this.getItemValue(item);
      return (currentValue === this.value);
    },

    radioBackground(item) {
      if (this.isItemChecked(item)) {
        return { 'background-color': this.themeColour };
      }
      return {};
    },
  },
};
</script>


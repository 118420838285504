import { Bar, mixins } from 'vue-chartjs';
import merge from 'deepmerge';

export default {
  extends: Bar,
  mixins: [mixins.reactiveProp],
  props: ['chartData', 'options'],

  computed: {
    barOptions() {
      return merge.all([
        this.options || {},
        {
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  min: 0,
                  stepSize: 1,
                },
              },
            ],
            xAxes: [
              {
                barPercentage: 0.6,
                categoryPercentage: 1.0,
                ticks: {
                  autoSkip: false,
                },
              },
            ],
          },
        },
      ]);
    },
  },

  mounted() {
    this.renderChart(this.chartData, this.barOptions);
  },
};

<template>
  <div v-if="requestedFiles.length > 0">
    <a v-for="(file, index) in requestedFiles"
      :key="file.id"
      :href="file.url"
      :download="fileName(file, index + 1)"
      class="download-link"
      @click="handleDownload(file, index)">
        Download File #{{ index + 1 }}<template v-if="index < requestedFiles.length - 1">,</template>
    </a>
  </div>
  <button v-else
    :class="requestButtonClasses"
    @click="requestDownload">
      Request Download Links
  </button>
</template>

<script>
import Theme from '@/mixins/theme';

export default {
  props: ['files', 'buttonClasses'],

  mixins: [Theme],

  data() {
    return {
      requestedFiles: [],
      color: themeColour,
    };
  },

  computed: {
    requestButtonClasses() {
      return `${this.buttonClasses} button_colour` || 'button button--center small button_colour';
    },
  },

  methods: {
    requestDownload() {
      return this.files.forEach((file) => {
        this.$http.get(`uploads/${file}`).then((response) => {
          const upload = response.data.upload;
          this.requestedFiles.push({ id: file, file_type: upload.file_type, mime_type: upload.mime_type });
        })
          .then(() => {
            const fileIndex = this.requestedFiles.findIndex(foundFile => foundFile.id === file);
            this.$http.get(`uploads/${file}/download`, { responseType: 'blob' })
              .then((response) => {
                this.$set(this.requestedFiles[fileIndex], 'url', URL.createObjectURL(response.data));
                this.$set(this.requestedFiles[fileIndex], 'blob', response.data);
              });
          });
      });
    },

    handleDownload(file, index) {
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(file.blob, this.fileName(file, index + 1));
      } else if (window.cordova) {
        window.resolveLocalFileSystemURL(window.cordova.file.cacheDirectory, (dir) => {
          dir.getFile(this.fileName(file, index + 1), { create: true, exclusive: false }, (fileEntry) => {
            fileEntry.createWriter((writer) => {
              writer.onwrite = (tempFile) => {
                window.resolveLocalFileSystemURL(tempFile.target.localURL, (entry) => {
                  cordova.plugins.fileOpener2.open(
                    entry.toURL(),
                    file.mime_type,
                  );
                });
              };
              writer.write(file.blob);
            });
          });
        });
      }
    },

    fileName(file, number) {
      return `File ${number}.${file.file_type}`;
    },
  },
};
</script>

<style>
  .download-link {
    margin-right: 10px;
  }
  .button_colour {
    background-color: v-bind(color)
  }
</style>

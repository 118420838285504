import { mapGetters, mapMutations } from 'vuex';
import router from '@/router';

export default {
  data: () => {
    return {
      formModel: {},
    };
  },

  computed: {
    ...mapGetters(['buildSchema']),

    formOptions() {
      return Object.assign({ context: this }, this.$store.state.formOptions);
    },
  },

  methods: {
    ...mapMutations(['setSubmittingForm']),

    addForm(data, form) {
      const prefix = this.admin === false ? '' : 'admin/';
      const parentId = this.formParent ? this.$store.getters[`${prefix}active${this.formParent}`].id : 0;
      this.$store.dispatch(`${prefix}add${this.formName}`, { data, parentId, form }).then((response) => {
        this.setSubmittingForm(false);
        const redirection = { name: this.formIndex, params: {} };
        if (this.formIndexParamKey) {
          redirection.params[this.formIndexParamKey] = this.getFormIndexParamValue(response);

          if (redirection.params[this.formIndexParamKey] === null) {
            return router.push({ name: this.altFormIndex });
          }
        }
        return router.push(redirection);
      });
    },

    duplicateSchema() {
      this.$store.commit('duplicateSchema', this.schema);
    },

    removeRow(groupIndex) {
      Object.keys(this.formModel).forEach((key) => {
        if (key.startsWith(groupIndex)) {
          this.$delete(this.formModel, key);
        }
      });
      this.$store.commit('removeRow', { schema: this.schema, groupIndex });
    },
  },

  created() {
    if (this.schema && this.schema.groups) {
      this.$store.commit('removeDuplicateSchemas', this.schema);
    }
  },
};

<template>
  <div class="wrapper select-wrapper select-wrapper--pointer" :class="{ 'flatpickr-input on-mobile': picker.isMobile }">
    <input
      class="form-control"
      type="text"
      v-model="value"
      :disabled="disabled"
      :maxlength="schema.max"
      :placeholder="schema.placeholder"
      :readonly="schema.readonly">
  </div>
</template>

<script>
import { abstractField } from 'vue-form-generator';
import Flatpickr from 'flatpickr';
import fecha from 'fecha';

// require('flatpickr/dist/themes/airbnb.css');

export default {
  mixins: [abstractField],

  data: () => ({
    picker: {},
    apiFormat: 'YYYY-MM-DDTHH:mm:ss',
  }),

  methods: {
    getDateFormat() {
      if (this.schema.flatpickrOptions && this.schema.flatpickrOptions.noCalendar) {
        return 'HH:mm';
      }
      if (this.schema.flatpickrOptions && this.schema.flatpickrOptions.enableTime) {
        return 'YYYY-MM-DD HH:mm';
      }
      return 'YYYY-MM-DD';
    },

    formatValueToField(value) {
      if (value) {
        const date = fecha.parse(value, this.apiFormat);
        return fecha.format(date, this.getDateFormat());
      }
      return value;
    },

    formatValueToModel(value) {
      const date = fecha.parse(value, this.getDateFormat());
      return fecha.format(date, this.apiFormat);
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.picker = new Flatpickr(this.$el, {
        onOpen: (selectedDates, dateStr, instance) => {
          if (this.value) {
            instance.setDate(this.value);
          }
        },
        onChange: (selectedDates, dateStr) => {
          this.value = dateStr;
        },
        ...this.schema.flatpickrOptions,
      });
    });
  },

  beforeDestroy() {
    this.picker.destroy();
  },
};
</script>

<template>
  <div class="admin">
    <div class="admin__title">
      <div class="admin__company">
        <h2 class="no-margin" v-if="activeCompany.id">
          <router-link :to="{ name: 'admin-show-company', params: { companyId: activeCompany.id } }">
            {{ activeCompany.name }}
          </router-link>
        </h2>
      </div>
      <div class="admin__breadcrumbs">
        <div class="admin__breadcrumbs" v-for="(breadcrumb, index) in breadcrumbs" :key="breadcrumb.title">
          <div class="note" v-if="index !== 0">></div>
          <div class="note">
            <router-link :to="breadcrumb.route">
              {{ breadcrumb.title }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import fetchResources from '@/mixins/fetch-resources';

export default {
  name: 'admin-header',

  mixins: [fetchResources],

  data() {
    return {
      resources: [
        { name: 'Company', dependant: 'Workflow', dependantKey: 'company_id', setActive: true },
      ],
    };
  },

  computed: {
    ...mapGetters('admin', [
      'activeCompany',
      'activeWorkflow',
      'activeScreen',
    ]),

    breadcrumbs() {
      const breadcrumbRoutes = [];
      if (this.$route.path.includes('workflow')) {
        if (this.activeCompany.id) {
          breadcrumbRoutes.push({
            route: { name: 'admin-workflows', params: { companyId: this.activeCompany.id } },
            title: 'Workflows',
          });
        }
        if (this.activeWorkflow.id) {
          breadcrumbRoutes.push({
            route: { name: 'admin-screens', params: { globalWorkflowId: this.activeWorkflow.id } },
            title: this.activeWorkflow.title,
          });
        }
        if (this.activeScreen.id) {
          breadcrumbRoutes.push({
            route: { name: 'admin-fields', params: { screenId: this.activeScreen.id } },
            title: this.activeScreen.title,
          });
        }
      }
      return breadcrumbRoutes;
    },
  },
};
</script>

<template>
  <div>
    <h1>Search Companies</h1>

    <form @submit.prevent="search" class="flex">
      <input type="text" class="company-search-input" v-model="term">
      <button type="submit">Search</button>
    </form>

    <div v-if="searchPerformed">
      <div class="companies-found" v-if="orderedCompanies.length > 0">Found {{ companyCount }}</div>

      <div class="items" v-if="orderedCompanies.length > 0">
        <router-link
          :to="{ name: 'lawyer-company', params: { companyId: company.id } }"
          v-for="company in orderedCompanies"
          :key="company.id">
            <div class="item panel">{{ company.name }}</div>
        </router-link>
      </div>
      <div class="spacing-small" v-else-if="searchPerformed"><em>No companies found</em></div>
    </div>
  </div>
</template>

<script>
import pluralize from 'pluralize';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'lawyer-companies',

  data() {
    return {
      term: '',
      searchPerformed: false,
    };
  },

  computed: {
    ...mapGetters('lawyer', [
      'orderedCompanies',
    ]),

    companyCount() {
      return `${this.orderedCompanies.length} ${pluralize('company', this.orderedCompanies.length)}`;
    },
  },

  watch: {
    term(value) {
      if (value === '') this.searchPerformed = false;
    },
  },

  methods: {
    ...mapActions('lawyer', [
      'searchCompanies',
    ]),

    search() {
      if (this.term) {
        this.searchCompanies(this.term).then(() => this.searchPerformed = true);
      }
    },
  },
};
</script>

<template>
  <dropzone
    :key="schema.model"
    :id="schema.model"
    :ref="schema.model"
    :options="dropzoneOptions"
    v-on:vdropzone-success="setValue"
    v-on:vdropzone-error="handleError">
  </dropzone>
</template>

<script>
import { mapState } from 'vuex';
import { abstractField } from 'vue-form-generator';
import Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
  mixins: [abstractField],

  components: { Dropzone },

  computed: {
    ...mapState({
      sessionId: state => state.user.sessionId,
    }),

    dropzoneOptions() {
      return {
        url: `${process.env.API_HOST}/uploads`,
        thumbnailWidth: 140,
        thumbnailHeight: 140,
        maxFilesize: 100,
        acceptedFiles: this.schema.acceptedFileTypes,
        addRemoveLinks: true,
        headers: { Authorization: this.sessionId },
        error(file, response) {
          this.defaultOptions.error(file, response.errors[0]);
        },
      };
    },
  },

  methods: {
    setValue(file, response) {
      if (!Array.isArray(this.value)) {
        this.value = [];
      }
      this.value.push(response.upload.id);
      this.$parent.clearValidationErrors();
    },

    handleError(file) {
      if (file.xhr) {
        const response = JSON.parse(file.xhr.response);
        this.$store.dispatch('handleError', {
          response,
        });
      }
    },
  },
};
</script>

/* eslint-disable no-shadow */

import Vue from 'vue';
import fecha from 'fecha';

import Common from '@/common';

const state = {
  incidents: [],
  incidentProgress: [],
  screens: [],
  notes: [],
  activeIncident: 0,
  activeScreen: 0,
  currentScreen: 0,
  showClosedIncidents: false,
  checkRiddorSchema: {
    basicInfo: {
      includeAll: true,
      fields: [
        {
          type: 'customCheckbox',
          styleClasses: 'form__field form__field--reverse form__field--full form__field--reverse',
          label: 'Have there been any serious injuries* in the incident?',
          model: 'serious_accident',
        },
        {
          type: 'customCheckbox',
          styleClasses: 'form__field form__field--reverse form__field--full form__field--reverse',
          label: 'Have there been any cases of industrial disease in the incident?',
          model: 'industrial_disease',
        },
        {
          type: 'customCheckbox',
          styleClasses: 'form__field form__field--reverse form__field--full form__field--reverse',
          label: 'Have there been any dangerous occurences* in the incident?',
          model: 'dangerous_occurence',
        },
        {
          type: 'text',
          styleClasses: 'form__field form__field--full',
          modal: 'reportable-conditions',
          get: () => '* <a href="">Examples can be found here</a>',
        },
      ],
    },
    all: {
      fields: [
        {
          model: '',
          type: 'button',
          styleClasses: 'form__field form__field--no-spacing',
          buttonText: 'Continue',
          onlyOn: 'new',
          buttonAction: (context) => {
            context.continueIncident();
          },
        },
      ],
    },
  },
};

const getters = {
  activeIncident: (state) => state.incidents.find((incident) => incident.id === state.activeIncident) || {},
  activeScreen: (state) => state.screens.find((screen) => screen.id === state.activeScreen) || {},
  activeFields: (state, getters) => getters.activeScreen.fields,
  sortedIncidents: (state, getters) => {
    return state.incidents
      .concat()
      .sort((a, b) => {
        return (
          getters.parsedDateTime(b.completed_at || b.updated_at) -
          getters.parsedDateTime(a.completed_at || a.updated_at)
        );
      })
      .filter((incident) => state.showClosedIncidents || !incident.closed);
  },
  incidentsFromPastMonths: (state, getters, rootState) => (months) =>
    state.incidents.filter((incident) => {
      if (!incident.complete) return false;
      const incidentDate = fecha.parse(incident.completed_at, rootState.apiDateFormat);
      const startDate = new Date();
      startDate.setMonth(startDate.getMonth() - (months - 1));
      startDate.setDate('1');
      return incidentDate > startDate;
    }),
  lastScreen: (state) => (state.screens.length > 0 ? state.screens[state.screens.length - 1] : {}),
  onLastScreen: (state, getters) => getters.lastScreen === getters.activeScreen,
  parsedDateTime: (state, getters, rootState) => (datetime) => {
    return fecha.parse(datetime.replace(/\s[^\s]*$/, ' +0000'), rootState.apiDateFormatWithTZ);
  },
  dateTime: (state, getters) => (datetime) =>
    fecha.format(getters.parsedDateTime(datetime), 'dddd MMMM Do, YYYY HH:mm'),
  formatValue: () => (value, fieldType) => {
    if (fieldType === 'boolean') {
      return value ? 'Yes' : 'No';
    }
    if (['date', 'time', 'datetime'].indexOf(fieldType) > -1) {
      return fecha.format(Date.parse(value), 'dddd MMMM Do, YYYY HH:mm');
    }
    return value;
  },
  incidentIndexById: (state) => (incidentId) => {
    const incidentIndex = state.incidents.findIndex((incident) => incident.id === incidentId);
    return incidentIndex >= 0 ? incidentIndex : 0;
  },
};

const mutations = {
  setActiveIncident(state, incident) {
    state.activeIncident = incident;
  },

  setIncidents(state, incidents) {
    state.incidents = incidents;
  },

  setIncident(state, incident) {
    const existingIncidentId = state.incidents.findIndex((existingIncident) => existingIncident.id === incident.id);
    if (existingIncidentId >= 0) {
      Vue.set(state.incidents, existingIncidentId, incident);
    } else {
      state.incidents.push(incident);
    }
  },

  removeIncidents(state, workflow) {
    state.incidents = state.incidents.filter((incident) => incident.workflow_id !== workflow);
  },

  storeIncidentProgress(state, { screen, data }) {
    const existingScreen = state.incidentProgress.findIndex((incidentScreen) => incidentScreen.id === screen);
    const screenData = {
      id: screen,
      data,
    };

    if (existingScreen >= 0) {
      Vue.set(state.incidentProgress, existingScreen, screenData);
    } else {
      state.incidentProgress.push(screenData);
    }
  },

  unsetIncidentProgress(state) {
    state.incidentProgress = [];
  },

  setScreens(state, screens) {
    const processedScreens = screens.map((screen) => {
      screen.multiple_count = 1;
      return screen;
    });
    state.screens = processedScreens;
  },

  setActiveScreen(state, screen) {
    state.activeScreen = screen;
  },

  setCurrentScreen(state, screen) {
    state.currentScreen = screen;
  },

  addFieldsToScreen(state, { screen, fields }) {
    Vue.set(screen, 'fields', fields);
  },

  copyScreen(state, screen) {
    screen.multiple_count += 1;
  },

  removeScreen(state, screen) {
    if (screen.multiple_count > 1) {
      screen.multiple_count -= 1;
    }
  },

  closeIncident(state, incidentId) {
    state.incidents[incidentId].closed = true;
  },

  deleteIncident(state, incidentId) {
    Vue.delete(state.incidents, incidentId);
  },

  setIncidentNotes(state, { notes }) {
    state.notes = notes;
  },

  addIncidentNote(state, note) {
    state.notes.push(note);
  },

  setShowClosedIncidents(state, status) {
    state.showClosedIncidents = status;
  },
};

const parseModel = (model) => {
  // Return either an array of model objects or just a single model object
  // depending on whether the screen has multiple copies
  let group = [];
  Object.keys(model).map((field) => {
    const index = parseInt(field.charAt(0));
    if (!group[index]) group[index] = {};
    return (group[index][field.replace(/^\d-/, '')] = model[field]);
  });
  group = group.filter((item) => item !== (undefined || null || ''));
  return group.length > 1 ? group : group[0];
};

const actions = {
  fetchIncidents({ commit }, { dependsOn, withData }) {
    const options = { params: { workflow_id: dependsOn } };
    if (withData) options.params.with_data = true;
    return Vue.axios
      .get('incidents', options)
      .then((response) => {
        commit('removeIncidents', dependsOn);
        commit('setIncidents', response.data.incident);
      })
      .catch((error) => Common.handleError(error));
  },

  fetchIncident({ commit }, incident) {
    return Vue.axios
      .get(`incidents/${incident}`)
      .then((response) => {
        commit('setIncident', response.data.incident);
        commit('setCurrentScreen', response.data.incident.next_screen);
      })
      .catch((error) => Common.handleError(error));
  },

  fetchScreens({ commit }, { dependsOn }) {
    return Vue.axios
      .get(`workflows/${dependsOn}/screens`)
      .then((response) => {
        commit('setScreens', response.data.screen);
      })
      .catch((error) => Common.handleError(error));
  },

  fetchIncidentNotes({ commit }, { dependsOn }) {
    return Vue.axios
      .get(`incidents/${dependsOn}/notes`)
      .then((response) => {
        commit('setIncidentNotes', { notes: response.data.note });
      })
      .catch((error) => Common.handleError(error));
  },

  createIncident({ commit, dispatch }, workflow) {
    commit('unsetIncidentProgress');
    return new Promise((resolve) => {
      Vue.axios
        .post('incidents', { workflow_id: workflow })
        .then((response) => {
          const incident = response.data.incident;
          dispatch('renderScreen', {
            workflow,
            screen: incident.next_screen,
          }).then(() => resolve(incident));
        })
        .catch((error) => Common.handleError(error));
    });
  },

  renderScreen({ commit, getters }, { workflow, screen }) {
    return new Promise((resolve) => {
      Vue.axios
        .get(`workflows/${workflow}/screens/${screen}/render`)
        .then((response) => {
          commit('setActiveScreen', screen);
          commit('setCurrentScreen', getters.activeIncident.next_screen || screen);
          commit('addFieldsToScreen', {
            screen: getters.activeScreen,
            fields: response.data.screen.fields,
          });
          resolve();
        })
        .catch((error) => Common.handleError(error));
    });
  },

  saveScreen({ commit, dispatch }, { incident, screen, model, form }) {
    return Vue.axios
      .put(`incidents/${incident}`, {
        screen_id: screen,
        data: parseModel(model),
      })
      .then((response) => {
        if (response.data.triggers) {
          dispatch('displayTriggerModal', response.data.triggers, {
            root: true,
          });
        }
        commit('setIncident', response.data.incident);
        if (response.data.incident.complete) {
          dispatch('setMessage', { text: 'Report submitted successfully' });
        } else if (response.data.incident.next_screen) {
          dispatch('renderScreen', {
            workflow: response.data.incident.workflow_id,
            screen: response.data.incident.next_screen,
          });
        }
        return response;
      })
      .catch((error) => Common.handleError(error, form));
  },

  copyScreen({ commit, dispatch }, screen) {
    if (screen.multiple_limit && screen.multiple_count >= screen.multiple_limit) {
      dispatch('setMessage', {
        text: `Cannot create more than ${screen.multiple_limit} copies`,
        messageType: 'warn',
      });
    } else if (screen.allow_multiple) {
      commit('copyScreen', screen);
    } else {
      dispatch('setMessage', {
        text: 'This screen does not allow multiple copies',
        messageType: 'error',
      });
    }
  },

  closeIncident({ commit, getters, dispatch }, incidentId) {
    return Vue.axios
      .post(`incidents/${incidentId}/close`)
      .then(() => {
        commit('closeIncident', getters.incidentIndexById(incidentId));
        dispatch('setMessage', { text: 'Successfully closed incident' }, { root: true });
      })
      .catch((error) => Common.handleError(error));
  },

  deleteIncident({ commit, getters, dispatch }, incidentId) {
    commit('deleteIncident', getters.incidentIndexById(incidentId));
    return Vue.axios
      .delete(`incidents/${incidentId}`)
      .then(() => dispatch('setMessage', { text: 'Successfully deleted incident' }, { root: true }))
      .catch((error) => Common.handleError(error));
  },

  createIncidentNote({ commit, dispatch }, { incident, note, uploadId = null, location = null }) {
    return Vue.axios
      .post(`incidents/${incident}/notes`, {
        note,
        upload_id: uploadId,
        location,
      })
      .then((response) => {
        commit('addIncidentNote', response.data.note);
        dispatch('setMessage', { text: 'Successfully saved note' }, { root: true });
      })
      .catch((error) => Common.handleError(error));
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

import Vue from 'vue';
import Router from 'vue-router';
import Store from '@/store/index';
import Common from '@/common';
import App from '@/components/App';
import Auth from '@/components/Auth';
import NotFound from '@/components/NotFound';
import WorkflowIndex from '@/components/workflows/Index';
import WorkflowShow from '@/components/workflows/Show';
import IncidentIndex from '@/components/incidents/Index';
import IncidentShow from '@/components/incidents/Show';
import IncidentNew from '@/components/incidents/New';
import IncidentReports from '@/components/incidents/Reports';
import Admin from '@/components/Admin';
import AdminDashbard from '@/components/admin/Dashboard';
import AdminHeader from '@/components/admin/Header';
import AdminCompanies from '@/components/admin/companies/Index';
import AdminShowCompany from '@/components/admin/companies/Show';
import AdminEditCompany from '@/components/admin/companies/Edit';
import AdminEditCompanyWorkflow from '@/components/admin/companies/workflows/Edit';
import AdminWorkflows from '@/components/admin/workflows/Index';
import AdminNewWorkflow from '@/components/admin/workflows/New';
import AdminNewGlobalWorkflow from '@/components/admin/workflows/global/New';
import AdminEditWorkflow from '@/components/admin/workflows/Edit';
import AdminPreviewWorkflow from '@/components/admin/workflows/Preview';
import AdminCloneWorkflow from '@/components/admin/workflows/Clone';
import AdminScreens from '@/components/admin/screens/Index';
import AdminNewScreen from '@/components/admin/screens/New';
import AdminShowScreen from '@/components/admin/screens/Show';
import AdminEditScreen from '@/components/admin/screens/Edit';
import AdminFields from '@/components/admin/fields/Index';
import AdminNewField from '@/components/admin/fields/New';
import AdminEditField from '@/components/admin/fields/Edit';
import Lawyer from '@/components/Lawyer';
import LawyerDashboard from '@/components/lawyer/Dashboard';
import LawyerCompany from '@/components/lawyer/companies/Show';
import LawyerIncident from '@/components/lawyer/incidents/Show';

Vue.use(Router);

const router = new Router({
  mode: window.cordova ? 'hash' : 'history',
  routes: [
    {
      path: '/',
      component: App,
      children: [
        {
          path: '',
          name: 'index',
          redirect: { name: 'workflows' },
        },
        {
          path: 'workflows',
          name: 'workflows',
          component: WorkflowIndex,
        },
        {
          path: 'workflows/:workflowId',
          component: WorkflowShow,
          children: [
            {
              path: '',
              name: 'incidents',
              component: IncidentIndex,
            },
            {
              path: 'incidents/reports',
              name: 'incident-reports',
              component: IncidentReports,
            },
            {
              path: 'incidents/new',
              name: 'new-incident',
              component: IncidentNew,
              beforeEnter: (to, from, next) => {
                if (!from.name) next();
                Common.confirmDialog({
                  modal: Vue.prototype.$modal,
                  title: 'Are you sure you want to start a new report?',
                  text: "Existing reports can be resumed on the 'View Incidents' page.",
                  okHandler: () => {
                    next();
                  },
                  cancelHandler: () => {
                    next(false);
                  },
                });
              },
            },
            {
              path: 'incidents/new/:incidentId/screen/:screenId',
              name: 'resume-incident',
              component: IncidentNew,
              props: true,
            },
            {
              path: 'incidents/:incidentId',
              name: 'incident',
              component: IncidentShow,
            },
          ],
        },
        {
          path: '/login',
          name: 'login',
          beforeEnter(to, from, next) {
            Vue.axios.get('auth').then((response) => {
              let authRedirectUrl = response.data;
              if (process.env.REDIRECT_URL_UID) {
                authRedirectUrl += `&redirect_url_uid=${process.env.REDIRECT_URL_UID}`;
              }

              if (window.cordova) {
                document.addEventListener('deviceready', () => {
                  // eslint-disable-next-line max-len
                  const browserOAuth = window.cordova.InAppBrowser.open(
                    authRedirectUrl,
                    '_blank',
                    'location=no,clearsessioncache=yes,clearcache=yes',
                  );
                  const redirectToLogin = () => next({ name: 'login' });
                  browserOAuth.addEventListener('exit', redirectToLogin);
                  browserOAuth.addEventListener('loadstart', (event) => {
                    const url = new URL(event.url);
                    if (url.pathname === '/auth') {
                      browserOAuth.removeEventListener('exit', redirectToLogin);
                      browserOAuth.close();
                      const authToken = url.searchParams.get('auth_token');
                      next({ name: 'auth', query: { auth_token: authToken } });
                    }
                  });
                });
              } else {
                window.location = authRedirectUrl;
              }
            });
          },
        },
        {
          path: '/auth',
          name: 'auth',
          component: Auth,
          props: (route) => ({ authToken: route.query.auth_token }),
        },
        {
          path: '/lawyer',
          component: Lawyer,
          children: [
            {
              path: '',
              name: 'lawyer-dashboard',
              component: LawyerDashboard,
            },
            {
              path: 'company/:companyId',
              name: 'lawyer-company',
              component: LawyerCompany,
            },
            {
              path: 'workflows/:workflowId/incidents/:incidentId',
              name: 'lawyer-incident',
              component: LawyerIncident,
            },
          ],
        },
        {
          path: '/admin',
          component: Admin,
          children: [
            {
              path: '',
              name: 'admin-dashboard',
              component: AdminDashbard,
            },
            {
              path: 'workflows/new',
              name: 'admin-new-global-workflow',
              component: AdminNewGlobalWorkflow,
            },
            {
              path: 'workflows/clone',
              name: 'admin-clone-workflow',
              component: AdminCloneWorkflow,
            },
            {
              path: 'workflows/:globalWorkflowId',
              component: AdminHeader,
              children: [
                {
                  path: '',
                  name: 'admin-screens',
                  component: AdminScreens,
                },
                {
                  path: 'preview',
                  name: 'admin-preview-workflow',
                  component: AdminPreviewWorkflow,
                },
                {
                  path: 'edit',
                  name: 'admin-edit-workflow',
                  component: AdminEditWorkflow,
                },
                {
                  path: 'screens/new',
                  name: 'admin-new-screen',
                  component: AdminNewScreen,
                },
                {
                  path: 'screens/:screenId',
                  component: AdminShowScreen,
                  children: [
                    {
                      path: '',
                      name: 'admin-fields',
                      component: AdminFields,
                    },
                    {
                      path: 'fields/new',
                      name: 'admin-new-field',
                      component: AdminNewField,
                    },
                    {
                      path: 'fields/:fieldId/edit',
                      name: 'admin-edit-field',
                      component: AdminEditField,
                    },
                  ],
                },
                {
                  path: 'screens/:screenId/edit',
                  name: 'admin-edit-screen',
                  component: AdminEditScreen,
                },
              ],
            },
            {
              path: 'companies',
              name: 'admin-companies',
              component: AdminCompanies,
            },
            {
              path: 'companies/:companyId',
              component: AdminHeader,
              children: [
                {
                  path: '',
                  name: 'admin-show-company',
                  component: AdminShowCompany,
                },
                {
                  path: 'workflows',
                  name: 'admin-workflows',
                  component: AdminWorkflows,
                },
                {
                  path: 'workflows/new',
                  name: 'admin-new-workflow',
                  component: AdminNewWorkflow,
                  meta: {
                    inWorkflows: true,
                  },
                },
                {
                  path: 'workflows/clone',
                  name: 'admin-clone-company-workflow',
                  component: AdminCloneWorkflow,
                  meta: {
                    inWorkflows: true,
                  },
                },
                {
                  path: 'workflows/:workflowId/edit',
                  name: 'admin-edit-company-workflow',
                  component: AdminEditCompanyWorkflow,
                },
              ],
            },
            {
              path: 'companies/:companyId/edit',
              name: 'admin-edit-company',
              component: AdminEditCompany,
            },
          ],
        },
        {
          path: '*',
          component: NotFound,
        },
      ],
    },
  ],
});

router.beforeResolve((to, from, next) => {
  if (!Store.state.alreadyScrolled) {
    window.scrollTo(0, 0);
  } else {
    Store.commit('setAlreadyScrolled', false);
  }

  if (!Store.state.user.currentUser.id && !['login', 'auth'].includes(to.name)) {
    const sessionId = localStorage.getItem('sessionId');
    if (sessionId) {
      Store.dispatch('loginUser', { sessionId });
    } else {
      return next({ name: 'login' });
    }
  }

  const resources = [
    {
      key: 'companyId',
      name: 'Company',
      onlyFor: ['admin'],
      single: true,
      keepActive: true,
    },
    { key: 'userId', name: 'User', onlyFor: ['standard'], fetch: false },
    {
      key: 'workflowId',
      name: 'Workflow',
      dependsOn: 'companyId',
      onlyFor: ['standard', 'admin'],
    },
    {
      key: 'globalWorkflowId',
      name: 'Workflow',
      onlyFor: ['admin'],
      single: true,
      keepActive: true,
    },
    {
      key: 'incidentId',
      name: 'Incident',
      dependsOn: 'workflowId',
      onlyFor: ['standard'],
    },
    {
      key: 'incidentNoteId',
      name: 'IncidentNote',
      dependsOn: 'incidentId',
      onlyFor: ['standard'],
      noActive: true,
    },
    {
      key: 'screenId',
      name: 'Screen',
      dependsOn: 'workflowId',
      onlyFor: ['standard'],
    },
    {
      key: 'screenId',
      name: 'Screen',
      dependsOn: 'globalWorkflowId',
      onlyFor: ['admin'],
    },
    {
      key: 'fieldId',
      name: 'Field',
      dependsOn: 'screenId',
      onlyFor: ['admin'],
    },
    {
      key: 'fieldOptionId',
      name: 'FieldOption',
      dependsOn: 'fieldId',
      onlyFor: ['admin'],
      noActive: true,
    },
    {
      key: 'screenConditionId',
      name: 'ScreenCondition',
      dependsOn: 'screenId',
      onlyFor: ['admin'],
      noActive: true,
    },
    {
      key: 'triggerId',
      name: 'Trigger',
      dependsOn: 'globalWorkflowId',
      onlyFor: ['admin'],
      noActive: true,
    },
    {
      key: 'allFieldId',
      name: 'AllField',
      dependsOn: 'globalWorkflowId',
      onlyFor: ['admin'],
      noActive: true,
    },
    {
      key: 'companyId',
      name: 'Company',
      onlyFor: ['lawyer'],
      single: true,
      noActive: true,
    },
    {
      key: 'workflowId',
      name: 'Workflow',
      onlyFor: ['lawyer'],
      single: true,
      noActive: true,
    },
    {
      key: 'incidentId',
      name: 'Incident',
      dependsOn: 'companyId',
      onlyFor: ['lawyer'],
      single: true,
      noActive: true,
    },
    {
      key: 'noteId',
      name: 'Note',
      dependsOn: 'incidentId',
      onlyFor: ['lawyer'],
      noActive: true,
    },
  ];

  let areaIn = 'standard';
  if (to.name) {
    Store.commit('setNotFoundError', false);
    Store.state.siteAreas.forEach((area) => (areaIn = to.name.startsWith(area) ? area : areaIn));
  }

  const areaResources = resources.filter((resource) => {
    return !resource.onlyFor || resource.onlyFor.includes(areaIn);
  });

  areaResources.forEach((resource) => {
    Store.dispatch('loadResources', {
      to,
      resource,
      resources: areaResources,
      areaIn,
    });
  });

  return next();
});

export default router;

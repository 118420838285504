<template>
  <div class="admin-fields">
    <vue-form-generator :schema="formSchema" :model="formModel" :options="{ validateAfterChanged: true }"/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import formBuilder from '@/mixins/form-builder';

export default {
  name: 'admin-show-screen',

  mixins: [formBuilder],

  data: () => {
    return {
      formPreview: true,
    };
  },

  computed: {
    ...mapState('admin', {
      fields: state => state.field.fields,
      screens: state => state.screen.screens,
    }),

    ...mapGetters('admin', [
      'activeScreen',
      'activeWorkflow',
      'activeFields',
    ]),
  },

  watch: {
    activeScreen: {
      handler(value) {
        if (value.id) {
          this.fetchFields({ dependsOn: value.id });
        }
      },
      immediate: true,
    },

    screens: {
      handler() {
        if (this.screens.length > 0) {
          this.setActiveScreen(this.screens[0].id);
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapMutations('admin', [
      'setActiveScreen',
    ]),

    ...mapActions('admin', [
      'fetchFields',
    ]),
  },
};
</script>

<template>
  <label class="input input--checkbox">
    <input
      :id="getFieldID(schema)"
      type="checkbox"
      class="input__default"
      v-model="value"
      :autocomplete="schema.autocomplete"
      :disabled="disabled"
      :name="schema.inputName">
    <div class="input__styled input__styled--checkbox"></div>
  </label>
</template>

<script>
import { abstractField } from 'vue-form-generator';

export default {
  mixins: [abstractField],

  watch: {
    value() {
      if (this.schema.onChange) this.schema.onChange.call(this, this.value);
    },
  },

  created() {
    if (typeof this.value === 'undefined') {
      if (this.schema.default) {
        this.value = this.schema.default === 'true';
      } else {
        this.value = false;
      }
    }
  },
};
</script>

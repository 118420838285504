import { mapGetters, mapMutations } from 'vuex';
import router from '@/router';
import clone from 'lodash.clone';

export default {
  data: () => {
    return {
      formModel: {},
    };
  },

  computed: {
    ...mapGetters(['buildSchema']),

    formOptions() {
      return Object.assign({ context: this }, this.$store.state.formOptions);
    },

    namespace() {
      return this.admin === false ? '' : 'admin/';
    },

    activeObjectModel() {
      return this.$store.getters[`${this.namespace}active${this.formName}`];
    },
  },

  watch: {
    'activeObjectModel.id': {
      handler() {
        this.defineFormObjects();
      },
      immediate: true,
    },
  },

  methods: {
    ...mapMutations(['setSubmittingForm']),

    editForm(data, form) {
      const parentId = this.formParent ? this.$store.getters[`${this.namespace}active${this.formParent}`].id : 0;
      this.$store.dispatch(`${this.namespace}edit${this.formName}`, { data, parentId, form }).then(() => {
        this.setSubmittingForm(false);
        router.push({ name: this.formIndex });
      });
    },

    defineFormObjects() {
      this.formModel = clone(this.activeObjectModel);
    },
  },
};

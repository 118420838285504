/* eslint-disable no-shadow */

import Vue from 'vue';

import Common from '@/common';

const state = {
  companies: [],
  fetchedAllCompanies: false,
  searchCompanies: [],
  activeCompany: 0,
  onNewCompanyWizard: false,
  companySchema: {
    basicInfo: {
      fields: [
        {
          type: 'customInput',
          inputType: 'color',
          styleClasses: 'form__field',
          label: 'Colour',
          model: 'colour',
          validator: 'string',
          required: true,
        },
        {
          type: 'customSelect',
          styleClasses: 'form__field',
          label: 'Primary User',
          model: 'primary_user_accounts_id',
          validator: 'integer',
          required: true,
          values: [],
        },
        {
          type: 'customCheckbox',
          styleClasses: 'form__field',
          label: 'Published',
          model: 'published',
          onlyOn: 'edit',
        },
        {
          type: 'customCheckbox',
          styleClasses: 'form__field',
          label: 'Test Company',
          model: 'test_company',
        },
        {
          type: 'customInput',
          inputType: 'text',
          styleClasses: 'form__field form__field--last',
          label: 'Notification Emails',
          hint: 'Comma seperated list of email addresses other than the primary user to send notifications to',
          model: 'notification_emails',
          validator: 'string',
        },
        {
          model: '',
          type: 'customSubmit',
          styleClasses: 'form__field',
          buttonText: 'Add Company',
          onlyOn: 'new',
          onSubmit: (model, schema, context, form) => {
            context.addForm(model, form);
          },
          validateBeforeSubmit: true,
        },
        {
          model: '',
          type: 'customSubmit',
          styleClasses: 'form__field',
          buttonText: 'Edit Company',
          onlyOn: 'edit',
          onSubmit: (model, schema, context, form) => {
            context.editForm(model, form);
          },
          validateBeforeSubmit: true,
        },
      ],
    },
  },
};

const getters = {
  orderedCompanies: (state) => state.companies.concat().sort((a, b) => a.accounts_id - b.accounts_id),
  orderedSearchCompanies: (state) => state.searchCompanies.concat().sort((a, b) => a.name.localeCompare(b.name)),
  companyCount: (state) => state.companies.length,
  activeCompany: (state) => state.companies.find((company) => company.id === state.activeCompany) || {},
  activeCompanyId: (state) => {
    const companyId = state.companies.findIndex((company) => company.id === state.activeCompany);
    return companyId >= 0 ? companyId : 0;
  },
  primaryUserSchema: (state) => {
    return state.companySchema.basicInfo.fields.find((field) => field.model === 'primary_user_accounts_id');
  },
};

const mutations = {
  setCompanies(state, companies) {
    state.companies = companies;
  },

  setSearchCompanies(state, companies) {
    state.searchCompanies = companies;
  },

  setActiveCompany(state, company) {
    state.activeCompany = company;
  },

  addCompany(state, company) {
    state.companies.push(company);
  },

  editCompany(state, { companyId, editedCompany }) {
    Vue.set(state.companies, companyId, editedCompany);
  },

  addToSuggestions(state, { companies, options }) {
    if (companies.length > 0) {
      options.splice(0, options.length, ...companies);
    }
  },

  // Pretty sure this can be removed
  populatePrimaryUsers(state, { schema, users }) {
    schema.values = users;
  },

  setOnNewCompanyWizard(state, status) {
    state.onNewCompanyWizard = status;
  },
};

const actions = {
  fetchCompanies({ rootState, commit }) {
    if (rootState.admin.company.fetchedAllCompanies) return false;
    return Vue.axios
      .get('admin/companies')
      .then((response) => {
        commit('setCompanies', response.data.company);
        commit('fetchedAllCompanies', true);
      })
      .catch((error) => Common.handleError(error));
  },

  fetchCompany({ rootState, commit }, companyId) {
    if (rootState.admin.company.fetchedAllCompanies) return false;
    return Vue.axios
      .get(`admin/companies/${companyId}`)
      .then((response) => {
        commit('addCompany', response.data.company);
      })
      .catch((error) => Common.handleError(error));
  },

  searchCompanies({ commit }, term) {
    return Vue.axios
      .post('search', { term, types: ['company'] })
      .then((response) => {
        commit(
          'setSearchCompanies',
          response.data.rows.map((row) => row.source),
        );
      })
      .catch((error) => Common.handleError(error));
  },

  addCompany({ commit, dispatch }, { data, form }) {
    return new Promise((resolve) => {
      return Vue.axios
        .post('admin/companies', data)
        .then((response) => {
          commit('setOnNewCompanyWizard', true);
          commit('addCompany', response.data.company);
          dispatch('setMessage', { text: 'Successfully created company' }, { root: true }).then(() =>
            resolve(response),
          );
        })
        .catch((error) => Common.handleError(error, form));
    });
  },

  editCompany({ getters, commit, dispatch }, { data, form }) {
    return Vue.axios
      .put(`admin/companies/${data.id}`, data)
      .then((response) => {
        commit('editCompany', {
          companyId: getters.activeCompanyId,
          editedCompany: response.data.company,
        });
        dispatch('setMessage', { text: `Successfully edited ${data.name}` }, { root: true });
      })
      .catch((error) => Common.handleError(error, form));
  },

  findCompany({ commit }, { options, searchQuery }) {
    if (searchQuery) {
      return Vue.axios
        .get(`admin/company-search?q=${encodeURIComponent(searchQuery)}`)
        .then((response) => {
          commit('addToSuggestions', {
            companies: response.data.company,
            options,
          });
        })
        .catch();
    }
    return false;
  },
  // Pretty certain this can be removed
  populatePrimaryUsers({ getters, commit }, { company }) {
    return Vue.axios
      .get(`admin/companies/${company}/account-users`)
      .then((response) => {
        const users = response.data.users
          .map((companyUser) => {
            return {
              id: companyUser.user_id,
              name: `${companyUser.user.first_name} ${companyUser.user.last_name}`,
            };
          })
          .sort((a, b) => a.name.localeCompare(b.name));
        commit('populatePrimaryUsers', {
          schema: getters.primaryUserSchema,
          users,
        });
      })
      .catch();
  },
  //
};
export default {
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div>
    <h1 id="edit-title">Edit {{ activeField.title }}</h1>
    <tabs :options="{ useUrlFragment: false }">
      <tab name="Basic Info">
        <vue-form-generator
          :schema="buildSchema('field', 'fieldSchema', 'basicInfo', 'edit')"
          :model="formModel"
          :options="formOptions" />
      </tab>
      <tab name="Validation">
        <vue-form-generator
          :schema="buildSchema('field', 'fieldSchema', 'validation', 'edit')"
          :model="formModel"
          :options="formOptions" />
      </tab>
      <tab name="Field Options" v-if="fieldHasOptions">
        <vue-form-generator
          :schema="buildSchema('field', 'fieldSchema', 'fieldOptions', 'edit')"
          :model="objects[activeObject]"
          :options="formOptions" />

        <div class="list-view list-view--inline" v-if="objects.length > 1">
          <div class="list-view__header">
            <div>Text</div>
            <div>Value</div>
            <div class="list-view__small-column">Position</div>
            <div class="list-view__small-column">Actions</div>
          </div>

          <div
            class="list-view__row"
            v-for="(fieldOption, index) in initialObjects"
            :key="fieldOption.id"
            v-if="index > 0">
              <div>{{ fieldOption.text }}</div>
              <div>{{ fieldOption.value }}</div>
              <div class="list-view__small-column">
                <span class="show-for-small">Position:&nbsp;</span>{{ fieldOption.position }}
              </div>
              <div class="list-view__small-column">
                <button
                  class="icon-button"
                  @click="alterPosition(fieldOption.id, fieldOption.position - 1)"
                  :disabled="index === 1">
                    <i class="fa-solid fa-arrow-up" />
                </button>
                <button
                  class="icon-button"
                  @click="alterPosition(fieldOption.id, fieldOption.position + 1)"
                  :disabled="index === objects.length - 1">
                    <i class="fa-solid fa-arrow-down" />
                </button>
                <button class="icon-button" @click="editObject(index)">
                  <i class="fa-solid fa-pen-to-square" />
                </button>
                <button class="icon-button" @click="deleteObject(fieldOption)">
                  <i class="fa-solid fa-trash" />
                </button>
              </div>
          </div>
        </div>
      </tab>
    </tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import editForm from '@/mixins/edit-form';
import inlineEditForm from '@/mixins/inline-edit-form';

export default {
  name: 'admin-edit-field',
  mixins: [editForm, inlineEditForm],

  data: () => {
    return {
      formIndex: 'admin-fields',
      formName: 'Field',
      inlineName: 'FieldOption',
      inlineId: 'options',
    };
  },

  computed: {
    ...mapGetters('admin', [
      'activeField',
    ]),

    fieldHasOptions() {
      return ['dropdown', 'checkbox', 'radio'].includes(this.activeField.savedFieldType);
    },
  },

  watch: {
    activeField: {
      handler() {
        this.defineFormObjects();
        this.setupObjects();
      },
      deep: true,
    },
  },

  methods: {
    alterPosition(fieldOptionId, position) {
      this.$store.dispatch('admin/editFieldOption', { data: { id: fieldOptionId, position }, noCommit: true })
        .then(() => this.$store.dispatch('admin/fetchFieldOptions', { dependsOn: this.activeField.id }));
    },
  },
};
</script>

/* eslint-disable no-shadow */

import Vue from 'vue';

import Common from '@/common';
import Operator from './operator';

const state = {
  triggers: [],
  triggerSchema: {
    basicInfo: {
      fields: [
        {
          type: 'customSelect',
          styleClasses: 'form__field',
          label: 'Field',
          model: 'field_id',
          values: [],
          validator: 'integer',
          visible: (model) => !model.always_run,
          required: true,
        },
        {
          type: 'customSelect',
          styleClasses: 'form__field',
          label: 'Trigger Type',
          model: 'trigger',
          values: [
            { id: 'popup', name: 'Popup' },
            { id: 'email', name: 'Email' },
          ],
          validator: 'string',
          required: true,
        },
        {
          type: 'customSelect',
          styleClasses: 'form__field',
          label: 'Operator',
          model: 'operator',
          values: () => Operator.state.operators,
          validator: 'string',
          visible: (model) => !model.always_run,
          required: true,
        },
        {
          type: 'customInput',
          inputType: 'text',
          styleClasses: 'form__field',
          label: 'Value',
          model: 'value',
          validator: 'string',
          visible: (model) => !model.always_run,
          required: true,
        },
        {
          type: 'customInput',
          inputType: 'text',
          styleClasses: 'form__field',
          label: 'Title',
          model: 'data.title',
          validator: 'string',
          visible: (model) => {
            return model.trigger === 'popup';
          },
        },
        {
          type: 'customInput',
          inputType: 'text',
          styleClasses: 'form__field',
          label: 'To',
          model: 'data.to',
          validator: 'string',
          visible: (model) => {
            return model.trigger === 'email';
          },
          required: true,
        },
        {
          type: 'customInput',
          inputType: 'text',
          styleClasses: 'form__field',
          label: 'Subject',
          model: 'data.subject',
          validator: 'string',
          visible: (model) => {
            return model.trigger === 'email';
          },
          required: true,
        },
        {
          type: 'textArea',
          styleClasses: 'form__field',
          label: 'Message',
          model: 'data.message',
          validator: 'string',
          rows: 4,
          required: true,
        },
        {
          type: 'customCheckbox',
          styleClasses: 'form__field',
          label: 'Always Run?',
          model: 'always_run',
          hint: 'Always run trigger on completion',
        },
        {
          type: 'customCheckbox',
          styleClasses: 'form__field form__field--last',
          label: 'Inverse',
          model: 'inverse',
        },
        {
          model: '',
          type: 'customSubmit',
          styleClasses: 'form__field',
          buttonText: 'Save',
          onSubmit: (model, schema, context, form) => {
            context.saveObject(model, form);
          },
          validateBeforeSubmit: true,
        },
        {
          model: '',
          type: 'customSubmit',
          styleClasses: 'form__field',
          buttonText: 'Cancel',
          noLoadingSpinner: true,
          visible: (model) => {
            return Object.keys(model).length !== 0;
          },
          onSubmit: (model, schema, context) => {
            context.clearForm();
          },
        },
      ],
    },
  },
};

const getters = {
  triggerIndexById: (state) => (triggerId) => {
    const triggerIndex = state.triggers.findIndex((trigger) => trigger.id === triggerId);
    return triggerIndex >= 0 ? triggerIndex : 0;
  },
};

const mutations = {
  addFieldsToTriggerDropdown(state, fields) {
    Vue.set(state.triggerSchema.basicInfo.fields[0], 'values', fields);
  },

  setTriggers(state, triggers) {
    state.triggers = triggers;
  },

  addTrigger(state, trigger) {
    state.triggers.push(trigger);
  },

  editTrigger(state, { triggerId, trigger }) {
    Vue.set(state.triggers, triggerId, trigger);
  },

  deleteTrigger(state, { triggerId }) {
    Vue.delete(state.triggers, triggerId);
  },
};

const actions = {
  fetchTriggers({ commit }, { dependsOn }) {
    return Vue.axios
      .get(`admin/workflows/${dependsOn}/triggers`)
      .then((response) => {
        commit('setTriggers', response.data.trigger);
      })
      .catch();
  },

  addTrigger({ commit, dispatch }, { data, parentId, form }) {
    return Vue.axios
      .post(`admin/workflows/${parentId}/triggers`, data)
      .then((response) => {
        commit('addTrigger', response.data.trigger);
        dispatch('setMessage', { text: 'Successfully created trigger' }, { root: true });
      })
      .catch((error) => Common.handleError(error, form));
  },

  editTrigger({ getters, commit, dispatch }, { data, parentId, form }) {
    return Vue.axios
      .put(`admin/workflows/${parentId}/triggers/${data.id}`, data)
      .then((response) => {
        commit('editTrigger', {
          triggerId: getters.triggerIndexById(data.id),
          trigger: response.data.trigger,
        });
        dispatch(
          'setMessage',
          {
            text: `Successfully edited trigger for ${getters.workflowById(parentId).title}`,
          },
          { root: true },
        );
      })
      .catch((error) => Common.handleError(error, form));
  },

  deleteTrigger({ getters, commit, dispatch }, { parentId, inlineId }) {
    return Vue.axios
      .delete(`admin/workflows/${parentId}/triggers/${inlineId}`)
      .then(() => {
        commit('deleteTrigger', {
          triggerId: getters.triggerIndexById(inlineId),
        });
        dispatch('setMessage', { text: 'Successfully deleted trigger' }, { root: true });
      })
      .catch((error) => Common.handleError(error));
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

/* eslint-disable no-shadow */

import Vue from 'vue';

import Common from '@/common';
import User from '@/user';

const state = {
  activeUser: {},
};

const getters = {
  activeUser: (state) => state.activeUser,
  userFullName: (state) => {
    if (state.activeUser) {
      return User.fullName(state.activeUser.first_name, state.activeUser.last_name);
    }
    return 'Unknown user';
  },
};

const mutations = {
  setActiveUser(state, user) {
    state.activeUser = user;
  },
};

const actions = {
  fetchUser({ commit }, userId) {
    return Vue.axios
      .get(`lawyer/users/${userId}`)
      .then((response) => {
        commit('setActiveUser', response.data.user);
      })
      .catch((error) => Common.handleError(error));
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div>
    <h1>Add Field</h1>
    <tabs>
      <tab name="Basic Info">
        <vue-form-generator
          :schema="buildSchema('field', 'fieldSchema', 'basicInfo', 'new')"
          :model="formModel"
          :options="formOptions" />
      </tab>
      <tab name="Validation">
        <vue-form-generator
          :schema="buildSchema('field', 'fieldSchema', 'validation', 'new')"
          :model="formModel"
          :options="formOptions" />
      </tab>
    </tabs>
  </div>
</template>

<script>
import newForm from '@/mixins/new-form';

export default {
  name: 'admin-new-field',
  mixins: [newForm],

  data() {
    return {
      formName: 'Field',
      formParent: 'Screen',
      formIndex: 'admin-fields',
    };
  },
};
</script>

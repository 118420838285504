<template>
  <incident-single
    :incident="activeIncident"
    :workflow="activeWorkflow"
    :notes="notes"
    :user-name="userFullName">
    <template slot="navigation">
      <themed-button
        :to="{ name: 'lawyer-company', params: { companyId: activeIncident.company_id } }"
        class="page__action page__action--full">
        {{ activeIncident.company_name }}
      </themed-button>
    </template>

    <template slot="message" v-if="activeIncident.primary_user">
      <div class="incident__contact-details">
        <div>
          <h3 class="no-margin">Contact Details</h3>
          <div>
            <i class="fa-solid fa-user" />
            {{ activeIncident.primary_user.details.first_name }} {{ activeIncident.primary_user.details.last_name }}
          </div>
          <div v-if="activeIncident.primary_user.number_to_call">
            <i class="fa-solid fa-phone" />
            {{ activeIncident.primary_user.number_to_call }}
          </div>
          <div v-if="activeIncident.primary_user.details.email">
            <i class="fa-solid fa-envelope" />
            <a :href="`mailto:${activeIncident.primary_user.details.email}`">
              {{activeIncident.primary_user.details.email}}
            </a>
          </div>
        </div>
        <div>
          <button class="button" @click="closeIncident()" :disabled="activeIncident.closed">
            Close Incident
          </button>
        </div>
      </div>
    </template>
  </incident-single>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import IncidentSingle from '@/components/incidents/Incident';
import Common from '@/common';

export default {
  name: 'lawyer-incident',

  components: { IncidentSingle },

  computed: {
    ...mapState('lawyer', {
      notes: state => state.incident.notes,
    }),

    ...mapGetters([
      'workflowType',
    ]),

    ...mapGetters('lawyer', [
      'activeWorkflow',
      'activeIncident',
      'userFullName',
    ]),

    ...mapGetters([
      'dateTime',
    ]),
  },

  watch: {
    activeIncident: {
      handler(val) {
        if (val.user_id) this.fetchUser(val.user_id);
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions('lawyer', [
      'fetchUser',
    ]),

    closeIncident() {
      Common.confirmDialog({
        modal: this.$modal,
        // eslint-disable-next-line max-len
        title: `Are you sure you want to close the incident dated ${this.dateTime(this.activeIncident.completed_at)}?`,
        okHandler: () => {
          this.$store.dispatch('lawyer/closeIncident', this.activeIncident.id);
        },
      });
    },
  },
};
</script>

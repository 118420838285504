<template>
  <div class="container">
    <div class="panel text-center">
      <h1>User Account Error</h1>
      <p v-html="userFetchError"></p>

      <div class="flex flex--horiz-center spacing">
        <themed-button :href="switchCompanyUrl">Switch Company</themed-button>
        <themed-button class="spacing-left" @click.native="$emit('logoutUser')">Logout</themed-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'user-fetch-error',

  props: ['switchCompanyUrl'],

  computed: {
    ...mapState({
      userFetchError: state => state.user.userFetchError,
    }),
  },
};
</script>

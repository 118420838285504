<template>
  <div class="container">
    <div class="panel text-center">
      <h1>API Error</h1>
      <p>
        It looks like there was a problem connecting to our servers.
        Please try again soon.
      </p>
      <button class="secondary-button" @click="window.location.reload()">Reload</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'api-error',
};
</script>

<template>
  <div class="list-view">
    <div class="list-view__header">
      <div>Title</div>
      <div>Colour</div>
      <div>Workflow Type</div>
      <div class="list-view__small-column">Published</div>
      <div class="list-view__small-column">Global</div>
      <div>Actions</div>
    </div>

    <div class="list-view__row" v-for="workflow in workflows" :key="workflow.id">
      <div>
        <router-link :to="{ name: 'admin-screens', params: { globalWorkflowId: workflow.id } }">
          {{ workflow.title }}
        </router-link>
        <span class="text-small" v-if="hasOverridenAttribute(workflow, 'title')">
          &nbsp; ({{ workflow.original_title }})
        </span>
      </div>
      <div>
        <span class="colour" :style="{ backgroundColor: workflow.colour }"></span> {{ workflow.colour }}
        <span class="text-small" v-if="hasOverridenAttribute(workflow, 'colour')">
          &nbsp; ({{ workflow.original_colour }})
        </span>
      </div>
      <div>{{ workflowType({ workflow, fullTitle: true }) }}</div>
      <div class="list-view__small-column">
        <span class="show-for-small">Published: </span>
        <i class="fa-solid fa-check" v-if="workflow.published" />
        <i class="fa-solid fa-xmark" v-else />
      </div>
      <div class="list-view__small-column">
        <span class="show-for-small">Global: </span>
        <i class="fa-solid fa-check" v-if="workflow.global" />
        <i class="fa-solid fa-xmark" v-else />
      </div>
      <div class="list-view__center-column">
        <router-link :to="editWorkflowRoute(workflow)">
          <i class="fa-solid fa-pen-to-square" />
        </router-link>
        <router-link :to="{ name: 'admin-preview-workflow', params: { globalWorkflowId: workflow.id } }">
          <i class="fa-solid fa-file-magnifying-glass" />
        </router-link>
        <button class="icon-button" @click="cloneWorkflow(workflow)" :disabled="!!workflow.cloning">
          <i class="fa-solid fa-copy" />
        </button>
        <button class="icon-button" @click="restoreWorkflow(workflow)" :disabled="!!!workflow.original_id">
          <i class="fa-solid fa-rotate-left" />
        </button>
        <button class="icon-button" @click="deleteWorkflow(workflow)" :disabled="!canDeleteWorkflow(workflow)">
          <i class="fa-solid fa-trash" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Common from '@/common';
import PulseLoader from '@/components/PulseLoader';

export default {
  name: 'admin-workflows-list',

  props: ['workflows'],

  components: { PulseLoader },

  computed: {
    ...mapState({
      primaryColour: state => state.primaryColour,
    }),

    ...mapGetters([
      'workflowType',
    ]),

    ...mapGetters('admin', [
      'activeCompany',
    ]),
  },

  methods: {
    cloneWorkflow(workflow) {
      // eslint-disable-next-line no-alert
      const clonedTitle = prompt('Cloned workflow name:', `${workflow.title} (cloned)`);
      if (clonedTitle) {
        this.$store.dispatch('admin/cloneWorkflow', {
          companyId: this.activeCompany ? this.activeCompany.id : null,
          workflowId: workflow.id,
          clonedTitle,
        });
      }
    },

    restoreWorkflow(workflow) {
      Common.confirmDialog({
        modal: this.$modal,
        title: `Are you sure you want to restore the ${workflow.title} workflow?`,
        // eslint-disable-next-line max-len
        text: 'This will restore the workflow to the original rradar template, therefore losing any customisations.<br><br>You cannot restore custom workflows that have existing incidents.',
        okHandler: () => {
          this.$store.dispatch('admin/restoreWorkflow', {
            companyId: this.activeCompany ? this.activeCompany.id : null,
            workflowId: workflow.id,
          });
        },
      });
    },

    hasOverridenAttribute(workflow, attribute) {
      return workflow[`original_${attribute}`] && workflow[attribute] !== workflow[`original_${attribute}`];
    },

    editWorkflowRoute(workflow) {
      const routeParams = { companyId: this.activeCompany.id };
      let routeName = 'admin-edit-workflow';

      if (workflow.global && this.activeCompany.id) {
        routeName = 'admin-edit-company-workflow';
        routeParams.workflowId = workflow.id;
      } else {
        routeParams.globalWorkflowId = workflow.id;
      }

      return {
        name: routeName,
        params: routeParams,
      };
    },

    canDeleteWorkflow(workflow) {
      return (workflow.global && !this.activeCompany.id) || !workflow.global;
    },

    deleteWorkflow(workflow) {
      Common.confirmDialog({
        modal: this.$modal,
        title: `Are you sure you want to delete the ${workflow.title} workflow?`,
        // eslint-disable-next-line max-len
        text: 'This will delete the main workflow, therefore deleting it for any company that has access to it.<br><br>You cannot delete workflows that have existing incidents.',
        okHandler: () => {
          this.$store.dispatch('admin/deleteWorkflow', workflow.workflow_id || workflow.id);
        },
      });
    },
  },
};
</script>

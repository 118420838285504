<template>
  <div class="steps">
    <div class="steps__step"
      :class="{ 'steps__step--last': n == items.length - 1 }"
      v-for="(item, n) in items" @click="handleClick(item, n)"
      :key="item.id">

      <div class="steps__shape">
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <circle cx="100" cy="100" r="90"
          :stroke="themeColour"
          stroke-width="22"
          :style="activeStyle(isActive(item, n))"
          class="steps__circle" />
        </svg>
      </div>

      <div class="steps__line-container">
        <svg class="steps__line"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          v-if="n != items.length - 1">
            <line x1="0" y1="0" :x2="n == items.length - 1 ? '50%' : '100%'" y2="0"
          :stroke="themeColour"
          stroke-width="4" />
        </svg>

        <div class="steps__description"
          :class="{ 'steps__description--active': isActive(item, n) }">
            {{ n + 1 }}. {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['items', 'activeItem', 'clickEvent'],

  computed: {
    ...mapGetters([
      'themeColour',
    ]),
  },

  methods: {
    isActive(item, index) {
      if (this.activeItem.id) {
        return this.activeItem.id === item.id;
      }
      return index === 0;
    },

    activeStyle(active) {
      return active ? { fill: this.themeColour } : {};
    },

    handleClick(item, n) {
      if (typeof this.clickEvent === 'function') {
        this.clickEvent(item, n);
      }
    },
  },
};
</script>

<template>
  <router-link
    v-if="to"
    :to="to"
    class="button-link"
    :disabled="disabled"
    :event="disabled ? '' : 'click'">
      <button class="primary-button">
        <slot></slot>
      </button>
  </router-link>
  <a v-else-if="href" :href="href" class="button-link" :disabled="disabled">
    <button class="primary-button">
      <slot></slot>
    </button>
  </a>
  <button v-else class="primary-button" :disabled="disabled" :event="disabled ? '' : 'click'">
    <slot></slot>
  </button>
</template>

<script>
import Theme from '@/mixins/theme';

export default {
  props: ['to', 'href', 'disabled'],
};
</script>


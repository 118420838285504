/* eslint-disable no-shadow */

import Vue from 'vue';

import Common from '@/common';

const state = {
  recentIncidents: [],
  incidents: [],
  activeIncident: {},
  showClosedIncidents: false,
  notes: [],
};

const getters = {
  activeIncident: (state) => state.activeIncident,
  incidentsByWorkflowType: (state) => (workflowType) => {
    return state.incidents.filter((incident) => {
      return incident.workflow_type === workflowType && (state.showClosedIncidents || !incident.closed);
    });
  },
  incidentIndexById: (state) => (incidentId) => {
    return state.incidents.findIndex((incident) => incident.id === incidentId);
  },
  recentIncidentIndexById: (state) => (incidentId) => {
    return state.recentIncidents.findIndex((incident) => incident.id === incidentId);
  },
};

const mutations = {
  setRecentIncidents(state, incidents) {
    state.recentIncidents = incidents;
  },

  setIncidents(state, incidents) {
    state.incidents = incidents;
  },

  addIncident(state, incident) {
    const incidentAlreadyAdded = state.incidents.find((stateIncident) => stateIncident.id === incident.id);
    if (incidentAlreadyAdded) return;

    state.incidents.push(incident);
  },

  setActiveIncident(state, incident) {
    state.activeIncident = incident;
  },

  setNotes(state, notes) {
    state.notes = notes;
  },

  setShowClosedIncidents(state, status) {
    state.showClosedIncidents = status;
  },

  closeIncident(state, incidentIds) {
    const { incidentId, recentIncidentId } = incidentIds;

    if (incidentId >= 0) {
      state.incidents[incidentId].closed = true;
    }
    if (recentIncidentId >= 0) {
      state.recentIncidents[recentIncidentId].closed = true;
    }
  },

  addIncidentNote(state, note) {
    state.notes.push(note);
  },
};

const actions = {
  fetchRecentIncidents({ state, commit }) {
    if (state.recentIncidents.length > 0) return false;
    return Vue.axios
      .get('lawyer/incidents', {
        params: { size: 10, page: 1, sort: 'completed_at:DESC' },
      })
      .then((response) => {
        commit('setRecentIncidents', response.data.incident);
      })
      .catch((error) => Common.handleError(error));
  },

  fetchIncident({ commit }, incidentId) {
    return Vue.axios
      .get(`lawyer/incidents/${incidentId}`)
      .then((response) => {
        commit('setActiveIncident', response.data.incident);
        commit('addIncident', response.data.incident);
      })
      .catch((error) => Common.handleError(error));
  },

  fetchIncidents({ commit }, { dependsOn }) {
    return Vue.axios
      .get(`lawyer/companies/${dependsOn}/incidents`, {
        params: { size: 10, page: 1, sort: 'completed_at:DESC' },
      })
      .then((response) => {
        commit('setIncidents', response.data.incident);
      })
      .catch((error) => Common.handleError(error));
  },

  fetchNotes({ commit }, { dependsOn }) {
    return Vue.axios
      .get(`lawyer/incidents/${dependsOn}/notes`)
      .then((response) => {
        commit('setNotes', response.data.note);
      })
      .catch((error) => Common.handleError(error));
  },

  closeIncident({ commit, getters, dispatch }, incidentId) {
    return Vue.axios
      .post(`lawyer/incidents/${incidentId}/close`)
      .then(() => {
        commit('closeIncident', {
          incidentId: getters.incidentIndexById(incidentId),
          recentIncidentId: getters.recentIncidentIndexById(incidentId),
        });
        dispatch('setMessage', { text: 'Successfully closed incident' }, { root: true });
      })
      .catch((error) => Common.handleError(error));
  },

  createIncidentNote({ commit, dispatch }, { incident, note, uploadId = null, location = null }) {
    return Vue.axios
      .post(`lawyer/incidents/${incident}/notes`, {
        note,
        upload_id: uploadId,
        location,
      })
      .then((response) => {
        commit('addIncidentNote', response.data.note);
        dispatch('setMessage', { text: 'Successfully saved note' }, { root: true });
      })
      .catch((error) => Common.handleError(error));
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

/* eslint-disable no-shadow */

import Vue from 'vue';

import Common from '@/common';
import User from '@/user';

const state = {
  currentUser: {},
  sessionId: '',
  users: [],
  activeUser: 0,
  userFetchError: null,
};

const getters = {
  isAuthenticated: (state) => !!state.currentUser.id,
  isStandardUser: (state) => state.currentUser.global_role === 'standard',
  isAdminUser: (state) => state.currentUser.global_role === 'admin',
  isLawyerUser: (state) => state.currentUser.global_role === 'lawyer',
  fullName: (state) => User.fullName(state.currentUser.first_name, state.currentUser.last_name),
  activeUser: (state) => state.users.find((user) => user.id === state.activeUser) || {},
  activePrimaryUser: (state) => {
    return {
      id: state.currentUser.id,
      primary_user_accounts_id: state.currentUser.accounts_id,
    };
  },
  currentUserDetails: (state) => {
    const user = state.users.find((user) => user.accounts_id === state.currentUser.accounts_id);
    return user ? user.details : {};
  },
  activeUserId: (state) => {
    const userId = state.users.findIndex((user) => user.id === state.activeUser);
    return userId >= 0 ? userId : 0;
  },
  userById: (state) => (userId) => state.users.find((user) => user.id === userId) || {},
  userIndexById: (state) => (userId) => state.users.findIndex((user) => user.id === userId),
  userFullName: (state, getters) => (userId) => {
    const user = getters.userById(userId);
    if (user.details) {
      return User.fullName(user.details.first_name, user.details.last_name);
    }
    return 'Unknown user';
  },
  hasPermissionForResource: (state) => (userId) => {
    return (
      (userId && userId === state.currentUser.accounts_id) || ['manager', 'admin'].includes(state.currentUser.role)
    );
  },
};

const mutations = {
  setSessionId(state, sessionId) {
    state.sessionId = sessionId;
  },

  removeSessionId(state) {
    state.sessionId = '';
  },

  setCurrentUser(state, user) {
    state.currentUser = Object.assign({ role: user.role }, user.user);
  },

  removeActiveUser(state) {
    state.currentUser = {};
  },

  setActiveUser(state, user) {
    state.activeUser = user;
  },

  setUsers(state, users) {
    state.users = users;
  },

  removeUsers(state, company) {
    state.users = state.users.filter((user) => user.company_id !== company);
  },

  populateUsers(state, { schema, users }) {
    schema.values = users;
  },

  setUserFetchError(state, status) {
    state.userFetchError = status;
  },
};

const actions = {
  authenticate({ commit, dispatch }, authToken) {
    return Vue.axios
      .post('auth', { auth_token: authToken })
      .then((response) => {
        dispatch('fetchAvailableCompanies');
        dispatch('loginUser', {
          sessionId: response.data.session_id,
          displayMessage: true,
        });
      })
      .catch((error) => {
        Common.handleError(error, null, null, false);
        commit('setUserFetchError', error.response.data.message);
      });
  },

  loginUser({ commit, dispatch }, { sessionId, displayMessage }) {
    localStorage.setItem('sessionId', sessionId);
    commit('setSessionId', sessionId);
    dispatch('fetchCurrentUser').then(() => {
      if (displayMessage) {
        dispatch('setMessage', { text: 'Successfully logged in' });
      }
    });
  },

  fetchCurrentUser({ commit }) {
    return Vue.axios
      .get('me')
      .then((response) => {
        commit('setCurrentUser', {
          user: response.data.me,
          role: response.data.role,
        });
        commit('setUserFetchError', null);
      })
      .catch((error) => {
        Common.handleError(error, null, null, false);
        commit('setUserFetchError', error.response.data.message);
      });
  },

  logout({ commit }) {
    return Vue.axios
      .delete('auth')
      .then(() => {
        localStorage.clear();
        commit('removeSessionId');
        commit('removeActiveUser');
      })
      .catch((error) => Common.handleError(error));
  },

  fetchUsers({ commit }, { dependsOn }) {
    return Vue.axios
      .get('users')
      .then((response) => {
        commit('removeUsers', dependsOn);
        commit('setUsers', response.data.user);
      })
      .catch((error) => Common.handleError(error));
  },

  populateUsers({ getters, commit }) {
    return Vue.axios
      .get('users-available')
      .then((response) => {
        commit('populateUsers', {
          schema: getters.userAccountIdSchema,
          users: User.users(response.data.user),
        });
      })
      .catch();
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

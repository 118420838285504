import '@babel/polyfill';

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import VueFormGenerator from 'vue-form-generator';
import { Tabs, Tab } from 'vue-tabs-component';
import { sync } from 'vuex-router-sync';
import Multiselect from 'vue-multiselect';
import VueModal from 'vue-js-modal';
import pluralize from 'pluralize';
import App from './App';
import router from './router';
import store from './store/index';
import ThemedButton from './components/ThemedButton';
import fieldButton from './components/fields/fieldButton';
import fieldCustomCheckbox from './components/fields/fieldCustomCheckbox';
import fieldCustomChecklist from './components/fields/fieldCustomChecklist';
import fieldCustomInput from './components/fields/fieldCustomInput';
import fieldText from './components/fields/fieldText';
import fieldNewRow from './components/fields/fieldNewRow';
import fieldCustomRadios from './components/fields/fieldCustomRadios';
import fieldCustomSelect from './components/fields/fieldCustomSelect';
import fieldCustomSubmit from './components/fields/fieldCustomSubmit';
import fieldDuration from './components/fields/fieldDuration';
import fieldFlatpickr from './components/fields/fieldFlatpickr';
import fieldFileUpload from './components/fields/fieldFileUpload';
import fieldCustomVueMultiSelect from './components/fields/fieldCustomVueMultiSelect';
import './assets/styles/main.scss';

Vue.component('tabs', Tabs);
Vue.component('tab', Tab);
Vue.component('multiselect', Multiselect);
Vue.component('fieldButton', fieldButton);
Vue.component('fieldCustomCheckbox', fieldCustomCheckbox);
Vue.component('fieldCustomChecklist', fieldCustomChecklist);
Vue.component('fieldCustomInput', fieldCustomInput);
Vue.component('fieldText', fieldText);
Vue.component('fieldNewRow', fieldNewRow);
Vue.component('fieldCustomRadios', fieldCustomRadios);
Vue.component('fieldCustomSelect', fieldCustomSelect);
Vue.component('fieldCustomSubmit', fieldCustomSubmit);
Vue.component('fieldDuration', fieldDuration);
Vue.component('fieldFlatpickr', fieldFlatpickr);
Vue.component('fieldFileUpload', fieldFileUpload);
Vue.component('fieldCustomVueMultiSelect', fieldCustomVueMultiSelect);
Vue.component('ThemedButton', ThemedButton);

Vue.use(VueFormGenerator);
Vue.use(VueModal, { dialog: true });

Object.defineProperty(Vue.prototype, 'pluralize', { value: pluralize });

sync(store, router);

Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  template: '<App/>',
  render: (h) => h(App),
});

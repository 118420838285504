<template>
  <div v-if="screen">
    <h3 v-if="hasNotes">{{ screen.title }} <template v-if="index">- {{ index }}</template></h3>
    <note-list :notes="screen.notes" />

    <div :key="fieldRef" v-for="(field, fieldRef) in screen.fields">
      <h4>{{ field.title }}</h4>
      <note-list :notes="field.notes" />
    </div>

    <div :key="index" v-for="(screen, index) in screen.screens">
      <note-screen :screen="screen" :index="index + 1" />
    </div>
  </div>
</template>

<script>
import NoteList from './List';

export default {
  name: 'note-screen',

  props: ['screen', 'index'],

  components: { NoteList },

  computed: {
    hasNotes() {
      return this.screen.notes.length > 0 || Object.keys(this.screen.fields).length > 0;
    },
  },
};
</script>

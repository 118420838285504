<template>
  <div class="flex flex--vert-center flex--horiz-center field-custom-submit">
    <button class="primary-button" ref="themeMount" type="button" @click="click" :disabled="disabled">
      {{ submittingForm ? "" : schema.buttonText }}
    <pulse-loader
      color="white"
      v-if="!schema.noLoadingSpinner"
      :loading="submittingForm"
      title="Submitting form"
    />
    </button>
  </div>
</template>

<script>
import { abstractField } from 'vue-form-generator';
import { mapState, mapMutations } from 'vuex';
import PulseLoader from '@/components/PulseLoader';

export default {
  mixins: [abstractField],

  components: { PulseLoader },

  computed: {
    ...mapState(['submittingForm']),
  },

  methods: {
    ...mapMutations(['setSubmittingForm']),

    click() {
      if (this.schema.validateBeforeSubmit === true) {
        if (!this.vfg.validate()) {
          // There are validation errors. Stop the submit
          return;
        }
      }
      if (typeof this.schema.onSubmit === 'function') {
        if (!this.schema.noLoadingSpinner) this.setSubmittingForm(true);
        this.schema.onSubmit(this.model, this.schema, this.formOptions.context, this.$parent);
      }
    },
  },
};
</script>


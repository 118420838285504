/* eslint-disable no-shadow */

import Vue from 'vue';

import Common from '@/common';
import operator from './operator';

const state = {
  screens: [],
  activeScreen: 0,
  screenSchema: {
    basicInfo: {
      fields: [
        {
          type: 'customInput',
          inputType: 'text',
          styleClasses: 'form__field',
          label: 'Title',
          model: 'title',
          validator: 'string',
          required: true,
        },
        {
          type: 'customInput',
          inputType: 'number',
          styleClasses: 'form__field',
          label: 'Position',
          model: 'position',
        },
        {
          type: 'customCheckbox',
          styleClasses: 'form__field',
          label: 'Allow multiple?',
          model: 'allow_multiple',
        },
        {
          type: 'customInput',
          inputType: 'number',
          styleClasses: 'form__field',
          label: 'Multiple Limit',
          model: 'multiple_limit',
        },
        {
          model: '',
          type: 'customSubmit',
          styleClasses: 'form__field',
          buttonText: 'Add Screen',
          onlyOn: 'new',
          onSubmit: (model, schema, context, form) => {
            context.addForm(model, form);
          },
          validateBeforeSubmit: true,
        },
        {
          model: '',
          type: 'customSubmit',
          styleClasses: 'form__field',
          buttonText: 'Edit Screen',
          onlyOn: 'edit',
          onSubmit: (model, schema, context, form) => {
            context.editForm(model, form);
          },
          validateBeforeSubmit: true,
        },
      ],
    },
    screenConditions: {
      fields: [
        {
          type: 'customSelect',
          styleClasses: 'form__field',
          label: 'Field',
          model: 'field_id',
          values: [],
          validator: 'integer',
          required: true,
        },
        {
          type: 'customSelect',
          styleClasses: 'form__field',
          label: 'Operator',
          model: 'operator',
          values: () => operator.state.operators,
          validator: 'string',
          required: true,
        },
        {
          type: 'customInput',
          inputType: 'text',
          styleClasses: 'form__field',
          label: 'Value',
          model: 'value',
          validator: 'string',
          required: true,
        },
        {
          type: 'customInput',
          inputType: 'number',
          styleClasses: 'form__field',
          label: 'Priority',
          model: 'priority',
          validator: 'integer',
        },
        {
          type: 'customSelect',
          styleClasses: 'form__field',
          label: 'Next',
          model: 'next_screen_id',
          values: () => {
            return this.a.state.screens
              .filter((screen) => screen.id !== this.a.state.activeScreen)
              .map((screen) => ({ id: screen.id, name: screen.title }));
          },
          validator: 'integer',
          required: true,
        },
        {
          type: 'customCheckbox',
          styleClasses: 'form__field',
          label: 'Inverse',
          model: 'inverse',
        },
        {
          model: '',
          type: 'customSubmit',
          styleClasses: 'form__field',
          buttonText: 'Save',
          onSubmit: (model, schema, context, form) => {
            context.saveObject(model, form);
          },
          validateBeforeSubmit: true,
        },
        {
          model: '',
          type: 'customSubmit',
          styleClasses: 'form__field',
          buttonText: 'Cancel',
          noLoadingSpinner: true,
          visible: (model) => {
            return Object.keys(model).length !== 0;
          },
          onSubmit: (model, schema, context) => {
            context.clearForm();
          },
        },
      ],
    },
  },
};

const getters = {
  activeScreen: (state) => state.screens.find((screen) => screen.id === state.activeScreen) || {},
  activeScreenId: (state, getters) => {
    const screenId = state.screens.findIndex((screen) => screen.id === getters.activeScreen.id);
    return screenId >= 0 ? screenId : 0;
  },
  screenConditionId: (state, getters) => (screenCondition) => {
    const screenConditionId = getters.activeScreen.conditions.findIndex(
      (condition) => condition.id === screenCondition,
    );
    return screenConditionId >= 0 ? screenConditionId : 0;
  },
  screenById: (state) => (screenId) => state.screens.find((screen) => screen.id === screenId),
  screenIndexById: (state) => (screenId) => {
    const screenIndex = state.screens.findIndex((screen) => screen.id === screenId);
    return screenIndex >= 0 ? screenIndex : 0;
  },
};

const mutations = {
  setScreens(state, screens) {
    const processedScreens = screens.map((screen) => {
      screen.multiple_count = 1;
      return screen;
    });
    state.screens = processedScreens;
  },

  setScreenConditions(state, { screenId, conditions }) {
    if (conditions) {
      Vue.set(state.screens[screenId], 'conditions', conditions);
    }
  },

  setActiveScreen(state, screen) {
    state.activeScreen = screen;
  },

  addScreen(state, screen) {
    state.screens.push(screen);
  },

  editScreen(state, { screenId, editedScreen }) {
    Vue.set(state.screens, screenId, editedScreen);
  },

  deleteScreen(state, { screenId }) {
    Vue.delete(state.screens, screenId);
  },

  addFieldsToScreenConditionsDropdown(state, fields) {
    Vue.set(state.screenSchema.screenConditions.fields[0], 'values', fields);
  },

  addScreenCondition(state, { screenId, screenCondition }) {
    state.screens[screenId].conditions.push(screenCondition);
  },

  editScreenCondition(state, { screenId, screenConditionId, screenCondition }) {
    Vue.set(state.screens[screenId].conditions, screenConditionId, screenCondition);
  },

  deleteScreenCondition(state, { screenId, screenConditionId }) {
    Vue.delete(state.screens[screenId].conditions, screenConditionId);
  },
};

const actions = {
  fetchScreens({ commit }, { dependsOn }) {
    return Vue.axios
      .get(`admin/workflows/${dependsOn}/screens`)
      .then((response) => {
        commit('setScreens', response.data.screen);
      })
      .catch();
  },

  fetchScreenConditions({ getters, commit }, { dependsOn }) {
    return Vue.axios
      .get(`admin/screens/${dependsOn}/conditions`)
      .then((response) => {
        commit('setScreenConditions', {
          screenId: getters.activeScreenId,
          conditions: response.data.condition,
        });
      })
      .catch();
  },

  addScreen({ commit, dispatch }, { data, parentId, form }) {
    return Vue.axios
      .post(`admin/workflows/${parentId}/screens`, data)
      .then((response) => {
        commit('addScreen', response.data.screen);
        dispatch('setMessage', { messageType: 'success' }, { root: true });
      })
      .catch((error) => Common.handleError(error, form));
  },

  editScreen({ getters, commit, dispatch }, { data, form, noCommit }) {
    return Vue.axios
      .put(`admin/screens/${data.id}`, data)
      .then((response) => {
        if (!noCommit) {
          commit('editScreen', {
            screenId: getters.activeScreenId,
            editedScreen: response.data.screen,
          });
          dispatch('setMessage', { text: `Successfully edited ${data.title}` }, { root: true });
        }
      })
      .catch((error) => Common.handleError(error, form));
  },

  deleteScreen({ getters, commit, dispatch }, { workflowId, screenId }) {
    commit('deleteScreen', { screenId: getters.screenIndexById(screenId) });
    return Vue.axios
      .delete(`admin/workflows/${workflowId}/screens/${screenId}`)
      .then(() => dispatch('setMessage', { text: 'Successfully deleted screen' }, { root: true }))
      .catch((error) => Common.handleError(error));
  },

  addScreenCondition({ getters, commit, dispatch }, { data, parentId, form }) {
    return Vue.axios
      .post(`admin/screens/${parentId}/conditions`, data)
      .then((response) => {
        commit('addScreenCondition', {
          screenId: getters.screenIndexById(parentId),
          screenCondition: response.data.condition,
        });
        dispatch('setMessage', { text: 'Successfully created screen condition' }, { root: true });
      })
      .catch((error) => Common.handleError(error, form));
  },

  editScreenCondition({ state, getters, commit, dispatch }, { data, form, noCommit }) {
    return Vue.axios
      .put(`admin/screens/${state.activeScreen}/conditions/${data.id}`, data)
      .then((response) => {
        if (!noCommit) {
          commit('editScreenCondition', {
            screenId: getters.activeScreenId,
            screenConditionId: getters.screenConditionId(data.id),
            screenCondition: response.data.condition,
          });
          dispatch(
            'setMessage',
            {
              text: `Successfully edited condition for ${getters.fieldById(data.field_id).title}`,
            },
            { root: true },
          );
        }
      })
      .catch((error) => Common.handleError(error, form));
  },

  deleteScreenCondition({ state, getters, commit, dispatch }, { inlineId }) {
    return Vue.axios
      .delete(`admin/screens/${state.activeScreen}/conditions/${inlineId}`)
      .then(() => {
        commit('deleteScreenCondition', {
          screenId: getters.activeScreenId,
          screenConditionId: getters.screenConditionId(inlineId),
        });
        dispatch('setMessage', { text: 'Successfully deleted screen condition' }, { root: true });
      })
      .catch((error) => Common.handleError(error));
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

/* eslint-disable no-shadow */

const state = {
  fieldTypesWithOptions: ['dropdown', 'checkbox', 'radio'],
  fieldTypesWithInputBox: ['text', 'textarea', 'integer'],
  inputTypes: {
    text: {
      name: 'Text Box',
      icon: 'input',
      vfg: {
        type: 'customInput',
        inputType: 'text',
      },
    },
    textarea: {
      name: 'Multi-Line Text Box',
      icon: 'subject',
      vfg: {
        type: 'textArea',
        rows: 4,
      },
    },
    integer: {
      name: 'Number',
      icon: 'filter_1',
      vfg: {
        type: 'customInput',
        inputType: 'number',
      },
    },
    boolean: {
      name: 'Checkbox',
      icon: 'flip',
      vfg: {
        type: 'customCheckbox',
      },
    },
    date: {
      name: 'Date',
      icon: 'date_range',
      vfg: {
        type: 'flatpickr',
      },
    },
    time: {
      name: 'Time',
      icon: 'access_time',
      vfg: {
        type: 'flatpickr',
        flatpickrOptions: {
          enableTime: true,
          noCalendar: true,
          defaultHour: new Date().getHours(),
          defaultMinute: new Date().getMinutes(),
        },
      },
    },
    datetime: {
      name: 'Date/Time',
      icon: 'av_timer',
      vfg: {
        type: 'flatpickr',
        flatpickrOptions: {
          enableTime: true,
          defaultHour: new Date().getHours(),
          defaultMinute: new Date().getMinutes(),
        },
      },
    },
    duration: {
      name: 'Duration',
      icon: 'timelapse',
      vfg: {
        type: 'duration',
      },
    },
    dropdown: {
      name: 'Drop Down',
      icon: 'menu',
      vfg: {
        type: 'customSelect',
        selectOptions: {
          noneSelectedText: 'Please choose an option',
        },
      },
    },
    checkbox: {
      name: 'Checkboxes',
      icon: 'check_box',
      vfg: {
        type: 'customChecklist',
        listBox: true,
      },
    },
    radio: {
      name: 'Radio',
      icon: 'radio_button_checked',
      vfg: {
        type: 'customRadios',
      },
    },
    file: {
      name: 'File Upload',
      icon: 'attach_file',
      vfg: {
        type: 'fileUpload',
      },
    },
    statictext: {
      name: 'Static Text',
      icon: 'insert_comment',
      vfg: {
        type: 'text',
      },
    },
    submit: {
      name: 'Submit',
      icon: 'submit',
      disabledInAdmin: true,
      vfg: {
        type: 'customSubmit',
        validateBeforeSubmit: true,
      },
    },
    button: {
      name: 'Button',
      disabledInAdmin: true,
      vfg: {
        type: 'button',
      },
    },
  },
};

const getters = {
  fieldHasOptions: (state) => (fieldType) => state.fieldTypesWithOptions.includes(fieldType),
  fieldHasInputBox: (state) => (fieldType) => state.fieldTypesWithInputBox.includes(fieldType),
};

const mutations = {};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};

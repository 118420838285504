<template>
  <modal-wrapper name="incident-notes" @before-open="beforeOpen">
    <div class="notes">
      <div>
        <h2>{{ workflow.title }} Notes</h2>

        <template v-if="filteredNotes.length > 0">
          <note-list :notes="groupedNotes.notes" />

          <div :key="index" v-for="(screen, index) in groupedNotes.screens">
            <note-screen :screen="screen" />
          </div>
        </template>
        <template v-else
          ><em>No notes have been added to this {{ noteType }}</em></template
        >
      </div>
      <div class="notes__add-note-container">
        <span class="error">{{ noteError }}</span>
        <textarea class="notes__add-note" rows="5" v-model="newNote" placeholder="Enter note text"></textarea>

        <div class="notes__add-note" v-if="showDropzone">
          <dropzone
            id="note_upload"
            :options="dropzoneOptions"
            v-on:vdropzone-success="setNoteUpload"
            v-on:vdropzone-removed-file="unsetNoteUpload"
            v-on:vdropzone-error="handleUploadError"
          >
          </dropzone>
        </div>

        <div class="flex button-container">
          <themed-button class="button button--no-margin" @click.native="createIncidentNote()">
            Add Note
          </themed-button>
          <button class="secondary-button" @click="toggleDropzone">
            <i class="fa-solid fa-paperclip" /> Attach File
          </button>
        </div>
      </div>
    </div>
  </modal-wrapper>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import notes from '@/mixins/notes';
import ModalWrapper from '@/components/ModalWrapper';
import NoteList from './List';
import NoteScreen from './Screen';

export default {
  name: 'note-modal',

  props: ['notes', 'workflow', 'incident'],

  mixins: [notes],

  components: { ModalWrapper, NoteList, NoteScreen, Dropzone },

  data() {
    return {
      newNote: '',
      noteType: '',
      noteLocation: null,
      noteError: '',
      noteUpload: null,
      showDropzone: false,
    };
  },

  computed: {
    ...mapState({
      sessionId: (state) => state.user.sessionId,
    }),

    ...mapGetters(['inLawyerArea']),

    filteredNotes() {
      const filteredNotes = this.notes;
      filteredNotes.map((note) => {
        Object.assign(note, this.parseNoteLocation(note.location));
        note.screenTitle = this.screen(note).title;
        note.fieldTitle = this.field(note).title;
        return note;
      });

      if (this.noteLocation) {
        return filteredNotes.filter((note) => note.location && note.location.startsWith(this.noteLocation));
      }

      return filteredNotes;
    },

    groupedNotes() {
      return this.filteredNotes.reduce(
        (group, note) => {
          return this.buildNoteLocation(group, note);
        },
        { notes: [], screens: [] },
      );
    },

    dropzoneOptions() {
      return {
        url: `${process.env.API_HOST}/uploads`,
        thumbnailWidth: 140,
        thumbnailHeight: 140,
        maxFiles: 1,
        maxFilesize: 100,
        addRemoveLinks: true,
        headers: { Authorization: this.sessionId },
        dictDefaultMessage: 'Drop file here to upload',
      };
    },
  },

  methods: {
    createIncidentNote() {
      const actionName = this.inLawyerArea ? 'lawyer/createIncidentNote' : 'createIncidentNote';
      this.$store
        .dispatch(actionName, {
          incident: this.incident.id,
          note: this.newNote,
          location: this.noteLocation,
          uploadId: this.noteUpload,
        })
        .then(() => {
          this.newNote = '';
          this.noteError = '';
          this.noteUpload = null;
          this.showDropzone = false;
        })
        .catch((response) => {
          this.noteError = response.data.errors.note[0];
        });
    },

    beforeOpen(event) {
      this.noteType = event.params.noteType;
      this.noteLocation = this.buildPath(event.params.noteLocation);
    },

    screen({ screenIndex }) {
      if (this.incident.data && screenIndex !== undefined) {
        return this.incident.data.screens[screenIndex].screen;
      }
      return {};
    },

    field({ screenIndex, screenCopyIndex, fieldRef }) {
      if (this.incident.data && fieldRef) {
        const screenData = this.incident.data.screens[screenIndex];
        if (screenCopyIndex >= 0) {
          return screenData.data[screenCopyIndex][fieldRef];
        }
        return screenData.data[fieldRef];
      }
      return {};
    },

    parseNoteLocation(location) {
      const parsedLocation = /^\[(\d+)\](?:\[(\d+)\])?(?:\[(.+)\])?/g.exec(location);

      if (parsedLocation) {
        return {
          screenIndex: parseInt(parsedLocation[1]),
          screenCopyIndex: parseInt(parsedLocation[2]),
          fieldRef: parsedLocation[3],
        };
      }

      return {};
    },

    buildNoteLocation(group, note) {
      let noteLocation = group;

      /* eslint-disable no-multi-assign */
      if (Number.isInteger(note.screenIndex)) {
        noteLocation = group.screens[note.screenIndex] = this.noteLoc(note, group.screens[note.screenIndex], 'screen');

        if (Number.isInteger(note.screenCopyIndex)) {
          noteLocation = noteLocation.screens[note.screenCopyIndex] = this.noteLoc(
            note,
            noteLocation.screens[note.screenCopyIndex],
            'screen',
          );
        }
        if (note.fieldRef) {
          noteLocation = noteLocation.fields[note.fieldRef] = this.noteLoc(
            note,
            noteLocation.fields[note.fieldRef],
            'field',
          );
        }
      }
      /* eslint-enable no-multi-assign */

      noteLocation.notes.push(note);
      return group;
    },

    noteLoc(note, noteLocation, noteType) {
      const screenObj = { title: note.screenTitle, notes: [], fields: {}, screens: [] };
      const fieldObj = { title: note.fieldTitle, notes: [] };
      return noteLocation || (noteType === 'screen' ? screenObj : fieldObj);
    },

    toggleDropzone() {
      this.showDropzone = !this.showDropzone;
    },

    setNoteUpload(_file, response) {
      this.noteUpload = response.upload.id;
    },

    unsetNoteUpload(_file, _error, _xhr) {
      this.noteUpload = null;
    },

    setNoteError(error) {
      this.noteError = error;
    },

    handleUploadError(file) {
      if (file.xhr) {
        const response = JSON.parse(file.xhr.response);
        this.$store.dispatch('handleError', {
          response,
        });
      }
    },
  },
};
</script>

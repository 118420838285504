/* eslint-disable no-shadow */

import Vue from 'vue';

import Common from '@/common';

const state = {
  activeWorkflow: {},
};

const getters = {
  activeWorkflow: (state) => state.activeWorkflow,
};

const mutations = {
  setActiveWorkflow(state, workflow) {
    state.activeWorkflow = workflow;
  },
};

const actions = {
  fetchWorkflow({ commit }, workflowId) {
    return Vue.axios
      .get(`lawyer/workflows/${workflowId}`)
      .then((response) => {
        commit('setActiveWorkflow', response.data.workflow);
      })
      .catch((error) => Common.handleError(error));
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

<template>
  <incident-single
    :incident="activeIncident"
    :workflow="activeWorkflow"
    :notes="notes"
    :user-name="userFullName(activeIncident.company_user_id)">

  </incident-single>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import fetchResources from '@/mixins/fetch-resources';
import IncidentSingle from '@/components/incidents/Incident';

export default {
  name: 'incident-show',

  components: { IncidentSingle },

  mixins: [fetchResources],

  data() {
    return {
      resources: [
        { name: 'Users', dependant: 'Company' },
        { name: 'Incident', dependant: 'Incident' },
      ],
    };
  },

  computed: {
    ...mapState({
      notes: state => state.incident.notes,
    }),

    ...mapGetters([
      'activeCompany',
      'activeWorkflow',
      'activeIncident',
      'workflowType',
      'userFullName',
    ]),
  },
};
</script>

/* eslint-disable no-shadow */

import Vue from 'vue';

import Common from '@/common';

const state = {
  companies: [],
  activeCompany: {},
};

const getters = {
  orderedCompanies: (state) => state.companies.concat().sort((a, b) => a.accounts_id - b.accounts_id),
  companyCount: (state) => state.companies.length,
  activeCompany: (state) => state.activeCompany,
};

const mutations = {
  setCompanies(state, companies) {
    state.companies = companies;
  },

  setActiveCompany(state, company) {
    state.activeCompany = company;
  },
};

const actions = {
  searchCompanies({ commit }, term) {
    return Vue.axios
      .post('search', { term, types: ['company'] })
      .then((response) => {
        commit(
          'setCompanies',
          response.data.rows.map((row) => row.source),
        );
      })
      .catch((error) => Common.handleError(error));
  },

  fetchCompany({ commit }, companyId) {
    return Vue.axios
      .get(`lawyer/companies/${companyId}`)
      .then((response) => {
        commit('setActiveCompany', response.data.company);
      })
      .catch((error) => Common.handleError(error));
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

import router from '@/router/index';
import Store from '@/store/index';
import user from '@/store/modules/user';

const handleError = (error, form = null, fieldKey = null, showToastMessage = true) => {
  Store.dispatch(
    'handleError',
    { response: error && error.response, form, fieldKey, showToastMessage },
    { root: true },
  );

  const response = error.response;
  if (response && response.status === 401) {
    const url = response.config.url.replace(`${response.config.baseURL}/`, '');

    if (user.state.sessionId && !['auth', 'me'].includes(url)) {
      router.push({ name: 'index' });
    } else {
      if (user.state.sessionId && url.includes('me')) {
        Store.dispatch('logout');
      }
      router.push({ name: 'login', params: { redirectPath: router.currentRoute } });
    }
  }
  if (response && response.status === 404) {
    Store.commit('setNotFoundError', true);
  }

  return Promise.reject(response || error);
};

const confirmDialog = ({ modal, title = '', text = '', okHandler, cancelHandler }) => {
  modal.show('dialog', {
    title,
    text,
    buttons: [
      {
        title: 'OK',
        handler: () => {
          modal.hide('dialog');
          if (typeof okHandler === 'function') {
            okHandler();
          }
        },
      },
      {
        title: 'Cancel',
        handler: () => {
          modal.hide('dialog');
          if (typeof cancelHandler === 'function') {
            cancelHandler();
          }
        },
      },
    ],
  });
};

const fullName = (firstName, lastName) => {
  if (firstName !== lastName) {
    return `${firstName} ${lastName}`;
  }
  return firstName;
};

const users = (items) => {
  return items
    .map((item) => {
      const details = item.details || item;
      item.name = `${details.first_name} ${details.last_name}`;
      return item;
    })
    .sort((a, b) => a.name.localeCompare(b.name));
};

export default {
  handleError,
  confirmDialog,
  fullName,
  users,
};

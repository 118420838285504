<template>
  <div
    :id="getFieldID(schema)"
    :class="schema.fieldClasses"
    v-html="value || schema.staticText"
    @click.prevent="clickHandler()">
  </div>
</template>

<script>
import { abstractField } from 'vue-form-generator';

export default {
  mixins: [abstractField],

  methods: {
    clickHandler() {
      if (this.schema.modal) {
        this.$modal.show(this.schema.modal);
      }
    },
  },
};
</script>

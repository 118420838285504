import clone from 'lodash.clone';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  data: () => ({
    formSchema: {
      groups: [
        {
          fields: [],
        },
      ],
    },
    formModel: {},
  }),

  computed: {
    ...mapState({
      inputTypes: (state) => state.field.inputTypes,
      activeIncident: (state) => state.incident.activeIncident,
      activeScreenId: (state) => state.incident.activeScreen,
      currentScreenId: (state) => state.incident.currentScreen,
      incidentProgress: (state) => state.incident.incidentProgress,
    }),

    ...mapState(['formOptions']),

    ...mapGetters(['onLastScreen']),
  },

  watch: {
    activeFields() {
      if (this.activeFields && this.activeFields.length > 0) {
        this.buildForm();
      }
    },

    'activeScreen.id': {
      handler(value) {
        const existingData = this.incidentProgress.find((screen) => screen.id === value);
        if (existingData) {
          this.formModel = existingData.data;
        } else {
          this.formModel = {};
        }
      },
    },

    'activeScreen.multiple_count': {
      handler(val, oldVal) {
        if (oldVal && val !== this.formSchema.groups.length - 1) {
          this.buildForm();
        }
      },
    },

    formModel: {
      handler() {
        this.storeIncidentProgress({ screen: this.activeScreenId, data: clone(this.formModel) });
      },
      deep: true,
    },
  },

  methods: {
    ...mapMutations([
      'removeScreen',
      'setIncident',
      'setAlreadyScrolled',
      'setSubmittingForm',
      'storeIncidentProgress',
    ]),

    ...mapActions(['saveScreen', 'copyScreen']),

    buildForm() {
      this.formSchema.groups[0].fields = [
        this.buildField(
          {
            buttonText: this.onLastScreen ? 'Submit Report' : 'Next Screen',
            field_type: 'submit',
            // eslint-disable-next-line max-len
            hint: 'Are you sure all details are correct?\nYou will not be able to go back to this screen other than to add notes.',
          },
          { disabled: this.formPreview },
        ),
      ];
      this.addGroupsAndFields();
    },

    addGroupsAndFields() {
      this.formSchema.groups.splice(1);
      const existingGroupsCount = this.formSchema.groups.length;
      let newGroupsCount = this.activeScreen.multiple_count - existingGroupsCount + 1;
      newGroupsCount = newGroupsCount <= 0 ? 1 : newGroupsCount;
      for (let i = existingGroupsCount; i < existingGroupsCount + newGroupsCount; i += 1) {
        this.formSchema.groups[i] = { fields: [] };
        if (this.activeScreen.allow_multiple) {
          this.addCopyButtons(i);
        }
        this.addFields(i);
      }
    },

    addCopyButtons(multipleIndex) {
      this.formSchema.groups[multipleIndex].fields.push(
        this.buildField(
          {
            buttonText: 'Add Another',
            field_type: 'button',
            buttonAction: () => this.copyScreen(this.activeScreen),
          },
          { disabled: this.formPreview },
        ),
      );
      if (multipleIndex > 1) {
        this.formSchema.groups[multipleIndex].fields.push(
          this.buildField({
            buttonText: 'Remove',
            field_type: 'button',
            buttonAction: () => this.removeScreenModel(multipleIndex),
          }),
        );
      }
    },

    removeScreenModel(multipleIndex) {
      Object.keys(this.formModel)
        .filter((field) => field.startsWith(`${multipleIndex}-`))
        .forEach((field) => this.$delete(this.formModel, field));
      this.$delete(this.formSchema.groups, multipleIndex);
      this.removeScreen(this.activeScreen);
    },

    addFields(group) {
      if (this.activeFields) {
        this.activeFields.forEach((field, index) => {
          const options = {};
          options.last = index === this.activeFields.length - 1;
          options.multipleIndex = group;
          options.index = index;
          const fields = this.formSchema.groups[group].fields;
          fields.splice(index, 0, this.buildField(field, options));
        });
      }
    },

    buildField(field, options = {}) {
      let styleClasses = 'form__field form__field--incident';
      if (options.last && options.index % 2 === 0) styleClasses = styleClasses.concat(' form__field--last');
      if (field.field_type === 'file') styleClasses = styleClasses.concat(' form__field--full');

      return {
        ...this.inputTypes[field.field_type].vfg,
        styleClasses,
        label: field.title,
        model: `${options.multipleIndex}-${field.ref}`,
        values: field.options
          ? field.options.map((option) => {
              return { id: option.value, name: option.text, value: option.value };
            })
          : [],
        minlength: field.min_length,
        maxlength: field.max_length,
        default: field.default,
        required: field.required,
        pattern: field.validation,
        validator: this.validations(field),
        placeholder: field.placeholder,
        hint: field.hint,
        disabled: options.disabled || this.activeScreenId !== this.currentScreenId,
        staticText: field.static_text,
        buttonText: field.buttonText,
        buttonAction: field.buttonAction,
        onSubmit: (model, schema, context, form) => this.handleSubmit(model, schema, context, form),
      };
    },

    validations(field) {
      const validations = [];
      if (field.validation) {
        validations.push('regexp');
      }
      if (field.field_type === 'integer') {
        validations.push('number');
      }
      if (['text', 'textarea', 'dropdown', 'datetime'].includes(field.field_type)) {
        validations.push('string');
      }
      return validations;
    },

    handleSubmit(model, schema, context, form) {
      this.saveScreen({
        incident: this.activeIncident,
        screen: this.activeScreen.id,
        model,
        form,
      }).then((response) => {
        this.setSubmittingForm(false);
        if (response.data.incident.complete) {
          this.$router.push({
            name: 'incident',
            params: {
              workflowId: response.data.incident.workflow_id,
              incidentId: response.data.incident.id,
            },
          });
          this.setIncident(response.data.incident);
        } else if (response.data.incident.next_screen) {
          this.$router.push({
            name: 'resume-incident',
            params: { incidentId: this.activeIncident, screenId: response.data.incident.next_screen },
          });
        }
      });

      const incidentForm = document.getElementById('incident');

      if (incidentForm.offsetTop - 20 < window.scrollY) {
        window.scrollTo(0, incidentForm.offsetTop - 20);
        this.setAlreadyScrolled(true);
      }
    },
  },
};

<template>
  <!-- eslint-disable vue/require-v-for-key -->
  <div class="select-wrapper">
    <select
      class="form-control"
      v-model="value"
      :disabled="disabled"
      :name="schema.inputName"
      :id="getFieldID(schema)"
      :class="schema.fieldClasses">
        <option v-if="!selectOptions.hideNoneSelectedText" :disabled="schema.required" :value="null">
          {{ schema.placeholder || selectOptions.noneSelectedText || ' ' }}
        </option>

        <template v-for="item in items">
          <optgroup v-if="item.group" :label="getGroupName(item)">
              <option v-if="item.ops" v-for="i in item.ops" :value="getItemValue(i)">{{ getItemName(i) }}</option>
          </optgroup>
          <option v-if="!item.group" :value="getItemValue(item)"> {{ getItemName(item) }}</option>
        </template>
    </select>
  </div>
</template>

<script>
import { abstractField } from 'vue-form-generator';

export default {
  /* eslint-disable no-throw-literal */
  /* eslint-disable max-len */

  mixins: [abstractField],

  computed: {
    selectOptions() {
      return this.schema.selectOptions || {};
    },

    items() {
      const values = this.schema.values;
      if (typeof (values) === 'function') {
        return this.groupValues(values.apply(this, [this.model, this.schema]));
      }
      return this.groupValues(values);
    },
  },

  methods: {
    formatValueToField(value) {
      if (!value) {
        return null;
      }
      return value;
    },

    groupValues(values) {
      const array = [];
      let arrayElement = {};

      values.forEach((item) => {
        arrayElement = null;

        if (typeof item === 'object' && item.group) {
          // There is in a group.

          // Find element with this group.
          arrayElement = array.find(i => i.group === item.group);

          if (arrayElement) {
            // There is such a group.

            arrayElement.ops.push({
              id: item.id,
              name: item.name,
            });
          } else {
            // There is not such a group.

            // Initialising.
            arrayElement = {
              group: '',
              ops: [],
            };

            // Set group.
            arrayElement.group = item.group;

            // Set Group element.
            arrayElement.ops.push({
              id: item.id,
              name: item.name,
            });

            // Add array.
            array.push(arrayElement);
          }
        } else {
          // There is not in a group.
          array.push(item);
        }
      });

      // With Groups.
      return array;
    },

    getGroupName(item) {
      if (item && item.group) {
        return item.group;
      }

      throw 'Group name is missing! https://icebob.gitbooks.io/vueformgenerator/content/fields/select.html#select-field-with-object-items';
    },

    getItemValue(item) {
      if (typeof item === 'object') {
        if (typeof this.schema.selectOptions !== 'undefined'
            && typeof this.schema.selectOptions.value !== 'undefined') {
          return item[this.schema.selectOptions.value];
        }
        // Use 'id' instead of 'value' cause of backward compatibility
        if (typeof item.id !== 'undefined') {
          return item.id;
        }
        throw '`id` is not defined. If you want to use another key name, add a `value` property under `selectOptions` in the schema. https://icebob.gitbooks.io/vueformgenerator/content/fields/select.html#select-field-with-object-items';
      } else {
        return item;
      }
    },

    getItemName(item) {
      if (typeof item === 'object') {
        if (typeof this.schema.selectOptions !== 'undefined' && typeof this.schema.selectOptions.name !== 'undefined') {
          return item[this.schema.selectOptions.name];
        }
        if (typeof item.name !== 'undefined') {
          return item.name;
        }
        throw '`name` is not defined. If you want to use another key name, add a `name` property under `selectOptions` in the schema. https://icebob.gitbooks.io/vueformgenerator/content/fields/select.html#select-field-with-object-items';
      } else {
        return item;
      }
    },
  },

  created() {
    if (this.schema.onCreated) {
      this.schema.onCreated.call(this, this.model, this.value);
    }
  },
};
</script>

<template>
  <modal-wrapper name="reportable-conditions">
    <div class="modal__message">
      <h1>Is your incident HSE reportable?</h1>

      <h2>What is a reportable/RIDDOR incident?</h2>

      <p>
        If the incident has resulted in any of the outcomes listed below, a report must be completed and submitted to the
        Health and Safety Executive. There can be serious consequences for the company if a report is not completed.
        Reporting an incident of this type will result in the details being passed to rradar’s legal team, who will
        contact the user to provide legally privileged advice around the incident.
      </p>

      <h2>Reportable Incidents</h2>

      <p>A reportable incident is any workplace injury that results in more than 7 days away from normal work</p>

      <h2>Reportable Injuries</h2>

      <ul>
        <li>Death</li>
        <li>Any amputation</li>
        <li>Any fracture, other than fingers and toes</li>
        <li>Dislocation of shoulder, hip, knee or spine</li>
        <li>Any loss of sight</li>
        <li>Burn or penetrating injury to the eye</li>
        <li>Electric shock or burn leading to unconsciousness</li>
        <li>Unconsciousness caused by asphyxia, exposure to a hazardous substance or biological agent</li>
      </ul>

      <h2>Reportable Dangerous Occurences</h2>

      <p>These include, but are not limited to:</p>

      <ul>
        <li>A fire, explosion or collapse of a structure or machine</li>
        <li>An uncontrolled release of a flammable liquid or gas</li>
        <li>An accidental release of any substance that may damage health</li>
        <li>Collapse, overturning or failure of load-bearing equipment</li>
        <li>Collapse of scaffold over 5m high</li>
      </ul>

      <h2>Reportable Occupational Diseases</h2>

      <p>
        Diagnoses of certain occupational diseases, where these are likely to have been caused or made worse at
        work, include but are not limited to:
      </p>

      <ul>
        <li>
          Carpal tunnel syndrome, severe cramp of the hand or forearm, hand-arm vibration syndrome, tendonitis or
          tenosynovitis of the hand or forearm
        </li>
        <li>Occupational dermatitis</li>
        <li>Occupational asthma</li>
        <li>Any occupational cancer</li>
        <li>Any disease attributed to an occupational exposure to a biological agent</li>
      </ul>

      <p>
        <a href="http://www.hse.gov.uk/pubns/indg453.pdf" target="_blank"
          >For further guidance please read this PDF &nbsp;<i class="fa-solid fa-arrow-up-right-from-square"
        /></a>
      </p>

    </div>
  </modal-wrapper>
</template>

<script>
import ModalWrapper from '@/components/ModalWrapper';

export default {
  name: 'reportable-conditions',

  components: { ModalWrapper },
};
</script>

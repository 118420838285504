<template>
  <div>
    <lawyer-company-index />
    <lawyer-incident-index class="spacing" />
  </div>
</template>

<script>
import LawyerCompanyIndex from '@/components/lawyer/companies/Index';
import LawyerIncidentIndex from '@/components/lawyer/incidents/Index';

export default {
  name: 'lawyer-dashboard',

  components: { LawyerCompanyIndex, LawyerIncidentIndex },
};
</script>

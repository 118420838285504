<template>
  <div v-if="screens.length > 0">
    <div class="admin__header">
      <h1 class="no-margin">{{ activeWorkflow.title }} Screens</h1>
      <div class="actions">
        <router-link :to="{ name: 'admin-preview-workflow', params: { workflowId: activeWorkflow.id } }"
          class="button actions__action">
          Preview Workflow
        </router-link>
        <router-link :to="{ name: 'admin-new-screen' }" class="button actions__action">Add Screen</router-link>
      </div>
    </div>
    <div class="list-view">
      <div class="list-view__header">
        <div>Title</div>
        <div class="list-view__small-column">Allow Multiple</div>
        <div class="list-view__small-column">Actions</div>
      </div>
      <div class="list-view__row" v-for="(screen, index) in sortedScreens" :key="screen.id">
        <div>
          <router-link :to="{ name: 'admin-fields', params: { screenId: screen.id } }">
            {{ screen.position }}: {{ screen.title }}
          </router-link>
        </div>
        <div class="list-view__small-column">
          <span class="show-for-small">Allow Multiple: </span>
          <i class="fa-solid fa-check" v-if="screen.allow_multiple" />
          <i class="fa-solid fa-xmark" v-else />
        </div>
        <div class="list-view__small-column">
          <button class="icon-button" @click="alterPosition(screen.id, screen.position - 1)" :disabled="index === 0">
            <i class="fa-solid fa-arrow-up" />
          </button>
          <button class="icon-button" @click="alterPosition(screen.id, screen.position + 1)"
            :disabled="index === sortedScreens.length - 1">
            <i class="fa-solid fa-arrow-down" />
          </button>
          <router-link :to="{ name: 'admin-edit-screen', params: { screenId: screen.id } }">
            <i class="fa-solid fa-pen-to-square" />
          </router-link>
          <button class="icon-button" @click="deleteScreen(screen)">
            <i class="fa-solid fa-trash" />
          </button>
        </div>
      </div>
    </div>
  </div>
  <no-items v-else :parent="activeWorkflow.title" name="Screen" icon="desktop" new-link="admin-new-screen" />
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import clone from 'lodash.clone';
import Common from '@/common';
import NoItems from '@/components/NoItems';

export default {
  name: 'admin-screens',

  components: { NoItems },

  computed: {
    ...mapState({
      screens: state => state.admin.screen.screens,
    }),

    ...mapGetters('admin', [
      'activeWorkflow',
    ]),

    sortedScreens() {
      const screens = clone(this.screens);
      return screens.sort((a, b) => a.position > b.position);
    },
  },

  methods: {
    deleteScreen(screen) {
      Common.confirmDialog({
        modal: this.$modal,
        title: `Are you sure you want to delete ${screen.title}?`,
        okHandler: () => {
          this.$store.dispatch('admin/deleteScreen', {
            workflowId: this.activeWorkflow.id,
            screenId: screen.id,
          });
        },
      });
    },

    alterPosition(screenId, position) {
      this.$store.dispatch('admin/editScreen', { data: { id: screenId, position }, noCommit: true })
        .then(() => this.$store.dispatch('admin/fetchScreens', { dependsOn: this.activeWorkflow.id, override: true }));
    },
  },
};
</script>

<template>
  <div class="admin">
    <div class="admin__header">
      <h1>Global Workflows</h1>
      <div class="actions">
        <pulse-loader
          :loading="loading"
          :color="primaryColour"
          size="10px"
          class="v-spinner--no-spacing actions__action"
          title="Cloning workflow" />
        <router-link :to="{ name: 'admin-clone-workflow' }" class="actions__action secondary-button">Clone Workflow</router-link>
        <router-link :to="{ name: 'admin-new-global-workflow' }" class="actions__action secondary-button">
          Add Workflow
        </router-link>
      </div>
    </div>

    <admin-workflow-list :workflows="workflows" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PulseLoader from '@/components/PulseLoader';
import AdminWorkflowList from '../WorkflowList';

export default {
  name: 'admin-global-workflows',

  components: { PulseLoader, AdminWorkflowList },

  computed: {
    ...mapState({
      workflows: state => state.admin.workflow.workflows,
      primaryColour: state => state.primaryColour,
      loading: state => state.admin.workflow.loading,
    }),
  },
};
</script>

<template>
  <div>
    <h1 id="edit-title">Edit {{ activeScreen.title }}</h1>
    <tabs :options="{ useUrlFragment: false }">
      <tab name="Basic Info">
        <vue-form-generator :schema="buildSchema('screen', 'screenSchema', 'basicInfo', 'edit')" :model="formModel"
          :options="formOptions" />
      </tab>
      <tab name="Screen Conditions">
        <vue-form-generator :schema="buildSchema('screen', 'screenSchema', 'screenConditions', 'edit')"
          :model="objects[activeObject]" :options="formOptions" />

        <div class="list-view list-view--inline" v-if="objects.length > 1">
          <div class="list-view__header">
            <div>Field</div>
            <div>Condition</div>
            <div class="list-view__small-column">Priority</div>
            <div>Next</div>
            <div class="list-view__small-column">Actions</div>
          </div>

          <div class="list-view__row" v-for="(screenCondition, index) in initialObjects" :key="screenCondition.id"
            v-if="index > 0">
            <div>{{ fieldById(screenCondition.field_id).title }}</div>
            <div>{{ friendlyOperatorName(screenCondition.operator) }}
              <template v-if="friendlyOperatorName(screenCondition.operator)">
                ({{ screenCondition.operator }}): {{ screenCondition.value }}
              </template>
              <template v-else>
                -
              </template>
            </div>
            <div class="list-view__small-column">
              <span class="show-for-small">Priority:&nbsp;</span>{{ screenCondition.priority }}
            </div>
            <div>{{ screenById(screenCondition.next_screen_id).title }}</div>
            <div class="list-view__small-column">
              <button class="icon-button" @click="alterPriority(screenCondition.id, screenCondition.priority - 1)"
                :disabled="index === 1">
                <i class="fa-solid fa-arrow-up" />
              </button>
              <button class="icon-button" @click="alterPriority(screenCondition.id, screenCondition.priority + 1)"
                :disabled="index === objects.length - 1">
                <i class="fa-solid fa-arrow-down" />
              </button>
              <button class="icon-button" @click="editObject(index)">
                <i class="fa-solid fa-pen-to-square" />
              </button>
              <button class="icon-button" @click="deleteObject(screenCondition)">
                <i class="fa-solid fa-trash" />
              </button>
            </div>
          </div>
        </div>
      </tab>
    </tabs>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import editForm from '@/mixins/edit-form';
import inlineEditForm from '@/mixins/inline-edit-form';

export default {
  name: 'admin-edit-screen',
  mixins: [editForm, inlineEditForm],

  data: () => {
    return {
      formIndex: 'admin-screens',
      formName: 'Screen',
      inlineName: 'ScreenCondition',
      inlineId: 'conditions',
    };
  },

  computed: {
    ...mapState('admin', {
      fields: state => state.field.fields,
    }),

    ...mapGetters('admin', [
      'activeWorkflow',
      'activeScreen',
      'activeField',
      'screenById',
      'fieldById',
      'friendlyOperatorName',
    ]),
  },

  watch: {
    activeWorkflow: {
      handler() {
        if (this.activeWorkflow.id) {
          this.fetchAllFields({ dependsOn: this.activeWorkflow.id }).then((fields) => {
            const filteredFields = fields.filter(field => field.screenPosition <= this.activeScreen.position);
            this.addFieldsToScreenConditionsDropdown(this.dropdownFields(filteredFields));
          });
        }
      },
      deep: true,
      immediate: true,
    },

    activeScreen: {
      handler() {
        if (this.activeScreen.id) {
          this.defineFormObjects();
          this.setupObjects();
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapMutations('admin', [
      'addFieldsToScreenConditionsDropdown',
    ]),

    ...mapActions('admin', [
      'fetchAllFields',
    ]),

    alterPriority(screenConditionId, priority) {
      this.$store.dispatch('admin/editScreenCondition', { data: { id: screenConditionId, priority }, noCommit: true })
        .then(() => this.$store.dispatch('admin/fetchScreenConditions', { dependsOn: this.activeScreen.id }));
    },
  },
};
</script>

const processNoteLocation = (noteLocation) => {
  noteLocation.screenMultiple = noteLocation.screen && noteLocation.screen.screen.multiple;
  noteLocation.screenCopyIndex -= 1;
  return noteLocation;
};

export default {
  methods: {
    buildPath(noteLocation) {
      let path = '';
      if (noteLocation && noteLocation.screenIndex !== undefined) {
        path += `[${noteLocation.screenIndex}]`;
        if (noteLocation.screenMultiple) {
          path += `[${noteLocation.screenCopyIndex}]`;
        }
        if (noteLocation.fieldRef) {
          path += `[${noteLocation.fieldRef}]`;
        }
      }
      return path;
    },

    manageIncidentNotes(noteType, noteLocation = {}) {
      this.$modal.show('incident-notes', { noteType, noteLocation: processNoteLocation(noteLocation) });
    },

    numberOfNotes(noteLocation = {}) {
      const path = this.buildPath(processNoteLocation(noteLocation));
      if (path) {
        return this.notes.filter((note) => note.location && note.location.startsWith(path)).length;
      }
      return this.notes.length;
    },
  },
};

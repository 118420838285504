<template>
  <div>
    <div class="page__header">
      <h1 class="page__title">{{ activeCompany.name }}</h1>
      <div class="actions">
        <label class="actions">
          Show Closed Incidents?
          <div class="input input--checkbox input--spacing">
            <input
              type="checkbox"
              class="input__default"
              :checked="showClosedIncidents"
              @change="setShowClosedIncidents($event.target.checked)">
            <div class="input__styled input__styled--checkbox"></div>
          </div>
        </label>
        <themed-button :to="{ name: 'lawyer-dashboard' }" class="page__action page__action--full">
          Lawyer Area
        </themed-button>
      </div>
    </div>

    <div
      class="spacing-bottom"
      v-for="workflowType in workflowTypes"
      :key="workflowType.id"
      v-if="incidentsByWorkflowType(workflowType.id).length > 0"
    >
      <h2>{{ pluralizedWorkflowType(workflowType) }}</h2>
      <div class="list-view">
        <div class="list-view__header">
          <div>Date/Time</div>
          <div class="list-view__small-column">Reference</div>
          <div>Workflow</div>
          <div class="list-view__small-column">Status</div>
          <div class="list-view__small-column">Actions</div>
        </div>

        <div class="list-view__row" v-for="incident in incidentsByWorkflowType(workflowType.id)" :key="incident.id">
          <div>
            <router-link :to="{
              name: 'lawyer-incident',
              params: { workflowId: incident.workflow_id, incidentId: incident.id }
            }">
              {{ dateTime(incident.completed_at) }}
            </router-link>
          </div>
          <div class="list-view__small-column">
            <span class="show-for-small">Reference: &nbsp;</span>{{ incident.reference || '-' }}
          </div>
          <div>{{ incident.workflow_title }}</div>
          <div class="list-view__small-column">{{ incident.closed ? 'Closed' : 'Open' }}</div>
          <div class="list-view__small-column">
            <button class="icon-button" @click="closeIncident(incident)" :disabled="incident.closed">
              <i class="fa-solid fa-box-archive" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="panel" v-if="incidents.length === 0">
      No incidents have been created for this company
    </div>
  </div>
</template>

<script>
import pluralize from 'pluralize';
import { mapState, mapGetters, mapMutations } from 'vuex';
import Common from '@/common';

export default {
  name: 'lawyer-company',

  computed: {
    ...mapState('lawyer', {
      incidents: state => state.incident.incidents,
      showClosedIncidents: state => state.incident.showClosedIncidents,
    }),

    ...mapState({
      workflowTypes: state => state.workflow.workflowTypes,
    }),

    ...mapGetters('lawyer', [
      'activeCompany',
      'incidentsByWorkflowType',
    ]),

    ...mapGetters([
      'dateTime',
    ]),
  },

  methods: {
    ...mapMutations('lawyer', [
      'setShowClosedIncidents',
    ]),

    pluralizedWorkflowType(workflowType) {
      return pluralize(workflowType.name);
    },

    closeIncident(incident) {
      Common.confirmDialog({
        modal: this.$modal,
        // eslint-disable-next-line max-len
        title: `Are you sure you want to close the incident dated ${this.dateTime(incident.completed_at)}?`,
        okHandler: () => {
          this.$store.dispatch('lawyer/closeIncident', incident.id);
        },
      });
    },
  },
};
</script>

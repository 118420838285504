/* eslint-disable no-shadow */

import Vue from 'vue';

import Common from '@/common';
import Store from '@/store/index';

const regexValidator = (value) => {
  try {
    /* eslint-disable no-new */
    new RegExp(value);
    return [];
  } catch (e) {
    return ['Invalid Regular Expression'];
  }
};

const processFields = (fields, getters) => {
  return fields.map((field) => {
    field.savedFieldType = field.field_type;
    const screen = getters.screenById(field.screen_id);
    if (screen) {
      field.screenTitle = getters.screenById(field.screen_id).title;
      field.screenPosition = getters.screenById(field.screen_id).position;
    }
    return field;
  });
};

const isCurrentFieldType = (model, fieldTypes) => {
  if (fieldTypes === 'all') {
    return model.field_type !== 'statictext';
  }
  return fieldTypes.includes(model.field_type);
};

const state = {
  fields: [],
  allFields: [],
  activeField: 0,
  fieldSchema: {
    basicInfo: {
      includeAll: true,
      fields: [
        {
          type: 'customInput',
          inputType: 'text',
          styleClasses: 'form__field',
          label: 'Title',
          model: 'title',
          validator: 'string',
          required: true,
        },
        {
          type: 'customSelect',
          styleClasses: 'form__field',
          label: 'Field Type',
          model: 'field_type',
          values: () => {
            return Object.keys(Store.state.field.inputTypes)
              .map((key) => {
                const fieldType = Store.state.field.inputTypes[key];
                return {
                  id: key,
                  name: fieldType.name,
                  available: !fieldType.disabledInAdmin,
                };
              })
              .filter((val) => val.available);
          },
          validator: 'string',
          required: true,
        },
        {
          type: 'customInput',
          inputType: 'text',
          styleClasses: 'form__field',
          label: 'Default Value',
          model: 'default',
          visible: (model) => isCurrentFieldType(model, 'all'),
        },
        {
          type: 'customInput',
          inputType: 'text',
          styleClasses: 'form__field',
          label: 'Placeholder',
          model: 'placeholder',
          visible: (model) => isCurrentFieldType(model, Store.state.field.fieldTypesWithInputBox),
        },
        {
          type: 'customInput',
          inputType: 'text',
          styleClasses: 'form__field',
          label: 'Hint',
          model: 'hint',
          visible: (model) => isCurrentFieldType(model, 'all'),
        },
        {
          type: 'textArea',
          styleClasses: 'form__field',
          label: 'Static Text',
          model: 'static_text',
          validator: 'string',
          rows: 8,
          visible: (model) => isCurrentFieldType(model, ['statictext']),
        },
        {
          type: 'customInput',
          inputType: 'number',
          styleClasses: 'form__field',
          label: 'Position',
          model: 'position',
        },
        {
          type: 'customCheckbox',
          styleClasses: 'form__field',
          label: 'Required?',
          model: 'required',
          default: true,
          visible: (model) => isCurrentFieldType(model, 'all'),
        },
        {
          type: 'customCheckbox',
          styleClasses: 'form__field',
          label: 'Show for Print?',
          model: 'show_for_print',
        },
      ],
    },
    validation: {
      includeAll: true,
      fields: [
        {
          type: 'customInput',
          inputType: 'number',
          styleClasses: 'form__field',
          label: 'Minimum Length',
          model: 'min_length',
          validator: 'integer',
        },
        {
          type: 'customInput',
          inputType: 'number',
          styleClasses: 'form__field',
          label: 'Maximum Length',
          model: 'max_length',
          validator: 'integer',
        },
        {
          type: 'customInput',
          inputType: 'text',
          styleClasses: 'form__field form__field--last',
          label: 'Validation Regex',
          model: 'validation',
          validator: regexValidator,
        },
      ],
    },
    all: {
      fields: [
        {
          type: 'newRow',
        },
        {
          model: '',
          type: 'customSubmit',
          styleClasses: 'form__field',
          buttonText: 'Add Field',
          onlyOn: 'new',
          onSubmit: (model, schema, context, form) => {
            context.addForm(model, form);
          },
          validateBeforeSubmit: true,
        },
        {
          model: '',
          type: 'customSubmit',
          styleClasses: 'form__field',
          buttonText: 'Edit Field',
          onlyOn: 'edit',
          onSubmit: (model, schema, context, form) => {
            context.editForm(model, form);
          },
          validateBeforeSubmit: true,
        },
      ],
    },
    fieldOptions: {
      fields: [
        {
          type: 'customInput',
          inputType: 'text',
          styleClasses: 'form__field',
          label: 'Text',
          model: 'text',
          validator: 'string',
          required: true,
        },
        {
          type: 'customInput',
          inputType: 'text',
          styleClasses: 'form__field',
          label: 'Value',
          model: 'value',
          validator: 'string',
          required: true,
        },
        {
          type: 'customInput',
          inputType: 'text',
          styleClasses: 'form__field form__field--last',
          label: 'Position',
          model: 'position',
        },
        {
          model: '',
          type: 'customSubmit',
          styleClasses: 'form__field',
          buttonText: 'Save',
          onSubmit: (model, schema, context, form) => {
            context.saveObject(model, form);
          },
          validateBeforeSubmit: true,
        },
        {
          model: '',
          type: 'customSubmit',
          styleClasses: 'form__field',
          buttonText: 'Cancel',
          noLoadingSpinner: true,
          visible: (model) => {
            return Object.keys(model).length !== 0;
          },
          onSubmit: (model, schema, context) => {
            context.clearForm();
          },
        },
      ],
    },
  },
};

const getters = {
  activeField: (state) => state.fields.find((field) => field.id === state.activeField) || {},
  activeFields: (state) => state.fields,
  activeFieldId: (state) => state.fields.findIndex((field) => field.id === state.activeField),
  fieldOptionId: (state, getters) => (fieldOption) => {
    const optionId = getters.activeField.options.findIndex((option) => option.id === fieldOption);
    return optionId >= 0 ? optionId : 0;
  },
  fieldById: (state) => (fieldId) => {
    return (
      state.allFields.find((field) => field.id === fieldId) || state.fields.find((field) => field.id === fieldId) || {}
    );
  },
  fieldIndexById: (state) => (fieldId) => {
    const fieldIndex = state.fields.findIndex((field) => field.id === fieldId);
    return fieldIndex >= 0 ? fieldIndex : 0;
  },
};

const mutations = {
  setFields(state, fields) {
    state.fields = fields;
  },

  setAllFields(state, fields) {
    state.allFields = fields;
  },

  setFieldOptions(state, { fieldId, options }) {
    if (state.fields[fieldId]) {
      Vue.set(state.fields[fieldId], 'options', options);
    }
  },

  setActiveField(state, field) {
    state.activeField = field;
  },

  addField(state, field) {
    field.savedFieldType = field.field_type;
    state.fields.push(field);
  },

  editField(state, { fieldId, editedField }) {
    editedField.savedFieldType = editedField.field_type;
    Vue.set(state.fields, fieldId, editedField);
  },

  deleteField(state, { fieldId }) {
    Vue.delete(state.fields, fieldId);
  },

  addFieldOption(state, { fieldId, fieldOption }) {
    state.fields[fieldId].options.push(fieldOption);
  },

  editFieldOption(state, { fieldId, fieldOptionId, fieldOption }) {
    Vue.set(state.fields[fieldId].options, fieldOptionId, fieldOption);
  },

  deleteFieldOption(state, { fieldId, fieldOptionId }) {
    Vue.delete(state.fields[fieldId].options, fieldOptionId);
  },
};

const actions = {
  fetchFields({ commit, getters }, { dependsOn }) {
    return Vue.axios
      .get(`admin/screens/${dependsOn}/fields`)
      .then((response) => {
        const fields = processFields(response.data.field, getters);
        commit('setFields', fields);
        return fields;
      })
      .catch();
  },

  fetchAllFields({ commit, getters }, { dependsOn }) {
    return Vue.axios
      .get(`admin/workflows/${dependsOn}/fields`)
      .then((response) => {
        const fields = processFields(response.data.field, getters);
        commit('setAllFields', fields);
        return fields;
      })
      .catch();
  },

  fetchFieldOptions({ getters, commit }, { dependsOn }) {
    return Vue.axios
      .get(`admin/fields/${dependsOn}/options`)
      .then((response) => {
        commit('setFieldOptions', {
          fieldId: getters.activeFieldId,
          options: response.data.option,
        });
      })
      .catch();
  },

  addField({ commit, dispatch }, { data, parentId, form }) {
    return Vue.axios
      .post(`admin/screens/${parentId}/fields`, data)
      .then((response) => {
        commit('addField', response.data.field);
        dispatch('setMessage', { text: 'Successfully created field' }, { root: true });
      })
      .catch((error) => Common.handleError(error, form));
  },

  editField({ getters, commit, dispatch }, { data, form, noCommit }) {
    return Vue.axios
      .put(`admin/fields/${data.id}`, data)
      .then((response) => {
        if (!noCommit) {
          commit('editField', {
            fieldId: getters.activeFieldId,
            editedField: response.data.field,
          });
          dispatch('setMessage', { text: `Successfully edited ${data.title}` }, { root: true });
        }
      })
      .catch((error) => Common.handleError(error, form));
  },

  deleteField({ getters, commit, dispatch }, { screenId, fieldId }) {
    commit('deleteField', { fieldId: getters.fieldIndexById(fieldId) });
    return Vue.axios
      .delete(`admin/screens/${screenId}/fields/${fieldId}`)
      .then(() => dispatch('setMessage', { text: 'Successfully deleted field' }, { root: true }))
      .catch((error) => Common.handleError(error));
  },

  addFieldOption({ state, getters, commit, dispatch }, { data, form }) {
    return Vue.axios
      .post(`admin/fields/${state.activeField}/options`, data)
      .then((response) => {
        commit('addFieldOption', {
          fieldId: getters.activeFieldId,
          fieldOption: response.data.option,
        });
        dispatch('setMessage', { text: 'Successfully created field option' }, { root: true });
      })
      .catch((error) => Common.handleError(error, form));
  },

  editFieldOption({ state, getters, commit, dispatch }, { data, form, noCommit }) {
    return Vue.axios
      .put(`admin/fields/${state.activeField}/options/${data.id}`, data)
      .then((response) => {
        if (!noCommit) {
          commit('editFieldOption', {
            fieldId: getters.activeFieldId,
            fieldOptionId: getters.fieldOptionId(data.id),
            fieldOption: response.data.option,
          });
          dispatch('setMessage', { text: `Successfully edited ${data.text}` }, { root: true });
        }
      })
      .catch((error) => Common.handleError(error, form));
  },

  deleteFieldOption({ state, getters, commit, dispatch }, { inlineId }) {
    return Vue.axios
      .delete(`admin/fields/${state.activeField}/options/${inlineId}`)
      .then(() => {
        commit('deleteFieldOption', {
          fieldId: getters.activeFieldId,
          fieldOptionId: getters.fieldOptionId(inlineId),
        });
        dispatch('setMessage', { text: 'Successfully deleted field option' }, { root: true });
      })
      .catch((error) => Common.handleError(error));
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

import { mapGetters, mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['primaryColour']),

    ...mapGetters(['themeColour', 'inStandardArea']),

    useThemeColour() {
      return this.inStandardArea;
    },
  },

  methods: {
    addLight(colour, amount) {
      const cc = parseInt(colour, 16) + amount;
      let c = cc > 255 ? 255 : cc;
      c = c.toString(16).length > 1 ? c.toString(16) : `0${c.toString(16)}`;
      return c;
    },

    lighten(colour, amount) {
      const parsedColour = colour.indexOf('#') >= 0 ? colour.substring(1, colour.length) : colour;
      const parsedAmount = parseInt((255 * amount) / 100);
      /* eslint-disable max-len */
      return `#${this.addLight(parsedColour.substring(0, 2), parsedAmount)}${this.addLight(
        parsedColour.substring(2, 4),
        parsedAmount,
      )}${this.addLight(parsedColour.substring(4, 6), parsedAmount)}`;
      /* eslint-enable max-len */
    },
  },

  mounted() {
    const listenerMount = this.$refs.themeMount || this.$el;
    listenerMount.addEventListener('mouseover', (e) => {
      if (this.useThemeColour && !this.disabled) {
        e.target.style.backgroundColor = this.lighten(this.themeColour, 10);
      }
    });
    listenerMount.addEventListener('mouseleave', (e) => {
      if (this.useThemeColour && !this.disabled) {
        e.target.style.backgroundColor = this.themeColour;
      }
    });
  },
};

<template>
  <modal :name="name" width="90%" :max-width="600" height="50%" :adaptive="true" @before-open="beforeOpen">
    <button class="icon-button modal__close" @click="$modal.hide(name)">
      <i class="fa-solid fa-x" />
    </button>

    <slot />
  </modal>
</template>

<script>
export default {
  name: 'modal-wrapper',

  props: ['name'],

  methods: {
    beforeOpen(event) {
      this.$emit('before-open', event);
    },
  },
};
</script>

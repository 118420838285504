import company from './lawyer/company';
import incident from './lawyer/incident';
import workflow from './lawyer/workflow';
import user from './lawyer/user';

export default {
  namespaced: true,
  modules: {
    company,
    incident,
    workflow,
    user,
  },
};

<template>
  <div class="admin">
    <div class="admin__header">
      <h1 class="no-margin">Companies</h1>
    </div>

    <div class="company-search">
      <form @submit.prevent="search" class="company-search__form">
        <input type="text" class="company-search__input" placeholder="Search for a company" v-model="term">
        <button type="submit" class="secondary-button">Search</button>
      </form>

      <div class="company-search__message" v-if="orderedSearchCompanies.length > 0 && searchPerformed">
        Found {{ companyCount }}
      </div>
      <div class="company-search__message" v-else-if="searchPerformed">No companies found</div>
    </div>

    <div class="list-view" v-if="orderedSearchCompanies.length > 0 && searchPerformed">
      <div class="list-view__header">
        <div>Name</div>
        <div>Colour</div>
        <div class="list-view__small-column">Published</div>
        <div class="list-view__small-column">Actions</div>
      </div>
      <div class="list-view__row" v-for="company in orderedSearchCompanies" :key="company.id">
        <div>
          <router-link :to="{ name: 'admin-show-company', params: { companyId: company.id } }">
            {{ company.name }}
          </router-link>
        </div>
        <div><span class="colour" :style="{ backgroundColor: company.colour }"></span> {{ company.colour }}</div>
        <div class="list-view__small-column">
          <span class="show-for-small">Published: </span>
          <i class="fa-solid fa-check" v-if="company.published" />
          <i class="fa-solid fa-xmark" v-else />
        </div>
        <div class="list-view__small-column">
          <router-link :to="{ name: 'admin-edit-company', params: { companyId: company.id } }">
            <i class="fa-solid fa-pen-to-square" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pluralize from 'pluralize';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'admin-companies',


  data() {
    return {
      term: '',
      searchPerformed: false,
    };
  },

  computed: {
    ...mapGetters('admin', [
      'orderedSearchCompanies',
    ]),

    companyCount() {
      return `${this.orderedSearchCompanies.length} ${pluralize('company', this.orderedSearchCompanies.length)}`;
    },
  },

  watch: {
    term(value) {
      if (value === '') this.searchPerformed = false;
    },
  },

  methods: {
    ...mapActions('admin', [
      'searchCompanies',
    ]),

    search() {
      if (this.term) {
        this.searchCompanies(this.term).then(() => this.searchPerformed = true);
      }
    },
  },
};
</script>

/* eslint-disable max-len */
const workflowTypes = [
  {
    id: 'privileged',
    name: 'Reportable Incident',
    shortName: 'Incident',
    description:
      'If any of the <a href="">reportable conditions</a> apply, a report must be completed and submitted to the Health and Safety Executive. Following the creation of an incident record, our specialist lawyers will contact you to advise you on how to do so and what else needs to be done. Don\'t worry, there\'ll be no bill for us to support you with this.',
    workflowNote:
      'Alternatively, you can report an incident by calling rradar on <a href="tel:+4408009556111">0800 955 6111</a><br/><br/>For 24/7 support in the event of a crisis, call <a href="tel:+4408009556222">0800 955 6222</a>',
    showInfo: true,
  },
  {
    id: 'incident',
    name: 'Non-reportable Incident',
    shortName: 'Incident',
    description:
      'If none of the <a href="">reportable conditions</a> apply, the incident is not reportable to the HSE but you should still record it and take the action you feel is appropriate.',
    showInfo: true,
  },
  {
    id: 'observation',
    name: 'Observation',
    shortName: 'Observation',
    description: 'Potential risk or hazard reports',
  },
];
/* eslint-enable max-len */

export default {
  workflowTypes,
};

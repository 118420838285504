<template>
  <div class="duration">
    <input class="form-control duration__input"
      :id="getFieldID(schema)"
      type="number"
      :value="value[0]"
      @change="onChange(0, $event)"
    >
    <div class="select-wrapper">
      <select class="form-control duration__type" :value="value[1]" @change="onChange(1, $event)">
        <option value="Seconds">Seconds</option>
        <option value="Minutes">Minutes</option>
        <option value="Hours">Hours</option>
        <option value="Days">Days</option>
        <option value="Weeks">Weeks</option>
        <option value="Months">Months</option>
        <option value="Years">Years</option>
      </select>
    </div>
  </div>
</template>

<script>
import { abstractField } from 'vue-form-generator';

export default {
  mixins: [abstractField],

  methods: {
    formatValueToField(value) {
      if (value) {
        return value.split(' ');
      }
      return [];
    },

    formatValueToModel(value) {
      if (Array.isArray(value)) {
        return value.join(' ');
      }
      return value;
    },

    onChange(index, event) {
      const newValue = this.value;
      newValue[index] = event.target.value;
      this.value = newValue;
    },
  },
};
</script>

import clone from 'lodash.clonedeep';

const filterFields = (fields, mode) => {
  return fields.filter((field) => {
    return (
      (!field.onlyOn || (Array.isArray(field.onlyOn) ? field.onlyOn.includes(mode) : field.onlyOn === mode)) &&
      (!field.notOn || (Array.isArray(field.notOn) ? !field.notOn.includes(mode) : field.notOn !== mode))
    );
  });
};

export default {
  buildSchema:
    (state) =>
    (namespace, schema, page, mode, admin = true) => {
      const stateStart = admin ? state.admin : state;
      const unfilteredSchema = stateStart[namespace][schema][page];
      const allSchema = stateStart[namespace][schema].all;
      const filteredSchema = {};
      Object.keys(unfilteredSchema).forEach((key) => (filteredSchema[key] = clone(unfilteredSchema[key])));
      if (unfilteredSchema.groups) {
        filteredSchema.groups.map((group, index) => {
          group.fields = filterFields(unfilteredSchema.groups[index].fields, mode);
          return group;
        });
      } else {
        filteredSchema.fields = filterFields(unfilteredSchema.fields, mode);
      }
      if (unfilteredSchema.includeAll && allSchema) {
        filteredSchema.fields.push(...filterFields(allSchema.fields, mode));
      }
      return filteredSchema;
    },

  inArea: (state) => (area) => {
    const route = state.route.name;
    if (!route) return false;
    if (area === 'standard' && !state.siteAreas.some((siteArea) => route.startsWith(siteArea))) return true;
    return route.startsWith(area);
  },
  inAdminArea: (state, getters) => getters.inArea('admin'),
  inLawyerArea: (state, getters) => getters.inArea('lawyer'),
  inStandardArea: (state, getters) => getters.inArea('standard'),
  onIndexPage: (state) => state.route.name === 'index',

  themeColour: (state, getters) => {
    const activeWorkflow = getters.inAdminArea ? getters['admin/activeWorkflow'] : getters.activeWorkflow;
    const activeCompany = getters.inAdminArea ? getters['admin/activeCompany'] : getters.activeCompany;
    return activeWorkflow.colour || activeCompany.colour || '#cc0000';
  },
};

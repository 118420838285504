import { HorizontalBar } from 'vue-chartjs';
import merge from 'deepmerge';

export default {
  extends: HorizontalBar,
  props: ['chartData', 'options'],

  computed: {
    barOptions() {
      return merge.all([
        this.options || {},
        {
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                barPercentage: 0.6,
                categoryPercentage: 1.0,
              },
            ],
            xAxes: [
              {
                ticks: {
                  min: 0,
                  max: 100,
                  callback(value) {
                    return `${value}%`;
                  },
                },
              },
            ],
          },
        },
      ]);
    },
  },

  methods: {
    render() {
      this.renderChart(this.chartData, this.barOptions);
    },
  },
};
